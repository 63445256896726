
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
    init,
    loadTranferPayment,
} from '../../modules/payment/payment'


import AdminClubCoupon from '../../components/admin/AdminClubCoupon';
import client, { url } from '../../lib/api/client';
import qs from 'qs';
import { loadAllClubList } from '../../modules/clubs/clubs';
import AdminClubSms from '../../components/admin/AdminClubSms';

const AdminClubSmsInfoContainer = ({history}) => {

    const dispatch = useDispatch();
    const {
      user,
      socketKey,
      clubs
    } = useSelector(({ user, socket, clubs }) => ({
        user: user.user,
        socketKey: socket.socketKey,
        clubs: clubs.clubs,
    }));

    const [smsPaymentList, setSmsPaymentList] = useState([])
    const [selectClub, setSelectClub] = useState(0)
    const [smsNumber, setSmsNumber] = useState('')
    const [aligoPoint, setAligoPoint] = useState({smsCount:0, lmsCount:0, mmsCount:0})
    
    useEffect(() => {
        initPaymentResult();
    }, [])

    const initPaymentResult = async ()=>{
        const queryString = qs.stringify({
            socketKey,
        });
        let resdata = await client.get(`${url}/api/sms/paymentresult/0?${queryString}`);
        console.log("initPaymentResult resdata : ", resdata.data)
        if(resdata.data && resdata.data.result && resdata.data.result == 1) {
            let paymentResult = [ ... resdata.data.infos ]
            paymentResult.sort((a, b)=>{
                return b.registTime - a.registTime
            })
            for (let i = 0; i < paymentResult.length; i++) {
                
                for (let j = 0; j < clubs.length; j++) {
                    if(clubs[j].clubID == paymentResult[i].clubID) {
                        paymentResult[i].clubName = clubs[j].name;
                        break;
                    }
                }
            }
            setSmsPaymentList(paymentResult)
        }

        let resAligo = await client.get(`${url}/api/sms/aligo/0?${queryString}`);
        console.log("initPaymentResult resAligo : ", resAligo.data)
        if(resAligo.data && resAligo.data.result && resAligo.data.result == 1) {
            setAligoPoint({smsCount:resAligo.data.smsCount, lmsCount:resAligo.data.lmsCount, mmsCount:resAligo.data.mmsCount})
        }
    }

    //==> 발신 번호 변경
    const onChangeSmsNumber = async ()=>{
        if(!selectClub || selectClub.value == 0) {
            return;
        }

        const queryString = qs.stringify({
            socketKey,
        });
        let res = await client.post(`${url}/api/sms/changesmsnumber/${selectClub.value}?${queryString}`, {smsNumber:smsNumber});
        console.log('setChangeSmsNumberInfo : ', res.data)
        
        if(res && res.data && res.data.result == 1) {
            alert('발신번호가 수정되었습니다.')
        }

        dispatch(
            loadAllClubList({
                playerID: Number(user.playerID),
                socketKey,
            }),
        );
    }

    const loadNumberInfo = async (clubID)=>{
        if(clubID == 0) {
            return;
        }

        const queryString = qs.stringify({
            socketKey,
        });
        let res = await client.get(`${url}/api/sms/${clubID}?${queryString}`);
        console.log('loadNumberInfo : ', res.data)
        if(res.data && res.data.result && res.data.result == 1) {
            setSmsNumber(res.data.smsNumber);
        }
        
    }

    let clubOptions=[]
  
    if(clubs)
    {
      for (let i = 0; i < clubs.length; i++) {
        clubOptions.push({label:clubs[i].name, value:clubs[i].clubID})
      }
    }

    return (
        <>
            <AdminClubSms
                aligoPoint={aligoPoint}
                selectClub={selectClub}
                setSelectClub={(e)=>{loadNumberInfo(e.value); setSelectClub(e);}}
                smsPaymentList={smsPaymentList}
                smsNumber={smsNumber}
                setSmsNumber={setSmsNumber}
                onChangeSmsNumber={onChangeSmsNumber}
                clubOptions={clubOptions}
            />
        </>
    );
};

export default AdminClubSmsInfoContainer;
