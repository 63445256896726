import React, { useEffect, useState, useMemo } from 'react';
import Sales from '../../components/sales/Sales';
import { useDispatch, useSelector } from 'react-redux';
import DeleteModal from '../../components/common/ConfirmModal';
import {
    loadSalesList,
    subtractMonth,
    addMonth,
    setActiveTab,
    initSelectDate,
    clickDate,
    getItemList,
    changeMonth,
    initSales,
    loadSubsList,
} from '../../modules/sales/sales';
import {
    init, loadClubPayment, cancelPayment, loadClubTansterCalculate
} from '../../modules/payment/payment';
import {
    loadSalesInfo,
} from '../../modules/app/mobileProduct'
import DailySalesModal from '../../components/sales/DailySalesModal';
import moment from 'moment';
import MobileCalculrateModal from '../../components/common/MobileCalculrateModal';

const SalesContainer = () => {
    const [visible, setVisible] = useState(false);
    const [monthPicker, setMonthPicker] = useState(false);
    const [cancelPaymentData, setCancelPaymentData] = useState(null);
    const [cancelPaymentModal, setCancelPaymentModal] = useState(false);
    const [cancelPaymentAdminModal, setCancelPaymentAdminModal] = useState(false);
    const [mobileCalculrateModal, setMobileCalculrateModal] = useState(false);
    const [mobileStartTime, setMobileStartTime] = useState(0)
    const [mobileEndTime, setMobileEndTime] = useState(0)
    


    const dispatch = useDispatch();
    const {
        club,
        socketKey,
        loading,
        active,
        month,
        dailySales,
        selectedDay,
        items,
        salesInfo,
        goods,
        memberships,
        salesByDate,
        tableData,
        refundData,
        clubPaymentList,
        paymentCancelResult,
        paymentCancelLoading,
        subsData,
        clubTransferCalculate
    } = useSelector(({ socket, club, mobileProduct, payment, membership, loading, sales }) => ({
        club: club.defaultClub,
        socketKey: socket.socketKey,
        loading: loading['sales/LOAD_SALES_LIST'],
        paymentCancelLoading: loading['payment/CANCEL_CLUB_PAYMENT'],
        active: sales.active,
        month: sales.month,
        dailySales: sales.dailySales,
        selectedDay: sales.selectedDay,
        items: sales.item,
        goods: sales.goods,
        salesInfo: mobileProduct.salesInfo,
        memberships: sales.membership,
        salesByDate: sales.salesByDate,
        refundData: sales.refundData,
        tableData: sales.tableData,
        clubTransferCalculate: payment.transferCalculate,
        clubPaymentList: payment.clubPaymentList,
        paymentCancelResult: payment.paymentCancelResult,
        subsData: sales.subsData,
    }));

    const onLoadPrevMonth = () => {
        dispatch(subtractMonth());
    };

    const onLoadNextMonth = () => {
        dispatch(addMonth());
    };

    const onChangeMonth = (date) => {
        dispatch(changeMonth(date));
    };

    const onSetTab = (tabName) => {
        dispatch(setActiveTab(tabName));
    };

    const onClickDate = (date, events) => {
        dispatch(clickDate({ date, sales: events }));
        setVisible(true);
    };

    const onClickDateArrow = (date, flag) => {
        const salesByDateOrderByDesc = Object.keys(salesByDate).sort((a, b) =>
            moment(a).isAfter(moment(b)) ? -1 : 1,
        );
        const currentIdx = salesByDateOrderByDesc.findIndex(
            (salesDate) => salesDate === date,
        );
        const newDate = salesByDateOrderByDesc[currentIdx + flag];

        if (!!newDate)
            dispatch(clickDate({ date: newDate, sales: salesByDate[newDate] }));
    };

    const onToggleMonthPicker = () => {
        setMonthPicker(!monthPicker);
    };

    const onModalClose = () => {
        setVisible(false);
        dispatch(initSelectDate());
    };

    const isCancelDate = (registTime) => {

        let registDate = new Date(registTime * 1000);
        let dayValue = registDate.getDate();
        let resultDate = new Date(registTime * 1000);

        if (dayValue >= 1 && dayValue <= 10) {
            resultDate.setDate(21);
        }
        else if (dayValue >= 11 && dayValue <= 20) {
            resultDate.setDate(1);
            resultDate.setMonth(resultDate.getMonth() + 1);
        }
        else if (dayValue >= 21) {
            resultDate.setDate(11);
            resultDate.setMonth(resultDate.getMonth() + 1);
        }

        resultDate.setDate(resultDate.getDate() - 1);
        resultDate.setHours(23, 59, 0, 0);

        return resultDate.getTime() > Date.now();
    }
    const onClickCancelPayment = (paymentdata) => {
        //let now = parseInt(Date.now()/1000)
        if (isCancelDate(paymentdata.registTime)) {
            setCancelPaymentData(paymentdata);
            setCancelPaymentModal(true);
        }
        else {
            alert("취소 가능 시간이 지났습니다.")
            dispatch(initSales());

            dispatch(loadSalesInfo({
                clubID: club.clubID,
                socketKey
            }))
        }
    }
    const onClickCancelPaymentAdmin = (paymentdata) => {
        //let now = parseInt(Date.now()/1000)
        if (true) {
            setCancelPaymentData(paymentdata);
            setCancelPaymentAdminModal(true);
        }
        else {
            alert("취소 가능 시간이 지났습니다.")
            dispatch(initSales());

            dispatch(loadSalesInfo({
                clubID: club.clubID,
                socketKey
            }))
        }
    }
    const onClickCancelPaymentCommit = () => {
        let now = parseInt(Date.now() / 1000)
        if ((cancelPaymentModal && isCancelDate(cancelPaymentData.registTime)) || cancelPaymentAdminModal) {
            dispatch(cancelPayment({ paymentData: cancelPaymentData, clubID: club.clubID, socketKey, targetID: cancelPaymentData.playerID }))
            dispatch(loadClubPayment({ clubID: club.clubID, socketKey }))
            setCancelPaymentData(null);
            setCancelPaymentModal(false);
            setCancelPaymentAdminModal(false);
            dispatch(initSales());

            dispatch(loadSalesInfo({
                clubID: club.clubID,
                socketKey
            }))
        }
        else {
            alert("취소 가능 시간이 지났습니다.")
            dispatch(initSales());

            dispatch(loadSalesInfo({
                clubID: club.clubID,
                socketKey
            }))
        }
    }

    const getMobileCalculateTime = (startTime, endTime)=>{
        const currentTime = Math.floor(Date.now()/1000);
        let startDate = new Date(Math.min(currentTime, startTime) * 1000)
        let endDate = new Date(Math.min(currentTime, endTime) * 1000)

        if(startDate.getDate() > 21) {
            startDate.setDate(11);
        }
        else if(startDate.getDate() > 11) {
            startDate.setDate(1);
        }
        else if(startDate.getDate() > 1) {
            startDate.setMonth(startDate.getMonth()-1);
            startDate.setDate(21);
        }
        else {
            startDate.setMonth(startDate.getMonth()-1);
            startDate.setDate(11);
        }

        if(endDate.getDate() <= 10) {
            endDate.setMonth(endDate.getMonth()-1);
            endDate.setDate(20);
            endDate.setHours(23, 59, 59)
        }
        else if(endDate.getDate() <= 20) {
            endDate.setDate(0);
            endDate.setHours(23, 59, 59)
        }
        else {
            endDate.setDate(10);
            endDate.setHours(23, 59, 59)
        }

        console.log('getMobileCalculateTime', `${startDate.getFullYear()}-${startDate.getMonth()+1}-${startDate.getDate()}`, `${endDate.getFullYear()}-${endDate.getMonth()+1}-${endDate.getDate()}`)

        return {
            startTime:Math.floor(startDate.getTime()/1000),
            endTime:Math.floor(endDate.getTime()/1000)
        }
    }

  const onClickMobilePeriod = (period)=>{
        let tempdate = new Date()

        let _startTime = 0;
        let _endTime = parseInt(tempdate.getTime()/1000);
        if(period == 'month')
        {
            tempdate.setDate(1)
            tempdate.setHours(0, 0, 0, 0)
            _startTime = parseInt(tempdate.getTime()/1000);
        }
        else if(period == '3months')
        {
            tempdate.setDate(1)
            tempdate.setHours(0, 0, 0, 0)
            tempdate.setMonth(tempdate.getMonth() - 2)
            _startTime = parseInt(tempdate.getTime()/1000);
        }
        else if(period == '6months')
        {
            tempdate.setDate(1)
            tempdate.setHours(0, 0, 0, 0)
            tempdate.setMonth(tempdate.getMonth() - 5)
            _startTime = parseInt(tempdate.getTime()/1000);
        }
        
        setMobileStartTime(_startTime)
        setMobileEndTime(_endTime)
        const newTime = getMobileCalculateTime(_startTime, _endTime)
        dispatch(loadClubTansterCalculate({
            startTime:newTime.startTime,
            endTime:newTime.endTime,
            clubID:club.clubID,
            socketKey
        }))
    }

    const onChangeMobileStartTime = (_startTime)=>
    {
        setMobileStartTime(_startTime)
        const newTime = getMobileCalculateTime(_startTime, mobileEndTime)
        dispatch(loadClubTansterCalculate({
            startTime:newTime.startTime,
            endTime:newTime.endTime,
            clubID:club.clubID,
            socketKey
        }))
    }

    const onChangeMobileEndTime = (_endTime)=>
    {
        setMobileEndTime(_endTime)
        const newTime = getMobileCalculateTime(mobileStartTime, _endTime)
        dispatch(loadClubTansterCalculate({
            startTime:newTime.startTime,
            endTime:newTime.endTime,
            clubID:club.clubID,
            socketKey
        }))
    }

    const onClickMobileSearch = ()=>{
        const newTime = getMobileCalculateTime(mobileStartTime, mobileEndTime)
        dispatch(loadClubTansterCalculate({
            startTime:newTime.startTime,
            endTime:newTime.endTime,
            clubID:club.clubID,
            socketKey
        }))
    }

    const initMobileCalculrate = ()=>{
        let _startTime = 0;
        let _endTime = 0;
        if(_endTime == 0 && _startTime == 0)
        {
            let tempdate = new Date()
            _endTime = parseInt(tempdate.getTime()/1000);
            tempdate.setDate(1)
            tempdate.setHours(0, 0, 0, 0)
            tempdate.setMonth(tempdate.getMonth() - 2)
            _startTime = parseInt(tempdate.getTime()/1000);
            setMobileStartTime(_startTime)
            setMobileEndTime(_endTime)
        }
        const newTime = getMobileCalculateTime(_startTime, _endTime)
        dispatch(loadClubTansterCalculate({
            startTime:newTime.startTime,
            endTime:newTime.endTime,
            clubID:club.clubID,
            socketKey
        }))
    }

    useEffect(() => {
        dispatch(initSales());

        dispatch(loadSalesInfo({
            clubID: club.clubID,
            socketKey
        }))
    }, []);

    useEffect(() => {
        if (club) {
            const startTime = parseInt(moment(month).startOf('month').unix());
            const endTime = parseInt(moment(month).endOf('month').unix());

            dispatch(
                loadSalesList({ socketKey, clubID: club.clubID, startTime, endTime }),
            );
            dispatch(
                loadSubsList({ socketKey, clubID: club.clubID, startTime, endTime }),
            );
        }
    }, [club, dispatch, month]);

    useEffect(() => {
        if (club) {
            dispatch(getItemList({ socketKey, clubID: club.clubID }));
            dispatch(loadClubPayment({ clubID: club.clubID, socketKey }))
        }
    }, [club]);

    useEffect(() => {
        if (club && paymentCancelResult && paymentCancelResult.result) {
            if (paymentCancelResult.result == 1) {
                dispatch(init());
                dispatch(getItemList({ socketKey, clubID: club.clubID }));
                const startTime = parseInt(moment(month).startOf('month').unix());
                const endTime = parseInt(moment(month).endOf('month').unix());

                dispatch(
                    loadSalesList({ socketKey, clubID: club.clubID, startTime, endTime }),
                );
                dispatch(
                    loadSubsList({ socketKey, clubID: club.clubID, startTime, endTime }),
                );

                setTimeout(() => {
                    dispatch(init());
                    dispatch(loadClubPayment({ clubID: club.clubID, socketKey }))
                }, 1000)
                dispatch(setActiveTab('mobileHistory'));
                alert("모바일 결제를 취소 하였습니다.")
            }
        }
    }, [paymentCancelResult]);
    
    return (
        <>
            <Sales
                isLoading={loading || paymentCancelLoading}
                onLoadPrevMonth={onLoadPrevMonth}
                onLoadNextMonth={onLoadNextMonth}
                month={month}
                onChangeMonth={onChangeMonth}
                active={active}
                onSetTab={onSetTab}
                onClickDate={onClickDate}
                onToggleMonthPicker={onToggleMonthPicker}
                onClickCancelPayment={onClickCancelPayment}
                onClickCancelPaymentAdmin={onClickCancelPaymentAdmin}
                monthPicker={monthPicker}
                items={items}
                memberships={memberships}
                salesByDate={salesByDate}
                tableData={tableData}
                refundData={refundData}
                goods={goods}
                selectedDay={selectedDay}
                salesInfo={salesInfo}
                clubPaymentList={clubPaymentList}
                subsData={subsData}
                setMobileCalculrateModal={()=>{
                    initMobileCalculrate()
                    setMobileCalculrateModal(true)
                }}
            />
            <MobileCalculrateModal
                clubTransferCalculate={clubTransferCalculate}
                startTime={mobileStartTime}
                endTime={mobileEndTime}
                setStartTime={onChangeMobileStartTime}
                setEndTime={onChangeMobileEndTime}
                onClickPeriod={onClickMobilePeriod}
                setActive={(_active) => {setMobileCalculrateModal(_active)}}
                active={mobileCalculrateModal}
                onClickSearch={onClickMobileSearch}
            />
            <DeleteModal
                isDelete
                text={`결제 취소 하시겠습니까?\n취소시 회원권은 삭제 되며 매출 및 정산금액에 반영되지 않습니다.`}
                onClick={() => {
                    onClickCancelPaymentCommit()
                }}
                setModal={() => {
                    setCancelPaymentData(null);
                    setCancelPaymentModal(false)
                    setCancelPaymentAdminModal(false)
                }}
                confirmText="결제 취소"
                onModal={cancelPaymentModal}
                onAdminModal={cancelPaymentAdminModal}
            />
            <DailySalesModal
                visible={visible}
                onClose={onModalClose}
                memberships={dailySales}
                selectedDay={selectedDay}
                items={items}
                goods={goods}
                onClickDateArrow={onClickDateArrow}
            />
        </>
    );
};

export default SalesContainer;
