import { createAction, handleActions } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import * as searchAPI from '../../lib/api/search';
import { takeLatest } from 'redux-saga/effects';
import produce from 'immer';
import * as messageAPI from '../../lib/api/message';
import {
  birthToAge,
  memberStateResolver,
  timeValueToDate,
  orderSort,
  sort,
} from '../../lib/commonFunc/commonFunc';
import moment from 'moment';

const INITIALIZE = 'notificationMessage/INITIALIZE';
const CHANGE_FORM_FIELD = 'notificationMessage/CHANGE_FORM_FIELD';
const ADD_IMAGE = 'notificationMessage/ADD_IMAGE';
const INIT_RES = 'notificationMessage/INIT_RES'

const SELECT_MEMBER = 'notificationMessage/SELECT_MEMBER';
const SELECT_TEMP_MEMBER = 'notificationMessage/SELECT_TEMP_MEMBER';
const SELECT_MEMBER_DELETE = 'notificationMessage/SELECT_MEMBER_DELETE';
const SELECT_TEMP_MEMBER_DELETE = 'notificationMessage/SELECT_TEMP_MEMBER_DELETE';
const INIT_TEMP_SELECTED_MEMBER = 'notificationMessage/INIT_TEMP_SELECTED_MEMBER';
const SELECT_TEMP_MEMBER_CONTROL = 'notificationMessage/SELECT_TEMP_MEMBER_CONTROL';
const MEMBER_TYPE_CHANGE = 'notificationMessage/MEMBER_TYPE_CHANGE';
const MEMBERS_CHANGE = 'notificationMessage/MEMBERS_CHANGE';
const CHANGE_IMAGE = 'notificationMessage/CHANGE_IMAGE'
const LOAD_DATA = 'notificationMessage/LOAD_DATA'
const UPLOAD_IMAGE = 'notificationMessage/UPLOAD_IMAGE'
const FILTER_DELETE_NOTICE = 'notificationMessage/FILTER_DELETE_NOTICE'
const INIT_MESSAGE_LIST = 'notificationMessage/INIT_MESSAGE_LIST'
const RESET_MEMBER_RESULT = 'notificationMessage/RESET_MEMBER_RESULT'
const RESET_MEMBERSHIP_RESULT = 'notificationMessage/RESET_MEMBERSHIP_RESULT'


const [
  LOAD_MEMBER,
  LOAD_MEMBER_SUCCESS,
  LOAD_MEMBER_FAILURE,
] = createRequestActionTypes('notificationMessage/LOAD_MEMBER');

const [
    LOAD_MEMBERSHIP,
    LOAD_MEMBERSHIP_SUCCESS,
    LOAD_MEMBERSHIP_FAILURE,
  ] = createRequestActionTypes('notificationMessage/LOAD_MEMBERSHIP');

const [
  LOAD_MESSAGE_DETAIL,
  LOAD_MESSAGE_DETAIL_SUCCESS,
  LOAD_MESSAGE_DETAIL_FAILURE,
] = createRequestActionTypes('notificationMessage/LOAD_MESSAGE_DETAIL');

const [
  LOAD_MESSAGE,
  LOAD_MESSAGE_SUCCESS,
  LOAD_MESSAGE_FAILURE,
] = createRequestActionTypes('notificationMessage/LOAD_MESSAGE');

const [
  LOAD_NOTICE,
  LOAD_NOTICE_SUCCESS,
  LOAD_NOTICE_FAILURE,
] = createRequestActionTypes('notificationMessage/LOAD_NOTICE');

const [
  LOAD_NEW_NOTICE,
  LOAD_NEW_NOTICE_SUCCESS,
  LOAD_NEW_NOTICE_FAILURE,
] = createRequestActionTypes('notificationMessage/LOAD_NEW_NOTICE');

const [
  CHANGE_NOTICE_STATE,
  CHANGE_NOTICE_STATE_SUCCESS,
  CHANGE_NOTICE_STATE_FAILURE,
] = createRequestActionTypes('notificationMessage/CHANGE_NOTICE_STATE');

const [
  SAVE_MESSAGE,
  SAVE_MESSAGE_SUCCESS,
  SAVE_MESSAGE_FAILURE,
] = createRequestActionTypes('notificationMessage/SAVE_MESSAGE');

const [
  DELETE_MESSAGE,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_FAILURE,
] = createRequestActionTypes('notificationMessage/DELETE_MESSAGE');

const [
  DELETE_NOTICE,
  DELETE_NOTICE_SUCCESS,
  DELETE_NOTICE_FAILURE,
] = createRequestActionTypes('notificationMessage/DELETE_NOTICE');

export const uploadImage = createAction(UPLOAD_IMAGE, ({image}) => (image))

export const loadMember = createAction(
  LOAD_MEMBER,
  ({ clubID, page, searchText, type, socketKey }) => ({
    clubID,
    page,
    searchText,
    type,
    socketKey,
  }),
);

export const loadMembership = createAction(
    LOAD_MEMBERSHIP,
    ({ clubID, socketKey }) => ({
      clubID,
      socketKey,
    }),
  );

export const loadMessageDetail = createAction(
  LOAD_MESSAGE_DETAIL,
  ({ clubID, memberMessageID, socketKey }) => ({
    clubID,
    memberMessageID,
    socketKey,
  }),
);
export const loadMessage = createAction(
  LOAD_MESSAGE,
  ({ clubID, messageType, socketKey }) => ({
    clubID,
    messageType,
    socketKey,
  }),
);
export const loadNotice = createAction(
  LOAD_NOTICE,
  ({ clubID, socketKey }) => ({
    clubID,
    isNew: 0,
    socketKey,
  }),
);
export const changeNoticeState = createAction(
  CHANGE_NOTICE_STATE,
  ({ clubID, time, socketKey }) => ({
    clubID,
    time,
    socketKey,
  }),
);
export const loadNewNotice = createAction(
  LOAD_NEW_NOTICE,
  ({ clubID, socketKey }) => ({
    clubID,
    isNew: 1,
    socketKey,
  }),
);
export const saveMessage = createAction(
  SAVE_MESSAGE,
  ({ playerID, socketKey, ClubMessageData }) => ({
    playerID,
    socketKey,
    ClubMessageData
  }),
);
export const deleteMessage = createAction(
  DELETE_MESSAGE,
  ({ playerID, socketKey, messageIDs }) => ({
    playerID,
    socketKey,
    messageIDs
  }),
);
export const deleteNotice = createAction(
  DELETE_NOTICE,
  ({ playerID, socketKey, messageIDs }) => ({
    playerID,
    socketKey,
    messageIDs
  }),
);

export const selectTempMember = createAction(SELECT_TEMP_MEMBER, ({member}) => (member))
export const selectMember = createAction(SELECT_MEMBER, ({member}) => (member))
export const selectMemberDelete = createAction(SELECT_MEMBER_DELETE, ({member}) => (member))
export const selectTempMemberDelete = createAction(SELECT_TEMP_MEMBER_DELETE, ({member}) => (member))
export const selectedTempMemberControl = createAction(SELECT_TEMP_MEMBER_CONTROL, ({member}) => (member))
export const memberTypeChange = createAction(MEMBER_TYPE_CHANGE, ({type}) => (type))
export const membersChange = createAction(MEMBERS_CHANGE, ({members}) => (members))
export const initSelectedTempMembers = createAction(INIT_TEMP_SELECTED_MEMBER)
export const initMessageList = createAction(INIT_MESSAGE_LIST)
export const changeImage = createAction(CHANGE_IMAGE, ({image}) => (image))
export const loadData = createAction(LOAD_DATA, ({imageArr, member}) => ({imageArr, member}))
export const filterDeleteNotice = createAction(FILTER_DELETE_NOTICE, ({deleteNoticeList}) => ({deleteNoticeList}))

export const changeFormField = createAction(
    CHANGE_FORM_FIELD,
    ({ key, value }) => ({
      key,
      value,
    }),
  );

export const addImage = createAction(ADD_IMAGE, ({image}) => (image))


export const init = createAction(INITIALIZE)
export const initRes = createAction(INIT_RES)
export const resetMemberResult = createAction(RESET_MEMBER_RESULT)
export const resetMembershipResult = createAction(RESET_MEMBERSHIP_RESULT)


const loadMemberSaga = createRequestSaga(LOAD_MEMBER, searchAPI.loadClubMemberList);
const loadMembershipSaga = createRequestSaga(LOAD_MEMBERSHIP, searchAPI.loadClubMembershipList);
const loadMessageDetailSaga = createRequestSaga(LOAD_MESSAGE_DETAIL, messageAPI.loadMessageDetail);
const loadMessageSaga = createRequestSaga(LOAD_MESSAGE, messageAPI.loadMessage);
const loadNoticeSaga = createRequestSaga(LOAD_NOTICE, messageAPI.loadNotice);
const changeNoticeStateSaga = createRequestSaga(CHANGE_NOTICE_STATE, messageAPI.changeNoticeState);
const loadNewNoticeSaga = createRequestSaga(LOAD_NEW_NOTICE, messageAPI.loadNotice);
const saveMessageSaga = createRequestSaga(SAVE_MESSAGE, messageAPI.saveMessage);
const deleteMessageSaga = createRequestSaga(DELETE_MESSAGE, messageAPI.deleteNotice);
const deleteNoticeSaga = createRequestSaga(DELETE_NOTICE, messageAPI.deleteNotice);

export function* messageSaga() {
  yield takeLatest(LOAD_MESSAGE_DETAIL, loadMessageDetailSaga);
  yield takeLatest(LOAD_MEMBER, loadMemberSaga);
  yield takeLatest(LOAD_MEMBERSHIP, loadMembershipSaga);
  yield takeLatest(LOAD_MESSAGE, loadMessageSaga);
  yield takeLatest(LOAD_NOTICE, loadNoticeSaga);
  yield takeLatest(CHANGE_NOTICE_STATE, changeNoticeStateSaga);
  yield takeLatest(LOAD_NEW_NOTICE, loadNewNoticeSaga);
  yield takeLatest(SAVE_MESSAGE, saveMessageSaga);
  yield takeLatest(DELETE_MESSAGE, deleteMessageSaga);
  yield takeLatest(DELETE_NOTICE, deleteNoticeSaga);
}

const initialState = {
    image: [],
    memberList: [],
    sendList: [],
    error: null,
    members: [],
    availableMembers: [],
    expiredMembers: [],
    selectedMembers: [],
    selectedTempMembers: [],
    selectedMessage: null,
    messageList: [],
    sentMessageList: [],
    tempMessageList: [],
    newNoticeList: [],
    noticeList: [],
    sortType: 0,
    noticeCheckRes: null,
    loadRes: null,
    saveRes: null,
    deleteRes: null,
    noticeDeleteRes: null,
    result:null,
    membershipResult:null
};

const notificationMessage = handleActions(
  {
    [INITIALIZE]: () => initialState,
    [CHANGE_FORM_FIELD]: (state, { payload: { key, value } }) =>
      produce(state, draft => {
        draft[key] = value;
    }),
    [INIT_RES]: (state) => ({
      ...state,
      noticeCheckRes: null,
      loadRes: null,
      saveRes: null,
      deleteRes: null,
      noticeDeleteRes: null,
    }),
    [ADD_IMAGE]: (state, { payload: image }) => ({
      ...state,
      image: state.image.concat(image),
    }),
    [CHANGE_IMAGE]: (state, { payload: image }) => ({
      ...state,
      image
    }),
    [LOAD_MESSAGE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      messageList: res.infos,
      loadRes: res.result,
      error: null,
    }),
    [LOAD_MESSAGE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [LOAD_NOTICE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      noticeList: res.infos,
      error: null,
    }),
    [LOAD_NOTICE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [CHANGE_NOTICE_STATE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      noticeCheckRes: res.result,
      error: null,
    }),
    [CHANGE_NOTICE_STATE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [LOAD_NEW_NOTICE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      newNoticeList: res.infos,
      error: null,
    }),
    [LOAD_NEW_NOTICE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [SAVE_MESSAGE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      saveRes: res.result,
    }),
    [SAVE_MESSAGE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [DELETE_MESSAGE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      deleteRes: res.result,
    }),
    [DELETE_MESSAGE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [LOAD_MESSAGE_DETAIL_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      selectedMessage: res.infos.map(item => {
        let photoArr = item.photoURL.split('%&')
        let allMemberDataList = []
        let allMemberList = []

        if(item.memberDataList && item.memberDataList.length > 0) {
            allMemberDataList = item.memberDataList
        }
        if(item.memberList && item.memberList.length > 0) {
            allMemberList = item.memberList
        }

        for (let i = 0; i < res.infos.length; i++) {
          if(res.infos[i].rootID == item.memberMessageID) {
            allMemberDataList = allMemberDataList.concat(res.infos[i].memberDataList)
            allMemberList = allMemberList.concat(res.infos[i].memberList)
          }
        }

        return item={
        ...item,
        memberDataList: sort(allMemberDataList, 'registTime'),
        memberList: allMemberList,
        photoURL: photoArr,
      }}).filter(item => !item.rootID || item.rootID == "")[0]
    }),
    [LOAD_MESSAGE_DETAIL_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [DELETE_NOTICE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      noticeDeleteRes: res.result,
    }),
    [DELETE_NOTICE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [LOAD_MEMBER_SUCCESS]: (state, { payload: result }) => ({
      ...state,
      result: result.result,
      members: result.infos
      ? result.infos.sort((a, b) => (a.registTime > b.registTime ? 1 : -1))
          .map((member) => {
            const age = birthToAge(member.birth);
            const membershipList = result.membershipInfos.filter(item => { return item.playerID == member.playerID })

            //     const playerMembershipData = membershipData[0].filter(item => { return item.playerID == resp.infos[j].playerID })
            return {
              ...member,
              age,
              sex:member.sex == 1 ? '남자': '여자',
              membershipList
            };
          })
      : [],
      error: null,
    }),
    [LOAD_MEMBERSHIP_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      membershipResult:res.result,
      members: res.membershipInfos?state.members.map((member) => {
        const membershipList = res.membershipInfos.filter(item => { return item.playerID == member.playerID })
        return {
          ...member,
          membershipList
        };
      }):state.members,
    }),
    [LOAD_MEMBERSHIP_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [SELECT_TEMP_MEMBER]: (state, { payload: member }) => ({
      ...state,
      selectedTempMembers: state.selectedTempMembers.concat(member)
    }),
    [SELECT_MEMBER]: (state, { payload: member }) => ({
      ...state,
      selectedMembers: member,
      selectedTempMembers: []
    }),
    [SELECT_MEMBER_DELETE]: (state, { payload: member }) => ({
      ...state,
      selectedMembers: state.selectedMembers.filter(item => {
        return item.playerID != member.playerID
      })
    }),
    [SELECT_TEMP_MEMBER_DELETE]: (state, { payload: member }) => ({
      ...state,
      selectedTempMembers: state.selectedTempMembers.filter(item => {
        return item.playerID != member.playerID
      })
    }),
    [SELECT_TEMP_MEMBER_CONTROL]: (state, { payload: member }) => ({
      ...state,
      selectedTempMembers: member,
    }),
    [MEMBER_TYPE_CHANGE]: (state, { payload: type }) => ({
      ...state,
      sortType: type,
    }),
    [FILTER_DELETE_NOTICE]: (state, { payload: {deleteNoticeList} }) => ({
      ...state,
      messageList: state.messageList.filter((item) => {return !deleteNoticeList.includes(item.memberMessageID)}), 
      noticeList: state.noticeList.filter((item) => {return !deleteNoticeList.includes(item.memberMessageID)}),
      newNoticeList: state.newNoticeList.filter((item) => {return !deleteNoticeList.includes(item.memberMessageID)})
    }),
    [LOAD_DATA]: (state, { payload: {imageArr, member} }) => ({
      ...state,
      image: state.image.concat(imageArr),
      selectedMembers: member,
    }),
    [UPLOAD_IMAGE]: (state, { payload: image }) => ({
      ...state,
      image: [image],
    }),
    [MEMBERS_CHANGE]: (state, { payload: members }) => ({
      ...state,
      availableMembers: members.availableMembers,
      expiredMembers: members.expiredMembers,
      readyMembers: members.readyMembers,
    }),
    [INIT_TEMP_SELECTED_MEMBER]: (state) => ({
      ...state,
      selectedTempMembers: [],
    }),
    [INIT_MESSAGE_LIST]: (state) => ({
      ...state,
      messageList: []
    }),
    [RESET_MEMBER_RESULT]: (state) => ({
      ...state,
      result:null
    }),
    [RESET_MEMBERSHIP_RESULT]: (state) => ({
      ...state,
      membershipResult:null
    }),
    [LOAD_MEMBER_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
  },
  initialState,
);

export default notificationMessage;
