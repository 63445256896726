import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { AutoSizer, List } from 'react-virtualized';
import Slider from '@material-ui/core/Slider';
import ColorPicker, { useColor } from "react-color-palette";
import { GrFormClose } from 'react-icons/gr';
import { GoSearch } from 'react-icons/go';
import { VscTriangleLeft, VscTriangleRight } from 'react-icons/vsc';
import palette from '../../../lib/styles/palette';
import { inputNumberAutoComma } from '../../../lib/commonFunc/commonFunc';
import defaultSms from '../../../resources/images/common/default_sms.png'

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const AddMemberModalWrap = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1200px) {
    width: 100%;
    height: 100%;
  }
`;

const AddMemberBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;
  

  @media screen and (max-width: 1200px) {
      position: absolute;
      top: 180px;
  }
`;

const AddMemberBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 10px 30px 20px 30px;
`;

const BtnAddMemberConfirm = styled.button`
  width: 330px;
  padding: 10px 10px 10px 10px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-right: 20px;

  &:focus {
    outline: none;
  }
`;

const BtnAddMemberClose = styled.button`
width: 120px;
  padding: 10px 10px 10px 10px;
background-color: ${palette.webGray[5]};
color: #fff;
font-size: 1.2rem;
border: 0;
border-radius: .2rem;
cursor: pointer;

&:focus {
  outline: none;
}
`;
const SmsImg = styled.img`
    overflow: hidden;
    width: 350px;
    height: 350px;
    border: 0;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
`;

const AddMemberTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const AddMemberContentBox = styled.div`
  width: 100%;
  height: auto;
  padding: 25px 30px 20px 30px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
`;

const TableBox = styled.div`
  width: 100%;
  height: 350px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[11]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;
const Table = styled.table`
  font-family: 'Noto Sans KR', sans-serif;

  td {
    padding: 5px 5px 30px 30px;
  }

`;

const SmsAdsInfoModal = ({
    visible, 
    closeModal
}) => {
    if(!visible) return false;

    return(
      <AddMemberModalWrap>
          <Background />
          <AddMemberBox>
              <AddMemberTitle>광고 문자 전송 시 주의 사항</AddMemberTitle>
              <AddMemberContentBox>
                <div style={{flex:1, justifyContent:'center', alignItems:'flex-start', padding:'20px'}}>
                    <div style={{fontSize:'1.8rem'}}><span style={{fontWeight:600}}>불법 스팸 방지를 위한 정보통신망법(제50조)</span>에 따라 광고 목적의 문자를 전송할 경우 반드시 다음 사항을 주의해야 합니다.</div>
                    <div style={{fontSize:'1.2rem', paddingTop:'20px', paddingBottom:'50px'}}>광고표기법을 미준수 할 경우 수신자에게 <span style={{fontWeight:600}}>문자가 전송되지 않고 실패</span>할 수 있으며 <span style={{fontWeight:600}}>과태료가 부과</span>될 수 있습니다.</div>
                    <div style={{display:'flex', width:'100%'}}>
                        <div>
                            <div style={{fontSize:'1.8rem', paddingTop:'30px', fontWeight:600}}>1. 광고임을 표시</div>
                            <div style={{fontSize:'1.4rem', paddingTop:'10px'}}> - 문자의 시작 시 '(광고)' 로 표기되어야 하며 '[광고]', {'\'<광고>\''} 등의 방식으로 사용할 수 없습니다. </div>
                            <div style={{fontSize:'1.8rem', paddingTop:'30px', fontWeight:600}}>2. 가장 하단에 무료수신거부 번호 표시</div>
                            <div style={{fontSize:'1.4rem', paddingTop:'10px'}}> - 문자의 마지막에 무료수신거부 번호를 표기하여야 합니다.</div>
                            <div style={{fontSize:'1.8rem', paddingTop:'30px', fontWeight:600}}>3. 광고문자 발송시간</div>
                            <div style={{fontSize:'1.4rem', paddingTop:'10px'}}> - 광고문자는 오후 9시부터 익일 오전 9시 까지 발송할 수 없습니다.</div>
                            <div style={{fontSize:'1.8rem', paddingTop:'50px', fontWeight:600, color:palette.webCyan[5]}}>광고문자란?</div>
                            <div style={{fontSize:'1.2rem', paddingTop:'10px'}}>매장 또는 웹사이트(어플리케이션) 등 업체의 방문을 유도하는 모든 문자를 광고문자라고 합니다.</div>
                        </div>
                        <div style={{width:'350px', justifyContent:'center', alignItems:'center', marginLeft:'50px'}}>
                            <SmsImg src={defaultSms}/>
                        </div>
                    </div>
                </div>
              </AddMemberContentBox>
              <AddMemberBtnBox>
                  <BtnAddMemberClose onClick={() => {
                    closeModal()
                  }}>닫기</BtnAddMemberClose>
              </AddMemberBtnBox>
          </AddMemberBox>
      </AddMemberModalWrap>
    )
};

export default SmsAdsInfoModal;
