import React, { useEffect, useState } from 'react';
import SaleForm from '../../components/locker/SaleForm';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  searchMember,
  changeField,
  initialize,
} from '../../modules/commons/search';
import {
    readPointUser
} from '../../modules/member/point';
import {
  selectTarget,
  setLocker,
  changeFormField,
  saleLocker,
  initializeRes,
  setEndTime,
  initializeSale,
} from '../../modules/locker/clubLocker';
import {
    addClubUserLocker,
    loadClubUserLocker,
    loadClubLocker,
    resetClubUserLockerRes,
    readSubsUser,
    subsDisconnectUserLocker,
    subsConnectUserLocker,
} from '../../modules/member/locker';
import qs from 'qs';
import moment from 'moment';
import { inputNumberRemoveComma } from '../../lib/commonFunc/commonFunc';

const LockerSaleForm = ({ location, history, subsConnectModal, setSubsConnectModal }) => {
  const { lockerSN, lockerID, type, name, startTime, endTime, card, cash, point, pay, price, salePrice } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [lockerOverlapModal, setLockerOverlapModal] = useState(false)
  //const [subsConnectModal, setSubsConnectModal] = useState(false)
  const dispatch = useDispatch();

  const {
    lockers,
    loading,
    club,
    socketKey,
    form,
    searchForm,
    target,
    _modalRes,
    searchResult,
    clubuserlocker,
    addUserLockerRes,
    pointUser,
    userSubs,
    disconnectSubsLockerRes,
    connectSubsLockerRes,

  } = useSelector(({ loading, pointUser, locker, club, socket, clubLocker, search }) => ({
    lockers: locker.club,
    club: club.defaultClub,
    loading: loading['search/SEARCH_MEMBER'],
    socketKey: socket.socketKey,
    form: clubLocker,
    searchForm: search.member,
    _modalRes: clubLocker.res,
    clubuserlocker: locker.clubuserlocker,
    searchResult: search.result,
    addUserLockerRes: locker.addClubUserlockerRes,
    pointUser: pointUser,
    target:clubLocker.target,
    userSubs: locker.userSubs,
    disconnectSubsLockerRes: locker.disconnectSubsLockerRes,
    connectSubsLockerRes: locker.connectSubsLockerRes,
  }));

  const onSearch = (e) => {
    e.preventDefault();
    dispatch(
      searchMember({
        clubID: club.clubID,
        type: searchForm.type,
        keyword: '',
        socketKey,
      }),
    );
  };

  const onSaleAsk = (e) => {
    e.preventDefault();

    let startTime = Math.ceil(new Date(form.startTime).setHours(0, 0, 0, 0) / 1000)
    let endTime = Math.ceil(new Date(form.endTime).setHours(23, 59, 59, 0) / 1000)

    if(!form.target)
    {
        alert('회원을 선택해주세요.');
        return;
    }

    if(endTime < startTime) {
        alert(`정상적인 기간을 입력해주세요.`);
        return;
    }


    for (const userlocker of clubuserlocker) {
        //==>
        let today = new Date().setHours(0, 0, 0, 0)
        
        if(!((startTime < userlocker.startTime && endTime < userlocker.startTime) || (startTime > userlocker.endTime && endTime > userlocker.endTime)))
        {
            if(!(userlocker.startTime * 1000 < today && userlocker.endTime * 1000 < today)) {
              alert(`${userlocker.playerName} 회원이 해당 기간에 이용 중 입니다.\n같은 기간에 락커를 등록 할 수 없습니다.`);
              return false;
            } else {
              setLockerOverlapModal(true);
              return false;
            }
        }
    }

    onSale()
  };

  const onSale = () => {

    setLockerOverlapModal(false);
    
    let startTime = moment(form.startTime).startOf('day').unix()
    let endTime = moment(form.endTime).startOf('day').unix()
    let registTime = moment(form.registTime).unix()

    console.log(new Date(Math.ceil(new Date(form.endTime).setHours(23, 59, 59, 0) / 1000) * 1000), "111111111")
    console.log(new Date(endTime * 1000), "2222222")

    let clubLockUserData = {
        clubUserLockerID:'',
        clubItemID:'',
        lockerID:Number(lockerID),
        card:Number(form.card),
        card2:Number(form.card2),
        company:form.company,
        company2:form.company2,
        authNumber:form.authNumber,
        authNumber2:form.authNumber2,
        InstallmentMonth:form.InstallmentMonth,
        InstallmentMonth2:form.InstallmentMonth2,
        cash:Number(form.cash),
        point:Number(form.point),
        pay:Number(form.pay),
        salePrice:Number(form.card) + Number(form.card2) + Number(form.cash) + Number(form.point) + Number(form.pay),
        price:Number(form.card) + Number(form.card2) + Number(form.cash) + Number(form.point) + Number(form.pay),
        category:Number(form.category),
        state:Number(form.state),
        holdList: [],
        controlList: [],
        playerID:form.target.playerID,
        playerName:form.target.name,
        phone:form.target.phone,
        name:`${form.name} ${lockerSN}`,
        type:Number(form.type),
        startTime:startTime,
        endTime:endTime,
        registTime:registTime,
        unpayment:Number(form.unpayment),
        password:form.password,
        isNew:1,
        subsID:"",
    }
    
    dispatch(
        addClubUserLocker({
        clubID: club.clubID,
        clubLockUserData: clubLockUserData,
        lockerID: lockerID,
        socketKey,
      }),
    );
    
  };

  const onChangeField = (e) => {
    let { name, value } = e.target;
    const commaStr = ['price', 'card', 'cash', 'point', 'pay', 'salePrice', 'password'];
    if (commaStr.some((el) => el.includes(name)) && name != 'password') {
      value = inputNumberRemoveComma(value);
    }
    dispatch(
      changeFormField({
        key: name,
        value,
      }),
    );
  };

  const onChange = (date, name) => {
    const value = moment(date).format('YYYY-MM-DD');
    dispatch(
      changeFormField({
        key: name,
        value,
      }),
    );

    if (name === 'startTime' && form.term != undefined && form.term != 0) {
        dispatch(
            setEndTime({
            startTime: value,
            term: form.term,
            }),
        );
    }
    if(name === 'endTime')
    {
        if(moment(date) < moment(form.startTime))
        {
            dispatch(
              changeFormField({
                key: 'startTime',
                value,
              }),
            );
        }
        
        dispatch(
            changeFormField({
              key: 'term',
              value:'0',
            }),
          );
    }
  };
  const onChangeTerm= (name, value) =>
  {
    //console.log('onChangeTerm : ', name, value );
      dispatch(
        changeFormField({
          key: 'term',
          value:value.value,
        }),
      );
      if(value.value != "0")
      {
        dispatch(
            setEndTime({
            startTime: form.startTime,
            term: value.value,
            }),
        );
      }
  }

  const onClickSubsConnect = ()=>{
    // if(clubuserlocker && clubuserlocker.length > 0)
    // {
    //     for (let i = 0; i < clubuserlocker.length; i++) {
    //         if(clubuserlocker[i].state != 2)
    //         {
    //             alert('사용중인 락커는 구독 연결을 할 수 없습니다.')
    //             return;
    //         }
    //     }
    // }

    setSubsConnectModal(true)
  }

  const onDisconnectSubs = ()=>{
    let clubUserLockerID = ""
    if(clubuserlocker && clubuserlocker.length > 0)
    {
        for (let i = 0; i < clubuserlocker.length; i++) {
            if(clubuserlocker[i].subsID && clubuserlocker[i].subsID != '')
            {
                clubUserLockerID = clubuserlocker[i].clubUserLockerID
                break;
            }
        }
    }

    if(clubUserLockerID != '')
    {
        dispatch(
            subsDisconnectUserLocker({
            clubID: club.clubID,
            lockerID: lockerID,
            clubUserLockerID: clubUserLockerID,
            paymentSubscriptionID: userSubs.paymentSubscriptionID,
            socketKey,
          }),
        );
    }

  }

  const onConnectSubs = (option)=>{
    const date = new Date();
    date.setHours(0,0,0,0);
    let startTime = Number(date.getTime()/1000)
    date.setMonth(date.getMonth()+1);
    date.setDate(date.getDate()-1);
    date.setHours(23, 59, 59);
    let endTime = Number(date.getTime()/1000)
    
    for (const userlocker of clubuserlocker) {
        //==>
        let today = new Date().setHours(0, 0, 0, 0)
        
        if(!((startTime < userlocker.startTime && endTime < userlocker.startTime) || (startTime > userlocker.endTime && endTime > userlocker.endTime)))
        {
            if(!(userlocker.startTime * 1000 < today && userlocker.endTime * 1000 < today)) {
              alert(`${userlocker.playerName} 회원이 해당 기간에 이용 중 입니다.\n같은 기간에 락커를 등록 할 수 없습니다.`);
              return;
            }
        }
    }
    
    let clubLockUserData = {
        clubUserLockerID:'',
        clubItemID:'',
        lockerID:Number(lockerID),
        card:0,
        card2:0,
        company:"",
        company2:"",
        authNumber:"",
        authNumber2:"",
        InstallmentMonth:"",
        InstallmentMonth2:"",
        cash:0,
        point:0,
        pay:0,
        salePrice:0,
        price:0,
        category:Number(form.category),
        state:Number(form.state),
        holdList: [],
        controlList: [],
        playerID:form.target.playerID,
        playerName:form.target.name,
        phone:form.target.phone,
        name:`${form.name} ${lockerSN}`,
        type:Number(form.type),
        startTime:0,
        endTime:0,
        registTime:moment(form.registTime).unix(),
        unpayment:0,
        password:"",
        isNew:1,
        subsID:"",
    }
    console.log('onConnectSubs', option, clubLockUserData)

    dispatch(
        subsConnectUserLocker({
        clubID: club.clubID,
        option: option,
        lockerID: lockerID,
        clubLockUserData: clubLockUserData,
        paymentSubscriptionID: userSubs.paymentSubscriptionID,
        socketKey,
      }),
    );
  }

  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeField({
        form: 'member',
        type: name,
        keyword: value,
      }),
    );
  };

  useEffect(() => {
    if (_modalRes && _modalRes.result) {
      history.push('/locker');
    }
  }, [_modalRes, history]);

  useEffect(() => {
    if (club) {
      dispatch(
        setLocker({
          lockerID,
          type,
          lockerSN,
          name,
        }),
      );
    }
  }, [
    club,
    dispatch,
    location.search,
    lockerID,
    lockerSN,
    name,
    socketKey,
    type,
  ]);

  useEffect(() => {
    if (addUserLockerRes == 1) {
        alert(`락커를 판매 하였습니다.`);
        dispatch(
            resetClubUserLockerRes(
                {
                    res:0
                }
            ),
        );
        dispatch(
            loadClubUserLocker({
            clubID: club.clubID,
            lockerID: lockerID,
            socketKey,
          }),
        );
        dispatch(initializeSale());
        dispatch(
          setEndTime({
          startTime: form.startTime,
          term: form.term,
          })
        );
         dispatch(loadClubLocker({ socketKey, clubID: club.clubID }));
    }
  }, [addUserLockerRes]);

  useEffect(() => {
    console.log('disconnectSubsLockerRes : ', disconnectSubsLockerRes)
    if(disconnectSubsLockerRes)
    {
        setSubsConnectModal(false)
        dispatch(resetClubUserLockerRes())
        alert('구독연결이 해지되었습니다.')
        dispatch(resetClubUserLockerRes())
        dispatch(
            resetClubUserLockerRes(
                {
                    res:0
                }
            ),
        );
        dispatch(
            loadClubUserLocker({
            clubID: club.clubID,
            lockerID: lockerID,
            socketKey,
          }),
        );
        dispatch(initializeSale());
        dispatch(
          setEndTime({
          startTime: form.startTime,
          term: form.term,
          })
        );
        dispatch(loadClubLocker({ socketKey, clubID: club.clubID }));
    }
  }, [disconnectSubsLockerRes]);
  
  useEffect(() => {
    console.log('connectSubsLockerRes : ', connectSubsLockerRes)
    if(connectSubsLockerRes)
    {
        setSubsConnectModal(false)
        alert('구독이 연결되었습니다.')
        dispatch(resetClubUserLockerRes())
        dispatch(
            resetClubUserLockerRes(
                {
                    res:0
                }
            ),
        );
        dispatch(
            loadClubUserLocker({
            clubID: club.clubID,
            lockerID: lockerID,
            socketKey,
          }),
        );
        dispatch(initializeSale());
        dispatch(
          setEndTime({
          startTime: form.startTime,
          term: form.term,
          })
        );
         dispatch(loadClubLocker({ socketKey, clubID: club.clubID }));
    }
  }, [connectSubsLockerRes]);

  useEffect(() => {
    dispatch(initialize('member'));
  }, [dispatch]);
  
  useEffect(() => {
    if (club && form.target && form.target.playerID) {
      dispatch(
          readPointUser({
          clubID: club.clubID,
          targetID: form.target.playerID,
          socketKey,
        }),
      );
      dispatch(
          readSubsUser({
          clubID: club.clubID,
          targetID: form.target.playerID,
          socketKey,
        }),
      );
    }
  }, [club, dispatch, socketKey, form.target && form.target.playerID]);

  useEffect(() => {
    if (form) {
      dispatch(
        setEndTime({
        startTime: form.startTime,
        term: form.term,
        })
      );
    }
  }, [form.startTime, form.term]);
  return (
    <SaleForm
      searchResult={searchResult}
      onSearch={onSearch}
      onSale={onSale}
      onSaleAsk={onSaleAsk}
      form={form}
      onChangeField={onChangeField}
      onChange={onChange}
      onChangeSearch={onChangeSearch}
      loading={loading}
      lockerSN={lockerSN}
      onChangeTerm={onChangeTerm}
      point={pointUser}
      setLockerOverlapModal={setLockerOverlapModal}
      lockerOverlapModal={lockerOverlapModal}
      userSubs={userSubs}
      onClickSubsConnect={onClickSubsConnect}
      setSubsConnectModal={setSubsConnectModal}
      subsConnectModal={subsConnectModal}
      clubuserlocker={clubuserlocker}
      onDisconnectSubs={onDisconnectSubs}
      onConnectSubs={onConnectSubs}
    />
  );
};

export default withRouter(LockerSaleForm);
