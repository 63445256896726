import React, { useEffect, useState, useRef } from 'react';
import ImageTemplate from '../../components/app/ImageTemplate';
import { useDispatch, useSelector } from 'react-redux';
import imageCompression from 'browser-image-compression'
import moment from 'moment';
import { 
    init,
    loadTemplate,
    deleteTemplate,
    setSelectedTemplate,
    viewNewImage,
} from '../../modules/app/template'
import dotenv from 'dotenv'
import { set } from 'date-fns';
dotenv.config()

const ImageTemplateContainer = ({ history }) => {
    const {
      club,
      socketKey,
      templateList,
      deleteRes,
      newImage,
    } = useSelector(({ template, club, socket, loading }) => ({
      club: club.defaultClub,
      socketKey: socket.socketKey,
      templateList: template.templateList,
      deleteRes: template.deleteRes,
      newImage: template.newImage
    }));
    
    const dispatch = useDispatch()

    const templateArr = [
        {name:"template1_sm", text4: false, size:"small", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template1_sm.jpg",
        text1:`O P E N  E V E N T`, text2:`모든 회원권 및 락커 30% 할인`, text3: `2021. 03. 01 ~ 2021. 03. 31`},
        {name:"template1_lg", text4: false, size:"large", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template1_lg.jpg",
        text1:`헬스클럽
OPEN EVENT`, 
        text2:`- 1:1 개인 PT EVENT -

"30회 등록 시 헬스 이용권 3개월 서비스"
        
"40회 등록 시 헬스 이용권 5개월 서비스"
        
"50회 등록 시 헬스 이용권 5개월 서비스"`, 
        text3: `
- 기간 -

2021. 01. 01 ~ 2021. 03. 01`},
        {name:"template2_sm", text4: false, size:"small", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template2_sm.jpg",
        text1:`모든 회원권 및 락커 30% 할인`, 
        text2:`회원권 3개월
9만원`, 
        text3: `2021. 03. 01 ~ 2021. 03. 31`},
        {name:"template2_lg", text4: false, size:"large", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template2_lg.jpg",
        text1:`2021. 03. 01 ~ 2021. 03. 31`, 
        text2:`여름 맞이 PT 이벤트`, 
        text3: `여름 맞이 파격 이벤트!
- 1년 헬스 + GX = 30만원 -

핫한 GX 프로그램!
- 줌바, 다이어트댄스, 요가 -

PT 가입 회원님께 드리는 혜택!
- 스포츠 가방, 텀블러, 스포츠타월 제공 -`},
        {name:"template3_sm", text4: false, size:"small", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template3_sm.jpg",
        text1:`EVENT`, 
        text2:`신규 오픈 기념
1:1 PT 할인`, 
        text3: `2021. 03. 01 ~ 2021. 03. 31`},
        {name:"template3_lg", text4: false, size:"large", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template3_lg.jpg",
        text1:`2021. 03. 01 ~ 2021. 03. 31`, 
        text2:`여름 맞이
파격 이벤트!`, 
        text3: `1. 모든 헬스장 이용권 30% 할인!

2. 트랩 운동 포인트 20% 추가 획득!

3. 1:1PT 이용권 15% 할인`},
        {name:"template4_sm", text4: false, size:"small", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template4_sm.jpg",
        text1:`2021. 03. 01 ~ 2021. 03. 31`, 
        text2:`회원권 및 락커 할인`, 
        text3: `바로가기`},
        {name:"template4_lg", text4: true, size:"large", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template4_lg.jpg",
        text1:`2021. 03. 01 ~ 2021. 03. 31`, 
        text2:`모든 헬스장 이용권
30% 할인!`, 
        text3: `1. 모든 헬스장 이용권 30% 할인!

2. 트랩 운동 포인트 20% 추가 획득!

3. 1:1PT 이용권 15% 할인`,
        text4: `1:1PT | 헬스 | GT | 크로스핏 | 스피닝 | 요가 | 필라테스`},
        {name:"template5_sm", text4: false, size:"small", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template5_sm.jpg",
        text1:`2021. 03. 01 ~ 2021. 03. 31`, 
        text2:`개인 PT 30% 할인`, 
        text3: `1:1 전문 트레이너 상담`},
        {name:"template5_lg", text4: true, size:"large", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template5_lg.jpg",
        text1:`2021. 03. 01 ~ 2021. 03. 31`, 
        text2:`여름 맞이 회원권
할인 EVENT`, 
        text3: `1. 모든 헬스장 이용권 30% 할인!

2. 트랩 운동 포인트 20% 추가 획득!

3. 1:1PT 이용권 15% 할인`,
        text4: `- 1:1PT | 헬스 | GT | 크로스핏 | 스피닝 | 요가 | 필라테스 -`},
        {name:"template6_sm", text4: false, size:"small", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template6_sm.jpg",
        text1:`2021. 03. 01 ~ 2021. 03. 31`, 
        text2:`모든 회원권 50% 할인`, 
        text3: `1:1PT | 헬스 | GT | 크로스핏 | 스피닝 | 요가 | 필라테스`},
        {name:"template6_lg", text4: true, size:"large", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template6_lg.jpg",
        text1:`2021. 03. 01 ~ 2021. 03. 31`, 
        text2:`모든 회원권 50% 할인`, 
        text3: `1. 모든 헬스장 이용권 30% 할인!

2. 트랩 운동 포인트 20% 추가 획득!

3. 1:1PT 이용권 15% 할인`,
        text4: `1:1PT | 헬스 | GT | 크로스핏 | 스피닝 | 요가 | 필라테스`},
        {name:"template7_sm", text4: false, size:"small", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template7_sm.jpg",
        text1:`OPEN EVENT`, 
        text2:`회원권 6개월
50% 할인`, 
        text3: `헬스클럽 이용권 / 요가 / 스피닝`},
        {name:"template7_lg", text4: false, size:"large", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template7_lg.jpg",
        text1:`6개월 회원권
50% 할인 이벤트`, 
        text2:`헬스장 오픈 1주년을 맞이하여
6개월 회원권 50% 할인 이벤트를 진행합니다.

* 이벤트 상품 구매는 앱에서 가능합니다.`, 
        text3: `이벤트 기간 : 2021. 4. 1 ~ 2021. 4. 31`},
        {name:"template8_sm", text4: false, size:"small", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template8_sm.jpg",
        text1:`트랩 4월 EVENT`, 
        text2:`모든 회원권 & PT 할인`, 
        text3: `2021. 4. 1 ~ 2021. 4. 30`},
        {name:"template8_lg", text4: true, size:"large", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template8_lg.jpg",
        text1:`트랩 4월 EVENT`, 
        text2:`회원권 & PT 할인 이벤트`, 
        text3: `1. 모든 회원권 30% 할인

2. 1:1 PT 10회 이용권 10% 할인

3. 운동복 무료 이용

4. 락커 이용권 30% 할인`,
        text4: `이벤트 기간 : 2021. 4. 1 ~ 2021. 4. 31`,},
        {name:"template9_sm", text4: false, size:"small", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template9_sm.jpg",
        text1:`모든 회원권`, 
        text2:`50% 할인`, 
        text3: `2021. 4. 1 ~ 2021. 4. 30`},
        {name:"template9_lg", text4: true, size:"large", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template9_lg.jpg",
        text1:`모든 회원권`, 
        text2:`50% 할인 이벤트!`, 
        text3: `이벤트 기간 : 2021. 4. 1 ~ 2021. 4. 31`,
        text4: `헬스 클럽 신규 오픈을 기념하여 모든 회원권을 50%
할인 판매중입니다. 해당 이벤트 상품은 모바일 앱
상풍 페이지에서 구매 가능합니다.`,},
        {name:"template10_sm", text4: false, size:"small", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template10_sm.jpg",
        text1:`4월 CLASS`, 
        text2:`요가 & 필라테스
다이어트반 모집`, 
        text3: `인원 제한 있으니 빨리 신청하세요!`},
        {name:"template10_lg", text4: false, size:"large", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template10_lg.jpg",
        text1:`4월 CLASS`, 
        text2:`요가 & 필라테스
다이어트반 모집`, 
        text3: `1. 혼자 다이어트하기 힘드신 분
2.다이어트 방법을 모르시는 분
3.다이어트를 자주 실패하시는분`,
        },
        {name:"template11_sm", text4: false, size:"small", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template11_sm.jpg",
        text1:`트랩 앱 사용 시`, 
        text2:`500 Point
즉시 지급`, 
        text3: `PT예약 & 공지 확인도 간편하게!`},
        {name:"template11_lg", text4: false, size:"large", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template11_lg.jpg",
        text1:`트랩 앱 이용 혜택`, 
        text2:`1. 가입, 출석 시 포인트 획득
2. 유산소 및 웨이트 운동 시 포인트 획득
3. 각종 이벤트 참여 및 소식 확인 가능
4. 간편한 PT 및 수업 예약
5. 모바일 간편 상품 구매`, 
        text3: `※ 문의 사항 : 02) 1234 - 5678`,
        },
        {name:"template12_sm", text4: false, size:"small", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template12_sm.jpg",
        text1:`헬스클럽 입장 시`, 
        text2:`마스크 착용 필수!`, 
        text3: `마스크 미착용 시 입장이 불가능합니다.`},
        {name:"template12_lg", text4: false, size:"large", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template12_lg.jpg",
        text1:`입장 시 마스크 착용 필수!`, 
        text2:`코로나 단게 격상 및 정부 지침에 따라서
마스크 미착용 시 입장이 불가능하며 운동 중에도
반드시 마스크를 착용해야 합니다.`, 
        text3: `마스크 미착용 상태로 운동 시 과태료가
부가될 수 있으며 회원간 대화는 삼가해 주시기 바랍니다.`,
        },
        {name:"template13_sm", text4: false, size:"small", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template13_sm.jpg",
        text1:`이유없이 좋아지는`, 
        text2:`몸매는 없다.`, 
        text3: `#필라테스 #요가 #다이어트 #1:1PT`},
        {name:"template13_lg", text4: false, size:"large", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template13_lg.jpg",
        text1:`이유없이 좋아지는`, 
        text2:`몸매는
없다.`, 
        text3: `#필라테스 #요가 #스피닝
#다이어트 #1:1PT`,
        },
        {name:"template14_sm", text4: false, size:"small", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template14_sm.jpg",
        text1:`이유없이 커지는`, 
        text2:`근육은 없다.`, 
        text3: `#웨이트 #유산소 #프로틴 #1:1PT`},
        {name:"template14_lg", text4: false, size:"large", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template14_lg.jpg",
        text1:`이유없이 커지는`, 
        text2:`근육은
없다.`, 
        text3: `#웨이트 #유산소 
#프로틴 #1:1PT`,
        },
    ]

    const deleteAWSImage = (imageName) => {
      //--> delete
    //   var AWS = require('aws-sdk');
    //   var s3 = new AWS.S3({accessKeyId: process.env, secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region:'ap-northeast-2'});
      
    //   var params = {
    //       Bucket: 'healthnavi',
    //       Delete: { // required
    //           Objects: [ // required
    //               {
    //                   Key: 'clubdata/template/'+imageName
    //               },
    //           ],
    //       },
    //   };

    //   s3.deleteObjects(params, function (err, data) {
    //       if (err) console.log(err, err.stack); // an error occurred
    //       else console.log(data);           // successful response
    //   });
    }

    const onDeleteImage = (image) => {
      //deleteAWSImage(image.templateURL)
      dispatch(deleteTemplate({
        clubID: club.clubID,
        clubTemplateID: image.clubTemplateID,
        socketKey,
      }))
    }

    const onViewNewImage = () => {
      dispatch(viewNewImage())
    }

    const onSetSelectedTemplate = (template) => {
      dispatch(setSelectedTemplate({template}))
      history.push(
          `/mobile/imageTemplate/regist`,
        );
    }
  
    useEffect(() => {
      dispatch(loadTemplate({
        clubID: club.clubID,
        socketKey
      }))
    }, []);
  
    useEffect(() => {
      if(deleteRes && deleteRes == 1) {
        alert("해당 이미지를 삭제했습니다.")
        dispatch(init())
        dispatch(loadTemplate({
          clubID: club.clubID,
          socketKey
        }))
      }
    }, [deleteRes]);
  
    useEffect(() => {
      dispatch(init())
    }, []);

    return (
        <ImageTemplate
          onSetSelectedTemplate={onSetSelectedTemplate}
          templateList={templateList}
          onDeleteImage={onDeleteImage}
          templateArr={templateArr}
          newImage={newImage}
          onViewNewImage={onViewNewImage}
        />
    );
};

export default ImageTemplateContainer;
