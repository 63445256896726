import React from 'react';

import { useHistory } from 'react-router-dom';
import { SideSpacer } from '../../components/common/Spacer';
import HeaderContainer from '../../containers/common/HeaderContainer';
import Navigation from '../../components/common/Navigation';
import {
  CommonContainer,
  HistoryBlock,
  NormalHistory,
  HistoryURL,
  HistoryActiveStyle,
  HorizonWrapper,
  HorizonContainer,
} from '../../components/common/Container';
import AppMainContainer from '../../containers/app/AppMainContainer';
import BannerManagement from '../../components/app/BannerManagement';
import BannerManagementContainer from '../../containers/app/BannerManagementContainer';
import AppNavbar from '../../components/app/AppNavBar'
import SMSContainer from '../../containers/app/SMSContainer';

const SMSPage = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  let history = useHistory();
  if (!user) {
    alert('잘못된 접근입니다 다시 로그인 해 주세요');
    history.push('/');
    return false;
  }
  
  return (
    <>
      <HeaderContainer />
      <Navigation />
      <SideSpacer>
        <CommonContainer>
            <HistoryBlock>
              <NormalHistory style={{fontWeight: 'bold'}} >
                앱관리
              </NormalHistory>
            </HistoryBlock>
            <AppNavbar page={"sms"} /> 
            <SMSContainer history={history}/>
        </CommonContainer>
      </SideSpacer>
    </>
  );
};

export default SMSPage;
