import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import CustomDatePicker from '../common/CustomDatePicker';
import Button from '../common/Button';
import { FileDrop } from 'react-file-drop'
import { AutoSizer, List } from 'react-virtualized';
import { FaTrash } from 'react-icons/fa';
import Select from 'react-select';
import { ImFilePicture } from 'react-icons/im';
import {
  timeValueToDate,
  inputNumberAutoComma,
  sort,
  sortReverse,
} from '../../lib/commonFunc/commonFunc';
import { FaArrowLeft } from 'react-icons/fa';
import palette from '../../lib/styles/palette';
import TableScrollbar from 'react-table-scrollbar';
import { Ring } from 'react-awesome-spinners';
import { ImPlus } from 'react-icons/im';
import profile from '../../resources/images/common/default_profile.jpg';
import MessageAddMember from './modal/MessageAddMember'
import TempStorage from './modal/TempStorage'
import TempModal from '../common/ConfirmModal'
import ConfirmModal from '../common/ConfirmModal'
import InitModal from '../common/ConfirmModal'
import landingPage from '../../modules/app/landingPage';
import { AiFillCheckCircle, AiOutlineCheckCircle, AiFillQuestionCircle } from 'react-icons/ai';
import SmsPaymentModal from './modal/SmsPaymentModal';
import SmsSendRecord from './modal/SmsSendRecord';
import SmsPaymentResultModal from './modal/SmsPaymentResultModal';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SmsAdsInfoModal from './modal/SmsAdsInfoModal';
import SmsNumberInfoModal from './modal/SmsNumberInfoModal';

const MsgContainer = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
`;

const LeftBox = styled.div`
    width: 100%;
`;
const RightBox = styled.div`

`;

const PreviewBox = styled.div`
    width: 330px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid ${palette.webGray[5]};
    height: 500px;
    margin: 20px 130px 20px 130px;
    border-radius: .3rem;
    background-color: #fff;
    box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.05);
`;

const PreviewTop = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
`;

const Top1 = styled.div`
    width: 10px;
    height: 10px;
    background-color: ${palette.webGray[5]};
    margin-right: 20px;
    border-radius: 100%;
`;

const Top2 = styled.div`
    width: 70px;
    height: 10px;
    border-radius: .3rem;
    background-color: ${palette.webGray[5]};
`;

const Preaview = styled.div`
    display: flex;
    flex-direction: column;
    width: 85%;
    height: 80%;
    border: 1px solid ${palette.webGray[15]};
    border-radius: .2rem;
    background-color: ${palette.webGray[15]};
`;

const TitleBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
`;

const SubBox = styled.div`
    display: flex;
    width: 100px;

    & .block {
        padding: 0;
        height: 20px;
        width: 8px;
        border-radius: .1rem;
        background-color: ${palette.webCyan[5]};
        margin-right: 10px;
    }
`;

const Sub = styled.div`
    font-size: 1rem;
    font-weight: bold;
`;

const TitleInputBox = styled.input`
    width: 100%;
    font-size: 1rem;
    padding: 5px 10px;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[16]};

    &:focus {
        outline: none;
    }
`;

const InputBox = styled.textarea`
    width: 100%;
    font-size: 1rem;
    height: 300px;
    padding: 5px 10px;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[16]};
    margin-top: 10px;
    resize: none;

    &:focus {
        outline: none;
    }
`;

const PhotoImgBox = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: auto;
    display: flex;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &::-webkit-scrollbar {
      height: 10px;
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }
`;

const PhotoBox = styled.div`
    width: 100%;
    font-size: 1rem;
    height: 300px;
    padding: 5px 0;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[16]};
    background-color: #F0F0F0;
    margin-top: 10px;
    resize: none;

    & div.svgBox {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

    }
    & svg {
        font-size: 6rem;
        color: ${palette.webGray[5]};
    }

    &:hover {
        opacity: .7;
    }

    &:focus {
        outline: none;
    }

    &.drag {
        opacity: .7;
    }
`;

const PhotoImg = styled.div`
    position: relative;
    min-width: 200px; 
    width: 100%;
    margin: 0 1%;
    height: 100%;
    border-radius: .3rem;
    overflow: hidden;

    & div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        opacity: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        & svg {
            font-size: 3rem;
            color: #000;
            cursor: pointer;
        }

        &:hover {
            opacity: .7;
        }
    }

    & img {
        width: 100%;
        height: 100%;
        background-color: ${palette.webGray[15]};
        object-fit: contain;

        &:hover {
            opacity: .7;
        }
    }
`;

const ContentBox = styled.div`
    width: 100%;
    margin-bottom: 30px;
`;

const PreaveiwContent = styled.div`
    flex: 1;
    overflow-y: auto;
    background-color: ${palette.webGray[15]};
    display: flex;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }

    &:hover {
        &::-webkit-scrollbar {
        width: 10px;
        display: block;
        }
    }
`;

const ClubPhotoBox = styled.div`
    width: 60px;
    height: 100%;
`;

const MsgBox = styled.div`
    width: 100%;
    height: fit-content;
    margin-bottom: 20px;
`;

const ClubPhoto = styled.img`
    border-radius: 100%;
    width: 100%;
    height: auto;
    padding: 6px;
    object-fit: contain;
    margin-top: 10px;
`;

const MsgBoxTop = styled.div`
    width: 100%;
    font-size: .85rem;
    color: ${palette.webGray[7]};
    margin-top: 25px;
`;

const Msg = styled.div`
    width: 95%;
    padding: 10px;
    background-color: #fff;
    margin-top: 10px;
    border-radius: .3rem;
`;

const MsgContents = styled.div`
    width: 100%;
    font-size: .85rem;
    line-height: 1.4rem;
    word-break: break-all;
    white-space: pre-line;
    margin-bottom: 10px;
`;

const MsgPhoto = styled.div`
    width: 100%;
    
    & img {
        width: 100%;
        height: auto;
        margin-bottom: 10px;
        border-radius: .3rem;
    }
`;

const BtnBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
`;

const Btn = styled.div`
    width: 150px;
    text-align: center;
    padding: 15px 0;
    border: 0;
    border-radius: .2rem;
    margin: 0 10px;
    background-color: ${palette.webGray[5]};
    color: #fff;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

const BtnSend = styled.div`
    width: 150px;
    text-align: center;
    padding: 15px 0;
    border: 0;
    font-weight: 600;
    margin: 0 10px;
    border-radius: .2rem;
    background-color: ${palette.webCyan[5]};
    color: #fff;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

const BtnAddMember = styled.div`
    width: 100px;
    text-align: center;
    padding: 4px 0;
    border: 1px solid ${palette.webGray[5]};
    border-radius: .2rem;
    background-color: #fff;
    font-size: .95rem;
    color: #000;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

const MemberBox = styled.div`
    width: 100%;
    height: 150px;
    border: 1px solid ${palette.webGray[5]};
    border-bottom-left-radius: .2rem;
    border-bottom-right-radius: .2rem;
    border-top: 0;
    overflow: hidden;
    background-color: #fff;
    
    & :focus {
        outline: none;
    }

    & ::-webkit-scrollbar {
      width: 10px;
    }
    & ::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    & ::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }
`;

const MemberBoxHeader = styled.div`
    display: flex;
    align-items:center;
    width: 100%;
    height: 40px;
    background-color: ${palette.webGray[15]};
    font-size: .95rem;
    font-weight: bold;
    border: 1px solid ${palette.webGray[5]};
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem;
    margin-top: 10px;

    & .name {
        width: 20%;
        text-align: center;
    }
    & .account {
        width: 60%;
        text-align: center;
    }
    & .delete {
        width: 20%;
        text-align: center;
    }
`;

const MemberListItem = styled.ul`
    width: 100%;
    display: flex;
    align-items: center;

    border-bottom: 1px solid ${palette.webGray[15]};

    & li {
        text-align: center;
        font-size: .95rem;

        &.name {
            width: 20%;
        }
        &.account {
            width: 60%;
        }
        &.delete {
            width: 20%;
            
            & button {
                border: 0;
                border-radius: .2rem;
                background-color: ${palette.warning};
                color: #fff;
                cursor: pointer;
                padding: 4px 10px;

                &:focus {
                    outline: none;
                }
            }
        }
    }
`;

const MemberNullBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: .95rem;
    color: ${palette.webGray[6]};
`;
const MyImageModalWrap = styled.div`
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const MyImageBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;
const MyImageBtnBox = styled.div`
    width: 100%;
  display: flex;
  padding: 10px 25px;
  margin-bottom: 15px;
  justify-content: center;
  font-size: 1rem;
  text-align: right;

  & button {
    cursor: pointer;
    padding: 4px 20px;
    text-align: center;
    border: none;
    font-size: .95rem;
    color: #fff;
    border-radius: 3px;
    background-color: ${palette.webGray[5]};

    &:focus{
      outline: none;
    }     
    
    &.confirm {
      background-color: ${palette.webCyan[5]};
      margin-right: 10px;
    }
  }
`;
const MyImageTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const MyImageContentBox = styled.div`
  width: 100%;
  height: auto;
  padding: 20px 30px;
`;
const SortBox = styled.ul`
width: 100%;
display: flex;
align-items: center;
margin-top: 20px;
margin-bottom: 15px;


& li {
    padding: 0 15px;
    font-size: 1.05rem;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    & span {
        position: absolute;
        top: -20px;
        font-size: .85rem;
        color: ${palette.webGray[6]};

    }
        &.active {
            font-weight: bold;
        }
    }
`;

const MyImageList = styled.ul`
    width: 800px;
    height: 500px;
    display: flex;
    padding: 20px 0;
    flex-wrap: wrap;
    display: flex;
    overflow-y: auto;
    border: 1px solid ${palette.webGray[15]};
    border-radius: .2rem;
    background-color: #fff;
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
    }
`;  

const MyImageItem = styled.li`
    width: 29%;
    margin: 0 2% 20px 2%;
`;

const ImageBox = styled.div`
    width: 100%;
    height: 270px;
    background-color: ${palette.webGray[17]};
    border-radius: .3rem;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
            width: 100%;
            height: auto;
            cursor: pointer;

            &:hover {
                opacity: .7;
            }
    }
`;

const ImageTitle = styled.div`
    width: 100%;
    text-align: center;
    font-size: .95rem;
    font-weight: bold;
    padding: 10px 4px;
    overflow:hidden; 
    text-overflow:ellipsis; 
    white-space:nowrap;
    background-color: #fff;
`;

const ImageRegistTime = styled.div`
    display: flex; 
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    font-size: .85rem;
    color: ${palette.webGray[6]};

    & svg {
        position: absolute;
        right: 4px;
        font-size: 1.1rem;
        cursor: pointer;
    }
`;

const MyImageNullBox = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content:center;
    align-items:center;
    font-size: .85rem;
    color: ${palette.webGray[5]};
`;

const SettingCheckBoxWrap = styled.div`
display: flex;
align-items: center;
padding: 25px 25px 25px 25px;

& span {
    color: rgba(0, 0,0 , .7);

  & input {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    width: 50px;
    font-size: 1rem;
    text-align: center;
    color: rgba(0, 0,0 , .7);

    &:focus {
      outline: none;
    }
  }
}

& div {
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-right: 30px;
    cursor: pointer;

  &.defaultCursor {
    cursor: default;
  }

  &:nth-last-child(1) {
    margin-right: 0px;
  }

  & svg {
    font-size: 1.9rem;
    color: ${palette.webGray[6]};
    margin-right: 10px;
    
    &.active {
      color: ${palette.webCyan[5]};
    }

  }
}
`;

const SMS = ({
    messageType,
    isADS,
    setIsADS,
    image, 
    club, 
    isDrag,
    onDrop,
    onTargetClick,
    fileInputRef,
    onFileInputChange,
    setIsDrag,
    addMemberModal,
    setAddMemberModal,
    members,
    onSelectMember,
    selectedMembers,
    onSelectTempMember,
    selectedTempMembers,
    onSelectMemberDelete,
    openModal,
    onSelectTempMemberDelete,
    closeModal,
    onSelectedTempMemberControl,
    sortType,
    onMemberTypeChange,
    expiredMembers,
    availableMembers,
    deleteImage,
    paymentModal,
    setPaymentModal,
    onSaveMessage,
    sentMessageList,
    content,
    setContent,
    initPage,
    onloadData,
    uploadMyImage,
    templateList,
    setIsAllDelete,
    isAllDelete,
    loading,
    selectedMessage,
    onLoadDetailMessage,
    onLoadMember,
    title,
    setTitle,
    myPoint,
    smsPrice,
    lmsPrice,
    mmsPrice,
    smsNumber,
    priceList,
    onPaymentSMS,
    freeNagative,
    resetSendRecord,
    sendRecordModal,
    setSendRecordModal,
    recordList,
    paymentResultModal,
    setPaymentResultModal,
    paymentResultList,
    resetPaymentResult,

    readyMembers,
    targetSex,
    targetAgeMin,
    targetAgeMax,
    onMemberSexChange,
    onMemberAgeMinChange,
    onMemberAgeMaxChange,
    adsInfoModal,
    setAdsInfoModal,
    smsNumberModal,
    setSmsNumberModal,
}) => {

    console.log("club : ", club)
    const [confirmModal, setConfirmModal] = useState(false);
    const [tempModal, setTempModal] = useState(false);
    const [initModal, setInitModal] = useState(false);
    const [myImageModal, setMyImageModal] = useState(false)
    const [templateSize, setTemplateSize] = useState(1)
    const settingRef = useRef(null);

    let myImage = [];

    if(templateList && templateList.length > 0) {
        if(templateSize == 1) {
            myImage = templateList
        } else if(templateSize == 2) {
            myImage = templateList.filter(item => {return item.size == "large"})
        } else if(templateSize == 3) {
            myImage = templateList.filter(item => {return item.size == "small"})
        }
    }


    const selectedMemberRenderer = useCallback(
      ({ index, key, style }) => {
        const member = selectedMembers[index];
        return (
          <MemberListItem
            style={style}
            key={index}
          >
              <li className="name">{member.name}</li>
              <li className="account">{member.account}</li>
              <li className="delete"><button  onClick={() => onSelectMemberDelete(member)}>삭제</button></li>
          </MemberListItem>
        );
      },
      [selectedMembers],
    );

    const getByteSize = (str)=>{
        let byteSize = 0;
  
        for (let i = 0; i < str.length; i++) {
          const charCode = str.charCodeAt(i);
          
          if (charCode <= 0x007F) {
            // ASCII 문자 (영문, 숫자, 공백 등): 1바이트
            byteSize += 1;
          } else {
            // 한글 및 기타 비-ASCII 문자: 2바이트
            byteSize += 2;
          }
        }
        
        return byteSize;
    }

    const textSize = getByteSize(isADS?(`(광고) ${club && club.name}\n`+content+`\n${freeNagative}`):content);

    // EUC-KR 기준 바이트 수 계산 함수
    const calculateBytes = (str) => {
        let bytes = 0;
        for (let i = 0; i < str.length; i++) {
        const charCode = str.charCodeAt(i);
        bytes += charCode <= 0x007F ? 1 : 2;  // ASCII(영문, 숫자, 공백) = 1바이트, 한글 = 2바이트
        }
        return bytes;
    };

    const price = messageType == "SMS"?smsPrice:(messageType == "LMS"?lmsPrice:mmsPrice)

    return(
        <>
            <MsgContainer>
                <LeftBox>
                    <div style={{padding:'10px 10px'}}>
                        {
                            (smsNumber && smsNumber != "")?
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'100px', fontSize:'20px', fontWeight:500}}>발신번호</div>
                                <div style={{fontSize:'20px', paddingRight:'15px', fontWeight:500}}>{smsNumber}</div>
                                <button style={{marginRight:'20px', padding:'10px 30px', borderRadius:'.2rem', border:'0', cursor:'pointer', backgroundColor: palette.webCyan[5], color: '#FFFFFF', fontSize:'1.1rem'}}
                                    onClick={() => {setSmsNumberModal(true)}}
                                >번호변경</button>
                            </div>
                            :
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'100px', fontSize:'20px', fontWeight:500}}>발신번호</div>
                                <div style={{fontSize:'20px', paddingRight:'15px', fontWeight:500}}>등록된 발신 번호가 없습니다.</div>
                                <button style={{marginRight:'20px', padding:'10px 30px', borderRadius:'.2rem', border:'0', cursor:'pointer', backgroundColor: palette.webCyan[5], color: '#FFFFFF', fontSize:'1.1rem'}}
                                    onClick={() => {setSmsNumberModal(true)}}
                                >번호등록</button>
                            </div>
                        }
                    </div>
                    <div style={{padding:'10px 10px', marginBottom:'30px'}}>
                        <div style={{display:'flex'}}>
                            <div style={{width:'100px', fontSize:'20px', fontWeight:500, paddingTop:'10px'}}>문자 분류</div>
                            <div>
                                <RadioGroup
                                    aria-label="condition"
                                    name="stateOption"
                                    onChange={(e)=>{

                                        console.log('e.target.value : ', )
                                        setIsADS(e.target.value == "true"?true:false)
                                    }}
                                    value={isADS}
                                    row={true}
                                >
                                    <FormControlLabel
                                    value={true}
                                    control={<Radio color="primary" />}
                                    label="광고"
                                    />
                                    <FormControlLabel
                                    value={false}
                                    control={<Radio color="primary" />}
                                    label="일반"
                                    />
                                </RadioGroup>
                                {
                                    isADS?
                                    <div style={{display:'flex', backgroundColor:palette.webGray[14], width:'500px', height:'40px', padding:'10px 20px', alignItems:'center'}}>
                                        <div style={{display:'flex'}}><div style={{fontWeight:600}}>광고 문자</div>를 발송전 <div style={{color:'#ff0000', fontWeight:600, paddingLeft:'2px', paddingRight:'2px'}}>{'주의'}</div>해야할 사항이 있습니다.</div>
                                        <div style={{flex:1}}/>
                                        <button style={{padding:'7px 15px', borderRadius:'.2rem', border:'0', cursor:'pointer', backgroundColor: '#FFFFFF'}}
                                            onClick={() => {setAdsInfoModal(true)}}
                                        >보기</button>
                                    </div>
                                    :null
                                }
                            </div>
                        </div>
                    </div>
                    {
                        messageType != "SMS"?
                        <TitleBox>
                            <SubBox>
                                <div className="block" />
                                <Sub>제목</Sub>
                            </SubBox>
                            <TitleInputBox
                                name="title"
                                onChange={(e) => {
                                    const input = e.target.value;
                                    let totalBytes = 0;
                                    let limitedInput = '';
    
                                    for (let i = 0; i < input.length; i++) {
                                      const char = input[i];
                                      const charBytes = calculateBytes(char);
                                
                                      if (totalBytes + charBytes > 30) break;  // 최대 바이트 수 초과 시 입력 차단
                                      totalBytes += charBytes;
                                      limitedInput += char;
                                    }
                                    setTitle(limitedInput)
                                }}
                                value={title}
                                placeholder="알림 제목을 입력해주세요."
                                style={{marginTop: 0}}
                            />
                        </TitleBox>
                        :null
                    }
                    <div style={{display: 'flex'}}>
                    <ContentBox>
                        <SubBox style={{paddingBottom: '7px'}}>
                            <div className="block" />
                            <Sub>내용</Sub>
                        </SubBox>
                        <InputBox
                            name="content"
                            onChange={(e) => {
                                const input = e.target.value;
                                let totalBytes = 0;
                                let limitedInput = '';
                                if(isADS) {
                                    totalBytes += getByteSize(`(광고) ${club && club.name}\n\n${freeNagative}`)
                                }

                                for (let i = 0; i < input.length; i++) {
                                  const char = input[i];
                                  const charBytes = calculateBytes(char);
                            
                                  if (totalBytes + charBytes > 2000) break;  // 최대 바이트 수 초과 시 입력 차단
                                  totalBytes += charBytes;
                                  limitedInput += char;
                                }
                            
                                setContent(limitedInput);
                            }}
                            value={content}
                            placeholder="알림 내용을 입력해주세요."
                        />
                        <div style={{display:'flex', width:'100%'}}>
                            <div style={{flex:1}}></div>
                            <div>({inputNumberAutoComma(textSize)}/{messageType == "SMS"?'90':'2,000'}{'Byte'})</div>
                        </div>
                    </ContentBox>
                    <ContentBox style={{width: '300px', marginLeft: '30px'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                            <SubBox style={{width: '300px'}}>
                                <div className="block" />
                                {/* <Sub>첨부 사진<span> ({image.length} / 3)</span></Sub> */}
                                <Sub>첨부 사진</Sub>
                            </SubBox>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <BtnAddMember 
                                    style={{width: '120px'}}
                                    onClick={() => setMyImageModal(true)
                                }>이미지 보관함</BtnAddMember>
                            </div>
                        </div>
                        <FileDrop
                            onDrop={onDrop}
                            onDragOver={() => setIsDrag(true)}
                            onDragLeave={() => setIsDrag(false)}
                        >
                            <PhotoBox className={isDrag ? "drag" : ""}>
                                <input
                                    onChange={onFileInputChange}
                                    ref={fileInputRef}
                                    type="file"
                                    style={{display: 'none'}}
                                />
                                {image.length > 0 ?
                                <>
                                    <PhotoImgBox>
                                        {image.map((item, idx) => {return (
                                            <PhotoImg key={idx} onClick={() => deleteImage(idx)}>
                                                <div><FaTrash /></div>
                                                {
                                                    item && !item.type?
                                                    <img src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${item}`}/>
                                                    :
                                                    <img src={URL.createObjectURL(item)}/>
                                                }
                                            </PhotoImg>)
                                            })
                                        }
                                    </PhotoImgBox>
                                </>
                                :
                                <div className="svgBox" onClick={() => onTargetClick()}>
                                    <ImFilePicture />
                                </div>
                                }
                            </PhotoBox>
                        </FileDrop>
                    </ContentBox>
                    </div>
                    <ContentBox style={{borderTop: `1px solid ${palette.webGray[5]}`, paddingTop: '30px', marginTop: '10px'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                            <SubBox style={{width: '300px'}}>
                                <div className="block" />
                                <Sub>수신 회원<span> (총 {selectedMembers.length}명)</span></Sub>
                            </SubBox>
                            <BtnAddMember onClick={() => {
                                onLoadMember()
                                openModal()
                            }}>회원 추가</BtnAddMember>
                        </div>
                        <MemberBoxHeader>
                            <div className="name">이름</div>
                            <div className="account">계정</div>
                            <div className="delete"></div>
                        </MemberBoxHeader>
                        <MemberBox>
                            {selectedMembers && selectedMembers.length > 0 ?
                            <AutoSizer>
                                {({ height, width }) => {
                                    return (
                                        <List
                                        rowRenderer={selectedMemberRenderer}
                                        rowCount={selectedMembers.length}
                                        width={width}
                                        height={height}
                                        rowHeight={50}
                                        list={selectedMembers}
                                        overscanRowCount={5}
                                        className="search_list"
                                        />
                                    );
                                }}
                            </AutoSizer>
                            :
                            <MemberNullBox>수신 회원이 없습니다.</MemberNullBox>
                            }
                        </MemberBox>
                    </ContentBox>
                </LeftBox>
                <RightBox>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <button style={{width:'160px', marginRight:'10px', padding:'15px 30px', borderRadius:'.2rem', cursor:'pointer', backgroundColor: '#fff', border: '1px solid rgba(0, 0, 0, .2)', color: '#4d4d4d'}}
                            onClick={() => {resetSendRecord()}}
                        >보낸 문자 기록</button>
                        <button style={{width:'160px', padding:'15px 30px', borderRadius:'.2rem', cursor:'pointer', backgroundColor: '#fff', border: '1px solid rgba(0, 0, 0, .2)', color: '#4d4d4d'}}
                            onClick={() => {resetPaymentResult()}}
                        >충전 내역</button>
                    </div>
                    <PreviewBox draggable={false}>
                        <PreviewTop>
                            <Top1 />
                            <Top2 />
                        </PreviewTop>
                        <div style={{display:'flex', width:'80%'}}>
                            <div style={{flex:1}}></div>
                            <div style={{fontSize:20, fontWeight:600}}>{messageType == "SMS"?"단문 ":(messageType == "LMS"?"장문 ":"그림 ")}({messageType})</div>
                        </div>
                        <Preaview>
                            <PreaveiwContent>
                                <ClubPhotoBox>
                                    <ClubPhoto src={profile}/>
                                </ClubPhotoBox>
                                <MsgBox>
                                    <MsgBoxTop>{club && club.smsNumber}</MsgBoxTop>
                                    <Msg>
                                        <MsgPhoto>
                                            {image.map((item, idx) => {
                                            if(item && !item.type) {
                                                return(<img key={idx} src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${item}`}/>)
                                            } else {
                                                return(<img key={idx} src={URL.createObjectURL(item)}/>)
                                            }
                                            })}
                                        </MsgPhoto>
                                        <MsgContents>
                                            {
                                                isADS?
                                                `(광고) ${club && club.name}\n`
                                                :null
                                            }
                                            {content}
                                            {
                                                isADS?
                                                `\n${freeNagative}`
                                                :null
                                            }
                                        </MsgContents>
                                    </Msg>
                                </MsgBox>
                            </PreaveiwContent>
                        </Preaview>
                        <div style={{display:'flex', width:'80%'}}>
                            <div style={{flex:1}}></div>
                            <div>({inputNumberAutoComma(textSize)}/{messageType == "SMS"?'90':'2,000'}{'Byte'})</div>
                        </div>
                    </PreviewBox>
                    <BtnBox>
                        <div style={{display:'flex', backgroundColor:palette.webGray[15], width:'320px', height:'240px', marginTop:'10px', marginBottom:'10px', padding:'20px 20px 2px 20px', alignItems:'center'}}>
                            <div style={{width:'100%', height:'100%'}}>
                                <div style={{display:"flex", width:'100%'}}>
                                    <div style={{fontSize:'1.2rem', fontWeight:500}}>발송 포인트</div>
                                    <div style={{flex:1}}/>
                                    <div style={{fontSize:'1.2rem', fontWeight:600, color:'#FF0000'}}>{inputNumberAutoComma(price * (selectedMembers?selectedMembers.length:0))}</div>
                                    <div style={{fontSize:'1.2rem', fontWeight:600, paddingLeft:'4px'}}>{'P'}</div>
                                </div>
                                {
                                    selectedMembers && selectedMembers.length > 0 ?
                                    <div style={{display:"flex", width:'100%'}}>
                                        <div style={{flex:1}}/>
                                        <div style={{fontSize:'0.7rem', color:'#646464'}}>({messageType == "SMS"?"단문 ":(messageType == "LMS"?"장문 ":"그림 ")}){inputNumberAutoComma(price)}P X {inputNumberAutoComma(selectedMembers.length)}명</div>
                                    </div>
                                    :
                                    <div style={{display:"flex", width:'100%'}}>
                                        <div style={{flex:1}}/>
                                        <div style={{fontSize:'0.7rem', color:palette.webGray[15]}}>{'.'}</div>
                                    </div>
                                }
                                <div style={{display:"flex", width:'100%', paddingTop:'40px'}}>
                                    <div style={{fontSize:'1.0rem'}}>보유포인트</div>
                                    <div style={{flex:1}}/>
                                    <div style={{fontSize:'1.0rem'}}>{inputNumberAutoComma(myPoint)}</div>
                                    <div style={{fontSize:'1.0rem', paddingLeft:'4px'}}>{'P'}</div>
                                </div>
                                <div style={{display:"flex", width:'100%', paddingTop:'5px'}}>
                                    <div style={{fontSize:'1.0rem'}}>발송 후 포인트</div>
                                    <div style={{flex:1}}/>
                                    <div style={{fontSize:'1.0rem', fontWeight:600, color:(myPoint - (price * (selectedMembers?selectedMembers.length:0))) < 0?'#FF0000':null}}>{(myPoint - (price * (selectedMembers?selectedMembers.length:0))) < 0?'-':''}{inputNumberAutoComma(myPoint - (price * (selectedMembers?selectedMembers.length:0)))}</div>
                                    <div style={{fontSize:'1.0rem', paddingLeft:'4px'}}>{'P'}</div>
                                </div>
                                <div style={{display:"flex", width:'100%', paddingTop:'10px'}}>
                                    <div style={{flex:1}}/>
                                    <button style={{padding:'10px 30px', borderRadius:'.2rem', border:'0', cursor:'pointer', backgroundColor:'#F8BD0E', fontSize:'1.1rem', fontWeight:600}}
                                        onClick={() => {setPaymentModal(true)}}
                                    >충전</button>
                                </div>
                                <div style={{width:'100%', backgroundColor:'#646464', height:'1px', marginTop:'10px', marginBottom:'5px'}}/>
                                <div style={{display:"flex", width:'100%', borderTopColor:'#646464', borderTopWidth:'1px', alignItems:'center', justifyContent:'center'}}>
                                    <div style={{flex:1}}/>
                                    <div style={{fontSize:'1rem', color:'#464646', fontWeight:600, display:'flex'}}>
                                        <div style={{borderWidth:'1px', border:'1px solid #646464', borderRadius:'3px', padding:'3px'}}>단문</div>
                                        <div style={{padding:'5px 3px 0 5px', color:'#FF0000'}}>{smsPrice}<span style={{color:'#000000', fontWeight:400}}>P /</span></div>
                                        <div style={{borderWidth:'1px', border:'1px solid #646464', borderRadius:'3px', padding:'3px', marginLeft:'5px'}}>장문</div>
                                        <div style={{padding:'5px 3px 0 5px', color:'#FF0000'}}>{lmsPrice}<span style={{color:'#000000', fontWeight:400}}>P /</span></div>
                                        <div style={{borderWidth:'1px', border:'1px solid #646464', borderRadius:'3px', padding:'3px', marginLeft:'5px'}}>그림</div>
                                        <div style={{padding:'5px 3px 0 5px', color:'#FF0000'}}>{mmsPrice}<span style={{color:'#000000', fontWeight:400}}>P</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </BtnBox>
                    <BtnBox>
                        <BtnSend onClick={() =>{
                            
                            if((myPoint - (price * (selectedMembers?selectedMembers.length:0))) < 0) {
                                alert("포인트가 부족합니다.")
                                setPaymentModal(true)
                                return;
                            }
                            
                            const now = new Date();
                            const hour = now.getHours();

                            // 오후 9시(21) ~ 다음날 오전 9시(9) 사이인지 검사
                            if((hour >= 21 || hour < 9) && isADS){

                                alert("오후 9시에서 익일 오전 9시 사이에 광고 문자를 보낼 수 없습니다.")
                                return;
                            }
                            if(smsNumber && smsNumber != ""){
                                setConfirmModal(true)
                            }
                            else {
                                alert("발신 번호를 등록해야 합니다.")
                            }

                        }}>문자 전송하기</BtnSend>
                        <Btn onClick={() => setInitModal(true)}>취소</Btn>
                        {/* <Btn onClick={() => setPaymentModal(true)}>충전</Btn> */}
                    </BtnBox>
                </RightBox>
            </MsgContainer>
            {myImageModal &&
                <MyImageModalWrap>
                    <Background />
                    <MyImageBox>
                        <MyImageTitle>이미지 보관함</MyImageTitle>
                        <MyImageContentBox>
                            <SortBox>
                                <li className={templateSize == 1 ? "active" : ""} onClick={() => {if(templateSize != 1) setTemplateSize(1)}}>전체</li>
                                <li className={templateSize == 2 ? "active" : ""} onClick={() => {if(templateSize != 2) setTemplateSize(2)}}><span>720 * 720</span>큰 사이즈</li>
                                <li className={templateSize == 3 ? "active" : ""} onClick={() => {if(templateSize != 3) setTemplateSize(3)}}><span>720 * 360</span>작은 사이즈(배너)</li>
                            </SortBox>
                            <MyImageList>
                                {myImage && myImage.length > 0 ?
                                    myImage.map((image, idx) => {
                                        return(
                                            <MyImageItem key={idx}>
                                                <ImageBox>
                                                    <img src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/template/${image.templateURL}`} 
                                                    onClick={() => {
                                                        uploadMyImage(`template/${image.templateURL}`)
                                                        setTemplateSize(1)
                                                        setMyImageModal(false)
                                                    }}/>
                                                </ImageBox>
                                                <ImageTitle>{image.title}</ImageTitle>
                                                <ImageRegistTime>{timeValueToDate(image.registTime)}</ImageRegistTime>
                                            </MyImageItem>
                                            )
                                    })
                                :
                                <MyImageNullBox>제작한 이미지가 없습니다.</MyImageNullBox>
                                }
                            </MyImageList>
                        </MyImageContentBox>
                        <MyImageBtnBox>
                            <button onClick={() => {
                                setTemplateSize(1)
                                setMyImageModal(false)
                            }}>닫기</button>
                        </MyImageBtnBox>
                    </MyImageBox>
                </MyImageModalWrap>
            }
            <MessageAddMember
                visible={addMemberModal}
                setVisible={setAddMemberModal}
                members={members}
                onSelectMember={onSelectMember}
                selectedMembers={selectedMembers}
                onSelectTempMember={onSelectTempMember}
                selectedTempMembers={selectedTempMembers}
                onSelectTempMemberDelete={onSelectTempMemberDelete}
                closeModal={closeModal}
                onSelectedTempMemberControl={onSelectedTempMemberControl}
                sortType={sortType}
                onMemberTypeChange={onMemberTypeChange}
                expiredMembers={expiredMembers}
                readyMembers={readyMembers}
                availableMembers={availableMembers}
                isNumber
                targetSex={targetSex}
                targetAgeMin={targetAgeMin}
                targetAgeMax={targetAgeMax}
                onMemberSexChange={onMemberSexChange}
                onMemberAgeMinChange={onMemberAgeMinChange}
                onMemberAgeMaxChange={onMemberAgeMaxChange}
            />
            <SmsSendRecord
                visible={sendRecordModal}
                setVisible={setSendRecordModal}
                sentMessageList={recordList}
                onCloseRecordModal={()=>{setSendRecordModal(false)}}
                selectedMessage={selectedMessage}
                onLoadDetailMessage={onLoadDetailMessage}
            />
            <SmsPaymentResultModal
                visible={paymentResultModal}
                closeModal={()=>{
                    setPaymentResultModal(false);
                }}
                paymentResultList={paymentResultList}
            />
            <SmsAdsInfoModal
                visible={adsInfoModal}
                closeModal={()=>{
                    setAdsInfoModal(false);
                }}
            />
            <SmsNumberInfoModal
                visible={smsNumberModal}
                closeModal={()=>{
                    setSmsNumberModal(false);
                }}
            />
            <SmsPaymentModal
                visible={paymentModal}
                closeModal={()=>{
                    setPaymentModal(false);
                }}
                comfirmModal={(price)=>{
                    onPaymentSMS(price)
                    setPaymentModal(false);
                }}
                myPoint={myPoint}
                smsPrice={smsPrice}
                lmsPrice={lmsPrice}
                mmsPrice={mmsPrice}
                priceList={priceList}
            />
            <ConfirmModal
                text={`문자 메세지를 전송하시겠습니까?`}
                onClick={() => {
                    onSaveMessage(title, content, 1);
                    setConfirmModal(false);
                }}
                setModal={() => {setConfirmModal(false)}}
                confirmText = "전송"
                onModal={confirmModal}
            />
            <InitModal
                text={`작성 내용을 초기화 하시겠습니까?`}
                onClick={() => {
                    initPage();
                    setInitModal(false);
                }}
                setModal={() => {setInitModal(false)}}
                confirmText = "확인"
                onModal={initModal}
            />
        </>
    )
};

export default React.memo(SMS);

