import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { AutoSizer, List } from 'react-virtualized';
import Slider from '@material-ui/core/Slider';
import ColorPicker, { useColor } from "react-color-palette";
import { GrFormClose } from 'react-icons/gr';
import { VscTriangleLeft, VscTriangleRight } from 'react-icons/vsc';
import palette from '../../../lib/styles/palette';
import { timeValueToDate, sort, sortReverse } from '../../../lib/commonFunc/commonFunc';
import { selectMember } from '../../../modules/app/notificationMessage';
import { Ring } from 'react-awesome-spinners';
import { MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md';
import DeleteModal from '../../common/ConfirmModal'

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const SentRecordModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 780px) {
    width: 100%;
    height: 100%;
  }
`;

const SentRecordBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;
  

  @media screen and (max-width: 780px) {
      position: absolute;
      top: 180px;
  }
`;

const SentRecordBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px 30px 20px 30px;
`;

const BtnSentRecordDelete = styled.button`
  width: 120px;
  padding: 5px 0;
  background-color: #fff;
  color: #4d4d4d;
  font-size: 1rem;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .2rem;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const BtnSentRecordConfirm = styled.button`
  width: 120px;
  padding: 5px 0;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-right: 20px;

  &:focus {
    outline: none;
  }
`;

const BtnSentRecordClose = styled.button`
width: 120px;
padding: 5px 0;
background-color: ${palette.webGray[5]};
color: #fff;
font-size: 1rem;
border: 0;
border-radius: .2rem;
cursor: pointer;

&:focus {
  outline: none;
}
`;

const SentRecordTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const SentRecordContentBox = styled.div`
  width: 100%;
  height: auto;
  padding: 25px 30px 20px 30px;
  display: flex;
  justify-content: space-between;
`;

const LeftBox = styled.div`
    height: auto;
    margin-right: 50px;
`;

const RightBox = styled.div`
    height: auto;
`;

const TitleBox = styled.div`
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`;

const Title = styled.div`
    font-size: 1.1rem;
    font-weight: bold;
`;

const ModalContentBox = styled.div`
  width: 700px;
  height: 400px;
  padding: 20px 20px;
  border-radius: .3rem;
  border: 1px solid ${palette.webGray[5]};
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
      width: 10px;
    }
  &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;

const TempMsgList = styled.ul`
  width: 300px;
  height: 400px;
  border-radius: .3rem;
  border: 1px solid ${palette.webGray[5]};
  background-color: #fff;
  overflow: hidden;

  & :focus {
    outline: none;
  }

  & ::-webkit-scrollbar {
      width: 10px;
    }
  & ::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  & ::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;

const TempMsgListItem = styled.li`
  width: 100%;
  background-color: #fff;
  padding: 12px 5px;
  cursor: pointer;

  &.active {
    background-color: ${palette.webGray[17]};

    &:hover {
      background-color: ${palette.webGray[17]};
    }
  }

  &:hover {
    background-color: ${palette.webGray[20]};
  }
`;

const ItemTitleBox = styled.div`
  display: flex;

  align-items: center;
  padding: 0 0 10px 0;

  & .mark {
    width: 12px;
    height: 12px;
    margin: 0 10px;
    border-radius: 100%;
    background-color: ${palette.webGray[5]};

    &.active {
      background-color: ${palette.webCyan[5]};
    }
  }

`;

const ItemTitle = styled.div`
  flex: 1;
  text-align: left;
  overflow: hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
  font-size: 1rem;
  font-weight: bold;
`;

const ItemDate = styled.div`
  text-align: left;
  width: 100%;
  font-size: .85rem;
  margin-left: 32px;
  color: ${palette.webGray[10]};
`;
const ContentTitleBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

const LinkBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

const SubBox = styled.div`
    display: flex;
    width: 100px;

    & .block {
        padding: 0;
        height: 20px;
        width: 8px;
        border-radius: .1rem;
        background-color: ${palette.webCyan[5]};
        margin-right: 10px;
    }
`;

const Sub = styled.div`
    font-size: 1rem;
    font-weight: bold;
`;

const TitleInputBox = styled.input`
    width: 100%;
    font-size: 1rem;
    padding: 5px 10px;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[16]};

    &:focus {
        outline: none;
    }
`;

const InputBox = styled.textarea`
    width: 100%;
    font-size: 1rem;
    height: 80px;
    padding: 5px 10px;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[16]};
    margin-top: 20px;
    resize: none;

    &:focus {
        outline: none;
    }
`;

const PhotoImgBox = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: auto;
    display: flex;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }
`;

const PhotoBox = styled.div`
    width: 100%;
    font-size: 1rem;
    height: 130px;
    padding: 5px 0;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[16]};
    background-color: #F0F0F0;
    margin-top: 10px;
    resize: none;

    & div.svgBox {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

    }
    & svg {
        font-size: 2rem;
        color: ${palette.webGray[5]};
    }

    &:focus {
        outline: none;
    }

    &.drag {
        opacity: .7;
    }
`;

const PhotoImg = styled.div`
    position: relative;
    min-width: 200px; 
    width: 32%;
    margin: 0 1%;
    height: 100%;
    border-radius: .3rem;
    overflow: hidden;

    & div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        opacity: 0;
        z-index: 2;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        & svg {
            font-size: 3rem;
            color: #000;
            cursor: pointer;
        }
    }

    & img {
        width: 100%;
        height: 100%;
        background-color: ${palette.webGray[5]};
        object-fit: cover;
    }
`;

const ContentBox = styled.div`
    width: 100%;
    margin-bottom: 20px;
`;

const BtnAddMember = styled.div`
    width: 100px;
    text-align: center;
    padding: 4px 0;
    border: 0;
    border-radius: .2rem;
    background-color: ${palette.webCyan[5]};
    color: #fff;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

const MemberBox = styled.div`
    width: 100%;
    height: 150px;
    border: 1px solid ${palette.webGray[5]};
    border-bottom-left-radius: .2rem;
    border-bottom-right-radius: .2rem;
    border-top: 0;
    overflow: hidden;
    background-color: #fff;
    
    & :focus {
        outline: none;
    }

    & ::-webkit-scrollbar {
      width: 10px;
    }
    & ::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    & ::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }
`;

const MemberBoxHeader = styled.div`
    display: flex;
    align-items:center;
    width: 100%;
    height: 40px;
    background-color: ${palette.webGray[15]};
    font-size: .95rem;
    font-weight: bold;
    border: 1px solid ${palette.webGray[5]};
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem;
    margin-top: 10px;

    & .name {
        width: 30%;
        text-align: center;
    }
    & .account {
        width: 70%;
        text-align: center;
    }
`;

const MemberListItem = styled.ul`
    width: 100%;
    display: flex;
    align-items: center;

    border-bottom: 1px solid ${palette.webGray[15]};

    & li {
        text-align: center;
        font-size: .95rem;

        &.name {
            width: 30%;
        }
        &.account {
            width: 70%;
        }
    }
`;

const MemberNullBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: .95rem;
    color: ${palette.webGray[6]};
`;

const LoadingBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const HistoryNullBox = styled.div`
  width: 1050px;
  height: 430px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: ${palette.webGray[11]};
`;

const AddMemberBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 10px 30px 20px 30px;
`;


const BtnAddMemberClose = styled.button`
width: 120px;
  padding: 10px 10px 10px 10px;
background-color: ${palette.webGray[5]};
color: #fff;
font-size: 1.2rem;
border: 0;
border-radius: .2rem;
cursor: pointer;

&:focus {
  outline: none;
}
`;

const SmsSendRecord = ({
    visible, 
    setVisible,
    sentMessageList,
    onloadData,
    loading,
    onCloseRecordModal,
    selectedMessage,
    onLoadDetailMessage
}) => {
    const messageRenderer = useCallback(
      ({ index, key, style }) => {
        const message = sentMessageList[index];
        return (
          <TempMsgListItem key={index} style={style} className={selectedMessage && selectedMessage.clubSmsID == message.clubSmsID ? "active" : ""} onClick={() => onLoadDetailMessage(message)}>
            <ItemTitleBox>
              <div className={selectedMessage && selectedMessage.clubSmsID == message.clubSmsID ? "mark active" : "mark"} />
              <ItemTitle>[{message.messageType}]{message.title}</ItemTitle>
            </ItemTitleBox>
            <ItemDate>{timeValueToDate(message.registTime)}</ItemDate>
          </TempMsgListItem>
        );
      },
      [sentMessageList, selectedMessage],
    );

    console.log('selectedMessage : ', selectedMessage)

    // const memberRenderer = useCallback(
    //   ({ index, key, style }) => {
    //     const member = selectedMessage.memberDataList[index];
    //     return (
    //       <MemberListItem
    //         style={style}
    //         key={index}
    //       >
    //           <li className="name">{member.name}</li>
    //           <li className="account">{member.account}</li>
    //       </MemberListItem>
    //     );
    //   },
    //   [selectedMessage],
    // );

    if(!visible) return false;
    if(!sentMessageList || sentMessageList.length == 0) {
      return(
        <SentRecordModalWrap>
          <Background onClick={() => {
            console.log("함수")
            setVisible(false)
          }}/>
          <SentRecordBox>
              <SentRecordTitle>임시 저장함</SentRecordTitle>
              <SentRecordContentBox>
                <HistoryNullBox>
                  {loading ? <Ring/> : "기록이 없습니다."}
                </HistoryNullBox>
              </SentRecordContentBox>
              <SentRecordBtnBox style={{justifyContent: 'flex-end'}}>
                  <BtnSentRecordClose onClick={() => {
                    console.log("함수")
                    setVisible(false)
                    onCloseRecordModal()
                  }}>닫기</BtnSentRecordClose>
              </SentRecordBtnBox>
          </SentRecordBox>
        </SentRecordModalWrap>
      )
    };

    return(
      <>
      <SentRecordModalWrap>
          <Background onClick={() => {
            console.log("함수")
            setVisible(false)
          }} />
        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100vw', height:'100vh'}}>
          <SentRecordBox>
              <SentRecordTitle>보낸 알림 기록</SentRecordTitle>
              <SentRecordContentBox>
                <LeftBox>
                    <TitleBox>
                      <Title>보낸 알림 목록</Title>
                    </TitleBox>
                    <TempMsgList>
                        <AutoSizer>
                            {({ height, width }) => {
                            return (
                                <List
                                rowRenderer={messageRenderer}
                                rowCount={sentMessageList.length}
                                width={width}
                                height={height}
                                rowHeight={70}
                                list={sentMessageList}
                                overscanRowCount={5}
                                className="search_list"
                                />
                            );
                            }}
                        </AutoSizer>
                    </TempMsgList>
                </LeftBox>
                <RightBox>
                  { selectedMessage ?
                  <>
                    <TitleBox>
                        <Title>내용</Title>
                    </TitleBox>
                    <ModalContentBox>
                    <ContentTitleBox>
                        <SubBox>
                            <div className="block" />
                            <Sub>제목</Sub>
                        </SubBox>
                        <TitleInputBox name="title" value={selectedMessage.title} readOnly/>
                    </ContentTitleBox>
                    <ContentBox>
                        <SubBox>
                            <div className="block" />
                            <Sub>내용</Sub>
                        </SubBox>
                        <InputBox name="content" value={selectedMessage.text} readOnly/>
                    </ContentBox>
                    <ContentBox>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                            <SubBox>
                                <div className="block" />
                                <Sub>첨부 사진</Sub>
                            </SubBox>
                        </div>
                            <PhotoBox>
                                <PhotoImgBox>
                                    {selectedMessage.photoURL &&
                                        <PhotoImg>
                                            <img src={selectedMessage.photoURL}/>
                                        </PhotoImg>
                                    }
                                </PhotoImgBox>
                            </PhotoBox>
                    </ContentBox>
                    <ContentBox>
                        <SubBox>
                            <div className="block" />
                            <Sub>사용 포인트</Sub>
                        </SubBox>
                        <div style={{display:'flex', alignItems:'flex-start', justifyContent:'flex-start', padding:"10px"}}>{selectedMessage.price}P</div>
                    </ContentBox>
                    <ContentBox>
                        <SubBox>
                            <div className="block" />
                            <Sub>보낸 인원</Sub>
                        </SubBox>
                        <div style={{display:'flex', alignItems:'flex-start', justifyContent:'flex-start', padding:"10px"}}>{selectedMessage.count}명</div>
                    </ContentBox>
                    </ModalContentBox>
                  </>
                  :
                  <LoadingBox style={{width: '700px', height: '430px'}}>없습니다.</LoadingBox>
                }
                </RightBox>
              </SentRecordContentBox>
              <SentRecordBtnBox style={{justifyContent: 'flex-end'}}>
                  <BtnSentRecordClose onClick={() => {
                    console.log("함수")
                    setVisible(false)
                    onCloseRecordModal()
                  }}>닫기</BtnSentRecordClose>
              </SentRecordBtnBox>
          </SentRecordBox>
        </div>
      </SentRecordModalWrap>
      </>
    )
};

export default SmsSendRecord;
