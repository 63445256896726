import React, { useState } from 'react';

import styled, { css } from 'styled-components';
//import MemberTable from '../common/MemberTable';
import { Ring } from 'react-awesome-spinners';
import CommenButton from '../common/Button';
import { selectMainMembershipState, selectMainMembership, timeValueToDate, sort, sortReverse, inputNumberAutoComma } from '../../lib/commonFunc/commonFunc'
import Select from 'react-select';
import palette from '../../lib/styles/palette';
import Pagination from '@material-ui/lab/Pagination';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';


const Part = styled.div`
    width: 100%;
    padding: 30px 20px 60px 20px;
    margin-bottom: 60px;

    &:nth-last-child(1) {
        border-bottom: 0;
    }
`;

const InfoBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    & .ql-editor {
        min-height: 500px;
    }

    &:nth-last-child(1) {
        margin-bottom: 0;
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

const InfoSub = styled.div`
    width: 190px;
    font-size: 1rem;
    font-weight: bold;
`;

const SubBox = styled.div`
    display: flex;
    width: 100%;
    font-size: 1.1rem;
    background-color: #F1F3F5;
    padding: 12px 14px;
    border-radius: .2rem;

    & .block {
        padding: 0;
        height: 20px;
        width: 8px;
        border-radius: .1rem;
        background-color: ${palette.webCyan[5]};
        margin-right: 10px;
    }
`;
const InputBox = styled.input`
    flex: 1;
    font-size: 1rem;
    padding: 8px 12px;
    border-radius: .2rem;
    border: 1px solid #CCCCCC;

    &:focus {
        outline: none;
    }
`;


const AdminClubCoupon = ({
    clubList,
    setSelectClub,
    selectClubInfo,
    setCouponFlag,
    setCouponPeriod,
    setCouponRepeatCount,
    setCouponEditCount,
    setCouponAdd,
    setChangeInfo,
}) => {
    const onChangeCouponFlag = (e)=>{
        setCouponFlag(parseInt(e.target.value))
    }
  return (
    <div style={{}}>
        <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px'}}>
            <div style={{width: '300px'}}>클럽 선택</div>
            <div style={{flex: 1}}>
                <Select
                    options={clubList}
                    onChange={(e)=>{
                        setSelectClub(e.value)
                    }}
                    defaultValue={0}
                    isSearchable={false}
                    //style={{width:'250px'}}
                    styles={{ 
                        container: provided => ({...provided, width: '400px', padding: 0, border: 0})
                    }}
                    height="450px"
                    aria-labelledby="select"
                    placeholder="클럽 선택"
                    maxMenuHeight={'450px'}
                />
            </div>
        </div>
        {
            selectClubInfo?
            <Part style={{marginTop:50}}>
                <InfoBox>
                    <InfoSub>활성화 유무</InfoSub>
                    <SubBox style={{ flex: 1, alignItems: 'center', backgroundColor: 'transparent' }}>
                        <RadioGroup
                            aria-label="type"
                            name="viewType"
                            value={selectClubInfo.couponFlag}
                            onChange={onChangeCouponFlag}
                            row={true}
                        >
                            <FormControlLabel
                                value={1}
                                control={<Radio color="primary" />}
                                label="활성화"
                            />
                            <FormControlLabel
                                value={0}
                                control={<Radio color="primary" />}
                                label="비활성화"
                            />
                        </RadioGroup>
                    </SubBox>
                </InfoBox>
                {
                    selectClubInfo.couponFlag == 1?
                    <>
                    <div style={{display:'flex', marginTop:'15px', fontSize:'1rem', fontWeight:'600px', margin:'10px'}}>! 큐노스가 클럽에게 지급한 [<div style={{color:'#FF0000'}}>미개봉 쿠폰</div>]{'은 매월 말일에 '}<div style={{paddingLeft:'2px', color:'#FF0000'}}>{' 자동으로 소멸'}</div>{'되고 매월 1일에 '}<div style={{paddingLeft:'2px', color:'#FF0000'}}>{' 자동으로 충전'}</div>된다.</div>
                        <InfoBox>
                            <InfoSub>쿠폰 유효기간 </InfoSub>
                            <SubBox style={{ flex:1, display:'flex', flexDirection:'column', backgroundColor: 'transparent' }}>
                                <div style={{flex:1, display:'flex', alignItems: 'center', flexDirection:'row'}}>
                                    <div style={{width:'200px', padding:'0 10px 0 0'}}>(회원이 수령한 이후)</div>
                                    <div style={{width:'100px'}}>
                                        <input
                                            value={selectClubInfo.couponPeriod}
                                            onChange={(e) => setCouponPeriod(e.target.value)}
                                            style={{width: '100px', border: '1px solid #ccc', borderRadius: '.2rem', padding: '12px 16px', fontSize: '.95rem'}} type="text"
                                        />
                                    </div>
                                    <div style={{padding:'0 0 0 10px'}}>달</div>
                                </div>
                            </SubBox>
                        </InfoBox>
                        <InfoBox>
                            <InfoSub>자동 충전 갯수</InfoSub>
                            <SubBox style={{ flex:1, display:'flex', backgroundColor: 'transparent' }}>
                                <div style={{flex:1, display:'flex', alignItems: 'center', flexDirection:'row'}}>
                                    <div style={{width:'100px'}}>
                                        <input
                                            value={selectClubInfo.couponRepeatCount}
                                            onChange={(e) => setCouponRepeatCount(e.target.value)}
                                            style={{width: '100px', border: '1px solid #ccc', borderRadius: '.2rem', padding: '12px 16px', fontSize: '.95rem'}} type="text"
                                        />
                                    </div>
                                    <div style={{padding:'0 20px 0 10px'}}>개</div><div>(큐노스가 클럽에게)</div>
                                </div>
                            </SubBox>
                        </InfoBox>
                        <InfoBox>
                            <InfoSub>쿠폰 즉시 발급</InfoSub>
                            <SubBox style={{ flex:1, display:'flex', alignItems:'center', backgroundColor: 'transparent' }}>
                                <div style={{width:'100px'}}>
                                    <input
                                        value={selectClubInfo.couponEditCount}
                                        onChange={(e) => setCouponEditCount(e.target.value)}
                                        style={{width: '100px', border: '1px solid #ccc', borderRadius: '.2rem', padding: '12px 16px', fontSize: '.95rem'}} type="text"
                                    />
                                </div>
                                <button
                                    onClick={() => setCouponAdd()}
                                    style={{backgroundColor: selectClubInfo.couponEditCount > 0?palette.webCyan[5]:'#fff', color:selectClubInfo.couponEditCount > 0?'#fff':'#E1E1E1', border: '1px solid rgba(0, 0, 0, .2)', borderRadius: '.2rem', padding: '4px 16px', cursor: 'pointer', margin:'0 0 0 10px'}}
                                >
                                    즉시 발급
                                </button>
                                <div style={{padding:'0 0 0 20px'}}>(큐노스가 클럽에게)</div>
                            </SubBox>
                        </InfoBox>
                        <InfoBox>
                            <InfoSub>미사용 쿠폰</InfoSub>
                            <SubBox style={{ flex: 1, alignItems: 'center', backgroundColor: 'transparent' }}>
                                <div style={{width:'150px', padding:'0 10px 0 0'}}>{selectClubInfo.couponCount}장</div>
                            </SubBox>
                        </InfoBox>
                    </>
                    :null
                }
                <div style={{width: '100%', display: 'flex', justifyContent: 'end', marginBottom: '15px'}}>
                    <button onClick={() => setChangeInfo()} style={{backgroundColor: selectClubInfo.isEdit?palette.webCyan[5]:'#fff', color:selectClubInfo.isEdit?'#fff':'#E1E1E1', border: '1px solid rgba(0, 0, 0, .2)', borderRadius: '.2rem', padding: '10px 20px', cursor: 'pointer', fontSize: '1.2rem'}}>
                        수정하기
                    </button>
                </div>
                {/* // <div style={{width: '100%', display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
                //     <div style={{width: '200px'}}>{'미사용 쿠폰 수'}</div>
                //     <div style={{width: '50px'}}>{selectClubInfo.couponCount}장</div>
                //     <div style={{width: '100px', marginLeft:20, marginRight:20}}><input value={selectClubInfo.couponEditCount} onChange={(e) => setCouponEditCount(e.target.value)} style={{width: '100px', border: '1px solid #ccc', borderRadius: '.2rem', padding: '12px 16px', fontSize: '.95rem'}} type="text"/></div>
                //     <button onClick={() => setCouponAdd()} style={{backgroundColor: selectClubInfo.couponEditCount > 0?palette.webCyan[5]:'#fff', color:selectClubInfo.couponEditCount > 0?'#fff':'#E1E1E1', border: '1px solid rgba(0, 0, 0, .2)', borderRadius: '.2rem', padding: '4px 16px', cursor: 'pointer'}}>
                //         쿠폰 추가
                //     </button>
                // </div> */}
            </Part>
            :null
        }
    </div>
  );
};

export default React.memo(AdminClubCoupon);
