import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import CustomDatePicker from '../common/CustomDatePicker';
import Button from '../common/Button';
import {BiUser, BiMessageAltDetail, BiMobileAlt, BiImageAlt, BiFileBlank, BiDockTop, BiMessage} from 'react-icons/bi';
import palette from '../../lib/styles/palette';
import TableScrollbar from 'react-table-scrollbar';

const NavBar = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
`

const NavWrap = styled.ul`
    display: flex;
    align-items: center;
    justify-content: center;

    & li {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 3px solid #C4C4C4;
        padding: 14px;
        width: 220px;
        color: #C4C4C4;
        cursor: pointer;

        & svg {
            font-size: 1.3rem;
            margin-right: 10px;
        }

        &.active {
            color: ${palette.webCyan[5]};
            font-weight: bold;
            border-bottom: 3px solid ${palette.webCyan[5]};
        }
    }
`;

const AppNavBar = ({page}) => {

    let history = useHistory();
    
    const movePage = (page) => {
      history.push(`/mobile/${page}`);
    }

    return(
        <NavBar>
            <NavWrap>
                <li onClick={() => {
                    if(page!="sms") movePage("sms")
                }} className={page=="sms" ? "active" : ""}><BiMessage />문자</li>
                <li onClick={() => {
                    if(page!="message") movePage("message")
                }} className={page=="message" ? "active" : ""}><BiMobileAlt />앱 푸쉬 알림</li>
                <li onClick={() => {
                    if(page!="banner") movePage("banner")
                }} className={page=="banner" ? "active" : ""}><BiDockTop />배너</li>
                <li onClick={() => {
                    if(page!="coachProfile") movePage("coachProfile")
                }} className={page=="coachProfile" ? "active" : ""}><BiUser />강사 소개</li>
            </NavWrap>
            <NavWrap>
                <li onClick={() => {
                    if(page!="landing") movePage("landing")
                }} className={page=="landing" ? "active" : ""}><BiFileBlank />페이지 제작</li>
                <li onClick={() => {
                    if(page!="imageTemplate") movePage("imageTemplate")
                }} className={page=="imageTemplate" ? "active" : ""}><BiImageAlt />이미지 제작</li>
            </NavWrap>
        </NavBar>
    )
};

export default AppNavBar;
