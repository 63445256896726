import React, { useEffect, useState } from 'react';
import palette from '../../lib/styles/palette';
import styled from 'styled-components';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 999;
`;

const Screen = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const Modal = styled.div`
  width: 40%;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.01);
  background-color: #fff;

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 350px;
  }
`;

const ModalText = styled.div`
  width: 100%;
  height: fit-content;
  padding: 30px;
  height: 70px;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: left;
`;

const ModalBtnBox = styled.div`
  display: flex;
  padding: 30px;
  justify-content: flex-end;
  font-size: 1rem;

  & button {
    cursor: pointer;
    padding: 10px 20px;
    text-align: center;
    border: none;
    font-size: 1.1rem;
    color: #fff;
    border-radius: 3px;
    font-weight: 600;
    background-color: ${palette.webGray[5]};

    &:focus{
      outline: none;
    }     
    
    &.confirm {
      background-color: ${palette.webCyan[5]};
      margin-right: 20px;
    }
  }
`;

const EditInput = styled.input`
  border: 1px solid ${palette.webGray[14]};
  border-radius: .2rem;
  padding: 5px 10px;
  width: 30%;
  margin-top: 8px;
  margin-bottom: 8px;

  &:focus {
    outline: none;
  }
`;

const EditMemberInfoModal = ({
    onConfirm,
    setModal,
    modal,

    sex,
    age,
    name,
    phone,
}) => {
    const [nameText, setNameText] = useState("");
    const [ageText, setAgeText] = useState("");
    const [phoneText, setPhoneText] = useState("");
    const [sexValue, setSexValue] = useState(true);
    
      useEffect(() => {
        if (modal) {
            setNameText(name)
            setAgeText(age)
            setPhoneText(phone)
            setSexValue(sex == 1?true:false)
        }
      }, [modal]);

      const onChangePhone = (text)=>{
          var cleaned = text.replace(/\D/g, '');
          
          const partA = cleaned.substring(0, 3);
          const partB = cleaned.substring(3, 7);
          const partC = cleaned.substring(7, 11);
        
          // 2345678  =>  234-567-8
          if (partC) {
            setPhoneText(`${partA}-${partB}-${partC}`)
            return;
          }
          // 2345 => 234-5
          if (partB) {
            setPhoneText(`${partA}-${partB}`)
              return;
          }
  
          setPhoneText(partA)
      }
    return(
        <>
            { modal ?
                  <Background >
                    <Screen onClick={() => {setModal(false)}} />
                    <Modal>
                        <ModalText>{'회원 정보 수정'}</ModalText>
                        <div style={{paddingTop:10, paddingBottom:10}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'16%', paddingLeft:'35px', textAlign:'start'}}>이름 : </div>
                                <EditInput
                                    type='text'
                                    value={nameText}
                                    onChange={e => setNameText(e.target.value)} 
                                />
                            </div>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'16%', paddingLeft:'35px', textAlign:'start'}}>나이 : </div>
                                <EditInput
                                    type={'number'}
                                    value={ageText}
                                    onChange={e => setAgeText(e.target.value)} 
                                />
                            </div>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'16%', paddingLeft:'35px', textAlign:'start'}}>전화번호 : </div>
                                <EditInput
                                    type='text'
                                    value={phoneText}
                                    onChange={e => onChangePhone(e.target.value)} 
                                />
                            </div>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'16%', paddingLeft:'35px', textAlign:'start'}}>성별 : </div>
                                <RadioGroup
                                    aria-label="condition"
                                    name="stateOption"
                                    onChange={(e)=>{

                                        console.log('e.target.value : ', e.target.value)
                                        setSexValue(e.target.value == "true"?true:false)
                                    }}
                                    value={sexValue}
                                    row={true}
                                >
                                    <FormControlLabel
                                    value={true}
                                    control={<Radio color="primary" />}
                                    label="남"
                                    />
                                    <FormControlLabel
                                    value={false}
                                    control={<Radio color="primary" />}
                                    label="여"
                                    />
                                </RadioGroup>
                            </div>
                        </div>
                        <ModalBtnBox>
                            <button className="confirm" onClick={() => {
                                // check
                                var blank_pattern = /[\s]/g;
                                let errorText = ''
                                
                                if (!nameText) {
                                    //--> 이름 없음
                                    errorText = '이름을 입력해 주세요.'
                                }
                                else if( blank_pattern.test(nameText) == true)
                                {
                                    errorText = '이름에 공백은 사용할 수 없습니다.'
                                }
                                else if (!ageText)
                                {
                                    errorText = '나이를 입력해 주세요.'
                                }
                                else if (!parseInt(ageText))
                                {
                                    errorText = '나이를 입력해 주세요.'
                                }
                                else if (parseInt(ageText) > 150 || parseInt(ageText) <= 0)
                                {
                                    errorText = '나이를 다시 입력해 주세요.'
                                }
                                else if (!phoneText)
                                {
                                    errorText = '전화번호를 입력해 주세요.'
                                }
                                else if (phoneText.length < 12 || phoneText.length > 14)
                                {
                                    errorText = '전화번호를 입력해 주세요.'
                                }

                                if(errorText != '') {
                                    alert(errorText)
                                    return;
                                }
                                
                                const userConfirmed = window.confirm('회원 정보를 수정 하시겠습니까?');
                                if (userConfirmed) {
                                    onConfirm(ageText, nameText, phoneText, sexValue?1:2)
                                }
                            }}>{"수정"}</button>
                            <button onClick={() => {setModal(false)}}>{"취소"}</button>
                        </ModalBtnBox>
                    </Modal>
                  </Background>
                :
                    ""
            }
        </>
    )
}

export default EditMemberInfoModal;