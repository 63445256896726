import React, { useState } from 'react';
import palette from '../../lib/styles/palette';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CommenButton from '../common/Button';
import Select from 'react-select';
import { inputNumberAutoComma } from '../../lib/commonFunc/commonFunc';

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 999;
`;

const Screen = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.35);
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const Modal = styled.div`
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.01);
  background-color: #fff;
  width: 1000px;
`;

const ModalTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;
const ModalContentBox = styled.div`
  width: 100%;
  height: auto;
  padding: 25px 30px 20px 30px;
`;

const BtnBox = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 12px 16px;
  background-color: #F9F9F9;
`;

const TextSpace = styled.div`
  padding: 0 10px;
  font-size: 20pt;
`;

const BtnCancel = styled.button`
  padding: 8px 24px;
  color: #3A3A3C;
  background-color: #fff;
  border-radius: 6px;
  font-size: 16px;
  border: 1px solid #DDE5E9;
  box-sizing: content-box;
  cursor: pointer;
`;

const Table = styled.table`
  font-family: 'Noto Sans KR', sans-serif;
  td {
    padding: 10px;
    border-top: 1px solid ${palette.webGray[15]};
  }

  thead {
    tr {
      border-top: 2px solid ${palette.webGray[7]};
      border-bottom: 2px solid ${palette.webGray[7]};
    }
  }
`;

const downloadExcel = (date, tableName) => {
    const table = document.getElementsByClassName(tableName)[0];
  
    let tab_text = '<html xmlns:x="urn:schemas-microsoft-com:office:excel">';
    tab_text +=
      '<head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">';
    tab_text += '<xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>';
    tab_text += '<x:Name>Test Sheet</x:Name>';
    tab_text +=
      '<x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions></x:ExcelWorksheet>';
    tab_text += '</x:ExcelWorksheets></x:ExcelWorkbook></xml></head><body>';
    tab_text += "<table border='1px'>";
  
    let exportTable = table.cloneNode(true);
  
    tab_text += exportTable.outerHTML;
    tab_text += '</table></body></html>';
    let data_type = 'data:application/vnd.ms-excel';
    let ua = window.navigator.userAgent;
    let msie = ua.indexOf('MSIE ');
    let fileName = date + '_Table' + '.xls';
  
    // browser 처리
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      //ie 용
      if (window.navigator.msSaveBlob) {
        let blob = new Blob([tab_text], {
          type: 'application/csv;charset=utf-8;',
        });
        navigator.msSaveBlob(blob, fileName);
      }
    } else {
      //그외
      let blob2 = new Blob([tab_text], {
        type: 'application/csv;charset=utf-8;',
      });
      let filename = fileName;
      let elem = window.document.createElement('a');
      elem.href = window.URL.createObjectURL(blob2);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
};

const MobileCalculrateModal = ({
  setActive,
  active,
  clubTransferCalculate,
  startTime,
  endTime,
  setStartTime,
  setEndTime,
  onClickPeriod,
  onClickSearch
}) => {
    const changeDateFormat = (timestamp) => {
        const date = new Date(timestamp * 1000);
        const year1 = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        const dateStr = `${year1}-${month}-${day}`;

        return dateStr
    }

    let total_totalPrice = 0
    let total_fees = 0
    let total_transferPrice = 0

    for (let i = 0; i < clubTransferCalculate.length; i++) {
        const element = clubTransferCalculate[i];

        total_totalPrice += clubTransferCalculate[i].totalPrice
        total_fees += clubTransferCalculate[i].fees
        total_transferPrice += clubTransferCalculate[i].transferPrice
    }

    //let _clubTransferCalculate = clubTransferCalculate?clubTransferCalculate.sort((a, b)=>{return b.registTime-a.registTime}):[]
    

    if(!active) {
      return null
    }

    return(
        <>
            <Background>
            <Screen onClick={() => {setActive(false)}} />
            <Modal>
                <ModalTitle>
                    정산 내역 검색
                </ModalTitle>
                <ModalContentBox>
                    <div>
                        <div style={{padding:'12px 12px 30px 30px', display:'flex'}}>
                            <TextField
                                id="startTime"
                                label="시작일"
                                name="startTime"
                                type="date"
                                value={changeDateFormat(startTime)}
                                onChange={(e) =>{
                                    let date = new Date(e.target.value)

                                    date.setHours(0, 0, 0, 0)

                                    setStartTime(parseInt(date.getTime()/1000))
                                }}
                                InputLabelProps={{
                                    shrink: true,
                            }}
                            />
                            <TextSpace>~</TextSpace>
                            <TextField
                                id="endTime"
                                label="종료일"
                                name="endTime"
                                type="date"
                                value={changeDateFormat(endTime)}
                                onChange={(e) =>{
                                    let date = new Date(e.target.value)

                                    date.setHours(23, 59, 59)

                                    setEndTime(parseInt(date.getTime()/1000))
                                }}
                                InputLabelProps={{
                                    shrink: true,
                            }}
                            />
                            <div style={{width:'20px'}}/>
                            <ButtonGroup
                                color="primary"
                                aria-label="outlined primary button group"
                            >
                                <Button onClick={() => onClickPeriod('month')}>1달</Button>
                                <Button onClick={() => onClickPeriod('3months')}>3달</Button>
                                <Button onClick={() => onClickPeriod('6months')}>6달</Button>
                                <Button onClick={() => onClickPeriod('')}>전체</Button>
                            </ButtonGroup>
                            <div style={{width:'50px'}}/>
                            <button onClick={() => onClickSearch()} style={{backgroundColor: '#fff', color: '#000', border: '1px solid', borderRadius: '.2rem', padding: '6px 40px', cursor: 'pointer'}}>
                                검색
                            </button>
                        </div>
                        <div style={{padding: '0 20px', marginBottom: '20px', marginTop: '100px'}}>
                            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px'}}>
                                <div style={{flex:1}}/>
                                <CommenButton
                                    onClick={() => downloadExcel('모바일매출 정산내역', 'transferCalculate')}
                                >{`Excel 다운로드`}</CommenButton>
                            </div>
                            <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '15px', marginTop: '45px'}}>
                                <div style={{width: '240px', display: 'flex', justifyContent:'flex-end'}}>총 카드결제 금액 : {inputNumberAutoComma(total_totalPrice)}</div>
                                <div style={{width: '240px', display: 'flex', justifyContent:'flex-end'}}>총 정산 금액 : {inputNumberAutoComma(total_transferPrice)}</div>
                            </div>
                        </div>
                        <div style={{ height:'440px', maxHeight: '440px', overflowY: 'auto' }}>
                            <Table className="transferCalculate">
                                <thead>
                                    <tr>
                                    <td>판매일</td>
                                    <td>정산일</td>
                                    <td>카드결제 금액</td>
                                    <td>정산 금액</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        clubTransferCalculate && clubTransferCalculate.map((dateData, idx1)=>{
                                            return (
                                            <tr
                                                key={idx1}
                                                style={{ borderTop: `1px solid ${palette.webGray[17]}`}}
                                            >
                                                <td>{dateData.transferDateLangth}</td>
                                                <td>{dateData.registDate}</td>
                                                <td>{inputNumberAutoComma(dateData.totalPrice)}</td>
                                                <td>{inputNumberAutoComma(dateData.transferPrice)}</td>
                                            </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </ModalContentBox>
                <BtnBox>
                    <BtnCancel onClick={() => {setActive(false)}}>{"닫기"}</BtnCancel>
                </BtnBox>
            </Modal>
            </Background>
        </>
    )
}

export default MobileCalculrateModal;