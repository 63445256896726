import { handleActions, createAction } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import * as paymentAPI from '../../lib/api/payment';
import { takeLatest } from 'redux-saga/effects';

/*
  actions
*/

const [
  LOAD_CLUB_PAYMENT,
  LOAD_CLUB_PAYMENT_SUCCESS,
  LOAD_CLUB_PAYMENT_FAILURE,
] = createRequestActionTypes('payment/LOAD_CLUB_PAYMENT');

const [
    LOAD_CLUB_PAYMENT_TIME,
    LOAD_CLUB_PAYMENT_TIME_SUCCESS,
    LOAD_CLUB_PAYMENT_TIME_FAILURE,
  ] = createRequestActionTypes('payment/LOAD_CLUB_PAYMENT_TIME');

const [
  CANCEL_CLUB_PAYMENT,
  CANCEL_CLUB_PAYMENT_SUCCESS,
  CANCEL_CLUB_PAYMENT_FAILURE,
] = createRequestActionTypes('payment/CANCEL_CLUB_PAYMENT');
const [
  LOAD_TRANSFER_PAYMENT,
  LOAD_TRANSFER_PAYMENT_SUCCESS,
  LOAD_TRANSFER_PAYMENT_FAILURE,
] = createRequestActionTypes('payment/LOAD_TRANSFER_PAYMENT');
const [
  LOAD_TRANSFER_CLUB_PAYMENT,
  LOAD_TRANSFER_CLUB_PAYMENT_SUCCESS,
  LOAD_TRANSFER_CLUB_PAYMENT_FAILURE,
] = createRequestActionTypes('payment/LOAD_TRANSFER_CLUB_PAYMENT');
const [
  LOAD_TRANSFER_CALCULATE_PAYMENT,
  LOAD_TRANSFER_CALCULATE_PAYMENT_SUCCESS,
  LOAD_TRANSFER_CALCULATE_PAYMENT_FAILURE,
] = createRequestActionTypes('payment/LOAD_TRANSFER_CALCULATE_PAYMENT');


const INITIALIZE = 'payment/INITIALIZE';

export const loadClubPayment = createAction(
  LOAD_CLUB_PAYMENT,
  ({ socketKey, clubID }) => ({
    socketKey,
    clubID,
  }),
);

export const loadClubPayment_time = createAction(
    LOAD_CLUB_PAYMENT_TIME,
    ({ socketKey, clubID, startTime, endTime }) => ({
      socketKey,
      clubID,
      startTime,
      endTime
    }),
  );

export const cancelPayment = createAction(
    CANCEL_CLUB_PAYMENT,
  ({ paymentData, clubID, socketKey, targetID }) => ({
    paymentData,
    clubID,
    socketKey,
    targetID,
  }),
);
export const loadTranferPayment = createAction(
    LOAD_TRANSFER_PAYMENT,
  ({ socketKey, playerID }) => ({
    socketKey,
    playerID,
  }),
);

export const loadClubTansterCalculate = createAction(
    LOAD_TRANSFER_CALCULATE_PAYMENT,
  ({ socketKey, startTime, endTime, clubID }) => ({
    socketKey,
    startTime,
    endTime,
    clubID,
  }),
);

export const loadTranferClubPayment = createAction(
    LOAD_TRANSFER_CLUB_PAYMENT,
  ({ socketKey, startTime, endTime, clubID }) => ({
    socketKey,
    startTime,
    endTime,
    clubID,
  }),
);

export const init = createAction(INITIALIZE);
export const initPayment = createAction(INITIALIZE);

const loadClubPaymentSaga = createRequestSaga(LOAD_CLUB_PAYMENT, paymentAPI.loadClubPayment);
const loadClubPayment_timeSaga = createRequestSaga(LOAD_CLUB_PAYMENT_TIME, paymentAPI.loadClubPayment_time);

const cancelClubPaymentSaga = createRequestSaga(CANCEL_CLUB_PAYMENT, paymentAPI.cancelClubPayment);
const loadTransferPaymentSaga = createRequestSaga(LOAD_TRANSFER_PAYMENT, paymentAPI.loadTransferPayment);
const loadTransferClubPaymentSaga = createRequestSaga(LOAD_TRANSFER_CLUB_PAYMENT, paymentAPI.loadTransferClubPayment);
const loadClubTansterCalculateSaga = createRequestSaga(LOAD_TRANSFER_CALCULATE_PAYMENT, paymentAPI.loadTransferCalculate);

export function* paymentSaga() {
    yield takeLatest(LOAD_CLUB_PAYMENT, loadClubPaymentSaga);
    yield takeLatest(CANCEL_CLUB_PAYMENT, cancelClubPaymentSaga);
    yield takeLatest(LOAD_TRANSFER_PAYMENT, loadTransferPaymentSaga);
    yield takeLatest(LOAD_TRANSFER_CLUB_PAYMENT, loadTransferClubPaymentSaga);
    yield takeLatest(LOAD_CLUB_PAYMENT_TIME, loadClubPayment_timeSaga);
    yield takeLatest(LOAD_TRANSFER_CALCULATE_PAYMENT, loadClubTansterCalculateSaga);
}

/*
  state
*/

const initialState = {
  error: null,
  clubPaymentList: [],
  paymentCancelResult:null,
  transferLogList:[],
  transferClubLogList:[],
  clubPaymentTimeList: [],
  transferCalculate: [],
};

/*
  handleActions
*/
const payment = handleActions(
  {
    [LOAD_CLUB_PAYMENT_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      clubPaymentList: res.infos,
      error: null,
    }),
    [LOAD_CLUB_PAYMENT_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LOAD_CLUB_PAYMENT_TIME_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      clubPaymentTimeList: res.infos,
      error: null,
    }),
    [LOAD_CLUB_PAYMENT_TIME_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [CANCEL_CLUB_PAYMENT_SUCCESS]: (state, { payload: result }) => ({
      ...state,
      paymentCancelResult: result,
    }),
    [CANCEL_CLUB_PAYMENT_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LOAD_TRANSFER_PAYMENT_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      transferLogList: res.logInfos,
      transferClubLogList: res.clubLogInfos,
      error: null,
    }),
    [LOAD_TRANSFER_PAYMENT_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LOAD_TRANSFER_CLUB_PAYMENT_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      transferLogList: res.logInfos,
      transferClubLogList: res.clubLogInfos,
      error: null,
    }),
    [LOAD_TRANSFER_CLUB_PAYMENT_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LOAD_TRANSFER_CALCULATE_PAYMENT_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      transferCalculate: res,
      error: null,
    }),
    [LOAD_TRANSFER_CALCULATE_PAYMENT_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [INITIALIZE]: () => initialState,
  },
  initialState,
);

export default payment;
