import qs from 'qs';
import client, { url } from './client';

export const loadClubPayment = ({ clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/payment/${clubID}?${queryString}`);
};

export const loadClubPayment_time = ({ clubID, socketKey, startTime, endTime }) => {
  const queryString = qs.stringify({
    socketKey,startTime, endTime
  });
  return client.get(`${url}/api/payment/${clubID}?${queryString}`);
};

export const cancelClubPayment = ({ 
    paymentData,
    clubID,
    socketKey,
    targetID }) => {
    const queryString = qs.stringify({
      socketKey,
    });
  
    return client.post(`${url}/api/payment/cancel/${clubID}?${queryString}`, {
        paymentData,
        targetID,
    });
  };


export const loadTransferPayment = ({ playerID, socketKey }) => {
    const queryString = qs.stringify({
        socketKey,
    });

    return client.get(`${url}/api/payment/transfer/${playerID}?${queryString}`);
};


export const loadTransferClubPayment = ({ clubID, socketKey, startTime, endTime }) => {
    const queryString = qs.stringify({
        socketKey,
        startTime,
        endTime
    });

    return client.get(`${url}/api/payment/transferclub/${clubID}?${queryString}`);
};


export const loadTransferCalculate = ({ startTime, endTime, clubID, socketKey }) => {
    const queryString = qs.stringify({
        socketKey,
        startTime,
        endTime
    });

    return client.get(`${url}/api/payment/transfercalculate/${clubID}?${queryString}`);
};
