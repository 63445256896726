import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../components/common/Header';
import { logout } from '../../modules/user';
import { withRouter } from 'react-router-dom';
import { initializer } from '../../modules/auth/auth';
import {
  changeClub,
  unloadClub,
  initChangeRes,
} from '../../modules/clubs/club';
import {
  initDoorRes,
  openDoor,
} from '../../modules/clubs/door';
import {
  check
} from '../../modules/user.js';
import {
  initUser
} from '../../modules/user';
import { unloadClubs, listClubs, loadAllClubList } from '../../modules/clubs/clubs';
import { loadClubLessonInfo } from '../../modules/clubs/clubLessonInfo';
import { arrayConverter, sort } from '../../lib/commonFunc/commonFunc';
import { initialize } from '../../modules/socket/socket';
import { isConnect } from '../../lib/api/auth';
import { patch } from '../../lib/api/clubs';
import { 
  loadNotice,
  loadNewNotice,
  changeNoticeState,
  initRes,
  deleteNotice,
  filterDeleteNotice,
} from '../../modules/app/notificationMessage'

const HeaderContainer = ({ history }) => {
  const { user, clubs, noticeLoading, club, openDoorResult, noticeDeleteRes, newNoticeList, noticeList, noticeCheckRes, socketKey, auth, changeClubRes } = useSelector(
    ({ user, clubs, coachProfile, loading, club, auth, notificationMessage, door, socket }) => ({
      user: user.user,
      clubs: clubs.clubs,
      club: club.defaultClub,
      changeClubRes: club.changeClubRes,
      socketKey: socket.socketKey,
      noticeList: notificationMessage.noticeList,
      newNoticeList: notificationMessage.newNoticeList,
      auth: auth.auth,
      noticeCheckRes: notificationMessage.noticeCheckRes,
      noticeLoading: loading['notificationMessage/LOAD_NOTICE'],
      noticeDeleteLoading: loading['notificationMessage/DELETE_NOTICE'],
      noticeDeleteRes: notificationMessage.noticeDeleteRes,
      openDoorResult: door.openDoorResult,
    }),
  );

  const [deleteNoticeList, setDeleteNoticeList] = useState([])
  const [noticeDeleteMode, setNoticeDeleteMode] = useState(false);
  const [isAllDelete, setIsAllDelete] = useState(false);

  const dispatch = useDispatch();

  const onOpenDoor = (doorID) => {
    dispatch(openDoor({
      clubID: club.clubID,
      doorID,
      playerID: user.playerID,
      socketKey
    }))
  }

  const onLoadNotice = () => {
    dispatch(loadNotice({
      clubID: club.clubID,
      socketKey
    }))
  }

  const onLogout = useCallback(() => {
    if(user && (user.memberType === 1 || user.memberType === 2)) {
      history.push('/')
    }
    localStorage.removeItem("clubs")
    dispatch(logout(socketKey));
    dispatch(initializer());
    dispatch(unloadClub());
    dispatch(initUser());
    dispatch(unloadClubs());
    dispatch(initialize());
  }, [dispatch, socketKey]);

  const onDeleteNotice = () => {
    if(deleteNoticeList && deleteNoticeList.length > 0) {
      dispatch(deleteNotice({
        playerID: user.playerID,
        messageIDs: deleteNoticeList,
        socketKey
      }))
    }
  }

  const onSelect = useCallback(
    (club) => {
      if (club.clubID) {
        dispatch(changeClub({ clubID: club.clubID, socketKey }));
        history.push(`/member`);
      }
    },
    [dispatch, history, socketKey],
  );

  const onInitDoorRes = () => {
    dispatch(initDoorRes())
  }

  const onClickNotification = () => {
    history.push(`/lesson`);
  }

  const patchTest = async () => {
    const res = await patch({ clubID: club.clubID, socketKey });
  }

  useEffect(() => {
    if (user) {
      checkConnecting();
    }
  }, [history, user]);

  const checkConnecting = async () => {
    const res = await isConnect({ socketKey });
    if (!res || res.data === false || res.data === 'invalid') {
      alert('서버와의 연결이 끊겼습니다. 다시 로그인해 주세요');
      onLogout();
    }
  };

  useEffect(() => {
    if (!user) {
      let tempUser = JSON.parse(localStorage.getItem("user"))
      if(!tempUser || tempUser.memberType != 1) {
        console.log('aaa login : ', tempUser)
        history.push('/login');
      }
    }
  }, [history, user, dispatch]);

  useEffect(() => {
    if (!club) {
      let tempUser = JSON.parse(localStorage.getItem("user"))
      if(!tempUser || tempUser.memberType != 1) {
        console.log('bbb login : ', tempUser)
        history.push('/login');
      }
    } else {
      dispatch(loadNewNotice({
        clubID: club.clubID,
        socketKey
      }))
    }
  }, [club, history]);
  useEffect(() => {
    if (auth) dispatch(listClubs(clubs));
  }, [auth, dispatch]);

  useEffect(() => {
    if (club && clubs) {
      // dispatch(loadClubLessonInfo({clubID: club.clubID, socketKey}))
      try {
        localStorage.setItem('clubs', JSON.stringify(clubs));
        localStorage.setItem('club', JSON.stringify(club));
      } catch (error) {
        console.log('localStorage is not working');
      }
    }
  }, [club, clubs]);

  useEffect(() => {
    if (changeClubRes && changeClubRes.result === 1) {
      history.push('/member');
      dispatch(initChangeRes());
    }
  }, [changeClubRes]);

  useEffect(() => {
    if(noticeCheckRes && noticeCheckRes === 1) {
      dispatch(loadNewNotice({
        clubID: club.clubID,
        socketKey
      }))
      dispatch(initRes())
    }
  }, [noticeCheckRes])

  useEffect(() => {
    if(noticeList && noticeList.length > 0) {
      if(noticeList.filter(item => {return item.clubCheck === 0}).length > 0) {
        dispatch(changeNoticeState({
          clubID: club.clubID,
          time: Math.ceil(new Date().getTime() / 1000),
          socketKey
        }))
      }
    }
  }, [noticeList])

  useEffect(() => {
    if(user && user.playerID) {
      if(user.memberType === 1 || user.memberType === 2) {
        dispatch(
          loadAllClubList({
            playerID: Number(user.playerID),
            socketKey,
          }),
        );
      }
    }
  }, [user])

  useEffect(() => {
    if(noticeDeleteRes && noticeDeleteRes === 1) {
      dispatch(filterDeleteNotice({deleteNoticeList}))
      dispatch(initRes())
      setIsAllDelete(false)
      setNoticeDeleteMode(false)
      setDeleteNoticeList([])
    }
  }, [noticeDeleteRes])

  return (
    <>
      <Header
        user={user}
        onLogout={onLogout}
        myClubs={arrayConverter(clubs)}
        onSelect={onSelect}
        defaultClub={club}
        onClickNotification={onClickNotification}
        noticeList={sort(noticeList, "registTime")}
        newNoticeList={newNoticeList}
        onLoadNotice={onLoadNotice}
        noticeLoading={noticeLoading}
        deleteNoticeList={deleteNoticeList}
        setDeleteNoticeList={setDeleteNoticeList}
        onDeleteNotice={onDeleteNotice}
        noticeDeleteMode={noticeDeleteMode}
        setNoticeDeleteMode={setNoticeDeleteMode}
        isAllDelete={isAllDelete}
        setIsAllDelete={setIsAllDelete}
        onOpenDoor={onOpenDoor}
        openDoorResult={openDoorResult}
        onInitDoorRes={onInitDoorRes}
        patchTest={patchTest}
      />
    </>
  );
};

export default withRouter(HeaderContainer);
