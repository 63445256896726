import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    setSelectLocker,
    loadClubUserLocker,
    removeClubUserLocker,
    changeSelectLocker,
    modifyClubUserLocker,
    loadClubLocker,
    resetClubUserLockerRes,
    loadOtherClubUserLocker,
    setSelectLockerEndTime,
    changeSelectLockerTerm,
    moveClubUserLocker,
    initializeRes,
    initalizeLocker,
} from '../../modules/member/locker';
import {
    readPointUserTemp
} from '../../modules/member/point';
import qs from 'qs';
import moment from 'moment';
import { inputNumberRemoveComma } from '../../lib/commonFunc/commonFunc';
import LockerInfoTable from '../../components/locker/LockerInfoTable';

const LockerInfoListForm = ({ location , setSubModal}) => {
  const { lockerSN, lockerID, name } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const dispatch = useDispatch();

  const {
    club,
    socketKey,
    clubuserlocker,
    modifyClubUserlockerRes,
    selectLocker,
    selectLockerTerm,
    otherClubuserlocker,
    pointUser,
    loading,
    lockers,
    moveClubUserlockerRes,
    deleteRes,
  } = useSelector(({ loading, locker, pointUser, club, socket }) => ({
    club: club.defaultClub,
    socketKey: socket.socketKey,
    loading: loading['locker/LOAD_CLUBLOCKER'],
    lockers: locker.club,
    clubuserlocker: locker.clubuserlocker,
    otherClubuserlocker: locker.otherClubuserlocker,
    modifyClubUserlockerRes: locker.modifyClubUserlockerRes,
    moveClubUserlockerRes: locker.moveClubUserlockerRes,
    selectLocker: locker.selectItem,
    selectLockerTerm: locker.selectItemTerm,
    pointUser: pointUser.userPointTemp,
    deleteRes: locker.deleteRes,
  }));
  
  const [lockerMoveModal, setLockerMoveModal] = useState(false);
  const [lockerMoveConfirm, setLockerMoveConfirm] = useState(false)
  const [lockerEditConfirm, setLockerEditConfirm] = useState(false)
  const [lockerMoveSecondConfirm, setLockerMoveSecondConfirm] = useState(false)
  const [selectMoveLocker, setSelectMoveLocker] = useState(null);
  const [modals, setModals] = useState([
    {
      id: 'modal',
      visible: false,
    },
    {
      id: 'modify',
      visible: false,
    },
    {
      id: 'remove',
      visible: false,
    },
    {
      id: 'info',
      visible: false,
    }
  ]);
  const [openMore, setOpenMore] = useState(-1);

  const onMouseOver = (locker) => {};

  const onClickMoveModalCell = (locker) => {
    if(locker.lockerID == selectLocker.lockerID) {
      alert("현재 사용중인 락커입니다.")
      return false;
    }
    setSelectMoveLocker(locker);
    dispatch(
      loadOtherClubUserLocker({
          clubID: club.clubID,
          lockerID: locker.lockerID,
          socketKey,
      }),
  );
  }
  const viewOpenMore = (idx) => {
    if (idx === openMore) {
      setOpenMore(-1);
      return;
    }
    setOpenMore(idx);
  };
  const setItemInfo = (item) => {
    console.log('setItemInfo : ', item)
    dispatch(setSelectLocker(item));
  };
  const onCloseModal = useCallback(
    (id) => {

        console.log('onCloseModal : ', id)
      setModals((modals) =>
        modals.map((modal) =>
          modal.id === id ? { ...modal, visible: false } : modal,
        ),
      );
    },
    [dispatch],
  );
  const onOpenModal = useCallback((id) => {
    console.log('onOpenModal : ', id)
    setModals((modals) =>
      modals.map((modal) =>
        modal.id === id ? { ...modal, visible: true } : modal,
      ),
    );
  }, []);

  const onModify = () => {
    if(selectLocker && selectLocker.playerID) {
      dispatch(
          readPointUserTemp({
              clubID:club.clubID, 
              targetID: selectLocker.playerID,
              socketKey,
          }),
        );
      setModals((modals) =>
        modals.map((modal) =>
          modal.id === 'modify' ? { ...modal, visible: true } : modal,
        ),
      );
    }
  };

  const onDeleteConfirm = () => {
      //-->delete
    dispatch(
        removeClubUserLocker({
            clubID: club.clubID,
            clubUserLockerID: selectLocker.clubUserLockerID,
            socketKey,
            lockerID: selectLocker.lockerID,
      }),
    );
    setModals((modals) =>
      modals.map((modal) =>
        modal.id === 'remove' ? { ...modal, visible: false } : modal,
      ),
    );
    dispatch(
        loadClubUserLocker({
            clubID: club.clubID,
            lockerID:selectLocker.lockerID,
            socketKey,
        }),
    );
  };
  const onCancel = () => {
    setModals((modals) =>
      modals.map((modal) =>
        modal.id === 'remove' ? { ...modal, visible: false } : modal,
      ),
    );
  };

  const onCloseMoreBtn = () => {
    //dispatch(initialize());
  };

  const editAsk = () => {

    let selectStartTime = new Date(selectLocker.startTime * 1000).setHours(0, 0, 0, 0)
    let selectEndTime = new Date(selectLocker.endTime * 1000).setHours(23, 59, 59, 0)
    let today = new Date().setHours(0, 0, 0, 0)

    if(selectEndTime < selectStartTime) {
        alert(`정상적인 기간을 입력해주세요.`);
        return false;
    }

    for (let i = 0; i < clubuserlocker.length; i++) {
      let startTime = new Date(clubuserlocker[i].startTime * 1000).setHours(0, 0, 0, 0)
      let endTime = new Date(clubuserlocker[i].endTime * 1000).setHours(23, 59, 59, 0)

      if(clubuserlocker[i].clubUserLockerID != selectLocker.clubUserLockerID) {
        if(!((selectStartTime < startTime && selectEndTime < startTime) || (selectStartTime > endTime && selectEndTime > endTime))) {
          if(startTime < today && endTime < today) {
              setLockerEditConfirm(true);
              return false;
          } else {
            alert("선택한 락커에 기간이 중복되는 락커가 존재합니다.")
            return false;
          }
        }
      }
    }
    setLockerEditConfirm(false);

    onEditConfirm()
  }

  const onEditConfirm = () => {
      //--> edit

    let clubLockUserData = {
        clubUserLockerID:selectLocker.clubUserLockerID,
        clubItemID:selectLocker.clubItemID,
        lockerID:Number(selectLocker.lockerID),
        card:Number(selectLocker.card),
        card2:Number(selectLocker.card2),
        company:selectLocker.company,
        company2:selectLocker.company2,
        authNumber:selectLocker.authNumber,
        authNumber2:selectLocker.authNumber2,
        InstallmentMonth:selectLocker.InstallmentMonth,
        InstallmentMonth2:selectLocker.InstallmentMonth2,
        cash:Number(selectLocker.cash),
        point:Number(selectLocker.point),
        pay:Number(selectLocker.pay),
        salePrice:Number(selectLocker.card) + Number(selectLocker.card2) + Number(selectLocker.cash) + Number(selectLocker.point) + Number(selectLocker.pay),
        price:Number(selectLocker.card) + Number(selectLocker.card2) + Number(selectLocker.cash) + Number(selectLocker.point) + Number(selectLocker.pay),
        category:Number(selectLocker.category),
        state:Number(selectLocker.state),
        holdList:[],
        playerID:selectLocker.playerID,
        playerName:selectLocker.playerName,
        phone:selectLocker.phone,
        name:`${name} ${lockerSN}`,
        type:Number(selectLocker.type),
        startTime:Math.ceil(new Date(selectLocker.startTime * 1000).setHours(0, 0, 0, 0) / 1000),
        endTime:Math.ceil(new Date(selectLocker.endTime * 1000).setHours(0, 0, 0, 0) / 1000),
        registTime:selectLocker.registTime,
        controlList: selectLocker.controlList,
        unpayment:Number(selectLocker.unpayment),
        password:selectLocker.password,
        isNew:0,
        subsID:selectLocker.subsID
    }
    dispatch(
        modifyClubUserLocker({
            clubID: club.clubID,
            clubLockUserData: clubLockUserData,
            lockerID: lockerID,
            socketKey,
      }),
    );
    setModals((modals) =>
      modals.map((modal) =>
        modal.id === 'modify' ? { ...modal, visible: false } : modal,
      ),
    );
    dispatch(
        loadClubUserLocker({
            clubID: club.clubID,
            lockerID:selectLocker.lockerID,
            socketKey,
        }),
    );
  }

  const onMove = () => {

    setLockerMoveConfirm(false);
    setLockerMoveModal(false);
    setLockerMoveSecondConfirm(false);
    
    let clubLockUserData = {
        clubUserLockerID:selectLocker.clubUserLockerID,
        clubItemID:selectLocker.clubItemID,
        lockerID:selectMoveLocker.lockerID,
        card:Number(selectLocker.card),
        card2:Number(selectLocker.card2),
        company:selectLocker.company,
        company2:selectLocker.company2,
        authNumber:selectLocker.authNumber,
        authNumber2:selectLocker.authNumber2,
        InstallmentMonth:selectLocker.InstallmentMonth,
        InstallmentMonth2:selectLocker.InstallmentMonth2,
        cash:Number(selectLocker.cash),
        point:Number(selectLocker.point),
        pay:Number(selectLocker.pay),
        salePrice:Number(selectLocker.card) + Number(selectLocker.card2) + Number(selectLocker.cash) + Number(selectLocker.point) + Number(selectLocker.pay),
        price:Number(selectLocker.card) + Number(selectLocker.card2) + Number(selectLocker.cash) + Number(selectLocker.point) + Number(selectLocker.pay),
        category:Number(selectLocker.category),
        state:Number(selectLocker.state),
        holdList:[],
        playerID:selectLocker.playerID,
        playerName:selectLocker.playerName,
        phone:selectLocker.phone,
        name:`${name} ${lockerSN}`,
        type:Number(selectLocker.type),
        startTime:Math.ceil(new Date(selectLocker.startTime * 1000).setHours(0, 0, 0, 0) / 1000),
        endTime:Math.ceil(new Date(selectLocker.endTime * 1000).setHours(0, 0, 0, 0) / 1000),
        registTime:selectLocker.registTime,
        controlList:selectLocker.controlList,
        unpayment:Number(selectLocker.unpayment),
        password:selectLocker.password,
        isNew:0,
        subsID:selectLocker.subsID,
    }
    dispatch(
      moveClubUserLocker({
            clubID: club.clubID,
            clubLockUserData: clubLockUserData,
            lockerID: lockerID,
            socketKey,
      }),
    );
    dispatch(
        loadClubUserLocker({
            clubID: club.clubID,
            lockerID:clubLockUserData.lockerID,
            socketKey,
        }),
    );
  }
  
  const onChangeEditField = (e) => {
    let { name, value } = e.target;
    console.log('onChangeEditField : ', name, value)
    const commaStr = ['price', 'card', 'cash', 'point', 'pay', 'salePrice', 'unpayment'];
    if (commaStr.some((el) => el.includes(name))) {
      value = inputNumberRemoveComma(value);
    }
    dispatch(
        changeSelectLocker({
        form:'selectItem',
        key: name,
        value,
      }),
    );
  };

  const onChangeEditDate = (date, name) => {
    const value = moment(date).startOf('day').unix();
    dispatch(
        changeSelectLocker({
            form:'selectItem',
            key: name,
            value,
      }),
    );
    
    if (name === 'startTime' && selectLockerTerm != undefined && selectLockerTerm != 0) {
        dispatch(
            setSelectLockerEndTime({
            startTime: moment(date).format('YYYY-MM-DD'),
            term: selectLockerTerm,
            }),
        );
    }
    if(name === 'endTime')
    {
        let startdate = moment.unix(selectLocker.startTime);
        if(moment(date) < startdate)
        {
            dispatch(
                changeSelectLocker({
                    form:'selectItem',
                    key: 'startTime',
                    value,
              }),
            );
        }
        
        dispatch(
            changeSelectLockerTerm('0'),
        );
    }
  };
  const onChangeTerm= (name, value) =>
  {
      dispatch(
        changeSelectLockerTerm(value.value),
      );
      if(value.value != "0")
      {
        dispatch(
            setSelectLockerEndTime({
                startTime: moment.unix(selectLocker.startTime).format('YYYY-MM-DD'),
                term: value.value,
            }),
        );
      }
  }

  const onMoveLocker = () => {

    let selectStartTime = new Date(selectLocker.startTime * 1000).setHours(0, 0, 0, 0)
    let selectEndTime = new Date(selectLocker.endTime * 1000).setHours(23, 59, 59, 0)
    let today = new Date().setHours(0, 0, 0, 0)

    for (let i = 0; i < otherClubuserlocker.length; i++) {
      let startTime = new Date(otherClubuserlocker[i].startTime * 1000).setHours(0, 0, 0, 0)
      let endTime = new Date(otherClubuserlocker[i].endTime * 1000).setHours(23, 59, 59, 0)

      if(!((selectStartTime < startTime && selectEndTime < startTime) || (selectStartTime > endTime && selectEndTime > endTime))) {
        if(startTime < today && endTime < today) {
          setLockerMoveConfirm(false);
          setLockerMoveSecondConfirm(true);
          return false;
        } else {
          alert("선택한 락커에 기간이 중복되는 락커가 존재합니다.")
          setLockerMoveConfirm(false);
          return false;
        }
      }
    }

    onMove();
    setLockerMoveConfirm(false);
    setLockerMoveModal(false);
  }

  useEffect(() => {
    if (club) {
      dispatch(
        loadClubUserLocker({
            clubID:club.clubID, 
            lockerID:lockerID,
            socketKey,
        }),
      );
    }
  }, [club, dispatch, socketKey, lockerID]);
  
  useEffect(() => {
    if (modifyClubUserlockerRes == 1) {
        alert(`락커를 수정 하였습니다.`);
        dispatch(
            resetClubUserLockerRes(
                {
                    res:0
                }
            ),
        );
      dispatch(
        loadClubUserLocker({
            clubID:club.clubID, 
            lockerID:lockerID,
            socketKey,
        }),
      );
    }
  }, [modifyClubUserlockerRes]);

  useEffect(() => {
    if(clubuserlocker)
    {
        let today = parseInt(Date.now()/1000)
        for (let i = 0; i < clubuserlocker.length; i++) {
            if(clubuserlocker[i].endTime >= today && clubuserlocker[i].startTime <= today && clubuserlocker[i].subsID && clubuserlocker[i].subsID != '')
            {
                //==> 연동중
            }
        }
    }
  }, [clubuserlocker]);

  useEffect(() => {
    if(deleteRes && deleteRes === 1) {
      alert("락커를 삭제했습니다.")
      dispatch(initializeRes())
      dispatch(
        loadClubUserLocker({
            clubID:club.clubID, 
            lockerID:lockerID,
            socketKey,
        }))
    }
  }, [deleteRes])

  useEffect(() => {
    if (moveClubUserlockerRes == 1) {
        alert(`락커를 이동 하였습니다.`);
        dispatch(
            resetClubUserLockerRes(
                {
                    res:0
                }
            ),
        );
      dispatch(
        loadClubUserLocker({
            clubID:club.clubID, 
            lockerID:lockerID,
            socketKey,
        }),
      );
      dispatch(loadClubLocker({ socketKey, clubID: club.clubID }));
    }
  }, [moveClubUserlockerRes]);

  useEffect(() => {
    if (club) {
      dispatch(initalizeLocker())
      dispatch(loadClubLocker({ socketKey, clubID: club.clubID }));
    }
  }, [club, dispatch, socketKey]);

  useEffect(() => {
    if (otherClubuserlocker) {
      setLockerMoveConfirm(true);
    }
  }, [otherClubuserlocker]);
  
  return (
    <div style={{backgroundColor:'#fff', margin:'8rem 2rem 8rem 2rem', padding:'2rem 4rem 2rem 4rem'}}>
      <LockerInfoTable
        data={clubuserlocker}
        onOpenModal={onOpenModal}
        onCloseModal={onCloseModal}
        onConfirm={onDeleteConfirm}
        onCancel={onCancel}
        modals={modals}
        viewOpenMore={viewOpenMore}
        openMore={openMore}
        setItemInfo={setItemInfo}
        onModify={onModify}
        onConfirmModify={onEditConfirm}
        onCloseMoreBtn={onCloseMoreBtn}
        editFrom={selectLocker}
        lockerName={name}
        lockerSN={lockerSN}
        onChangeEditField={onChangeEditField}
        onChangeEditDate={onChangeEditDate}
        point={pointUser}
        selectLockerTerm={selectLockerTerm}
        onChangeTerm={onChangeTerm}
        loading={loading}
        lockers={lockers}
        onMouseOver={onMouseOver}
        onClickMoveModalCell={onClickMoveModalCell}
        otherClubuserlocker={otherClubuserlocker}
        setLockerMoveConfirm={setLockerMoveConfirm}
        lockerMoveConfirm={lockerMoveConfirm}
        onMoveLocker={onMoveLocker}
        lockerMoveModal={lockerMoveModal}
        setLockerMoveModal={setLockerMoveModal}
        lockerMoveSecondConfirm={lockerMoveSecondConfirm}
        setLockerMoveSecondConfirm={setLockerMoveSecondConfirm}
        onMove={onMove}
        selectMoveLocker={selectMoveLocker}
        setLockerEditConfirm={setLockerEditConfirm}
        lockerEditConfirm={lockerEditConfirm}
        editAsk={editAsk}
        setSubModal={setSubModal}
      />
    </div>
  );
};

export default withRouter(LockerInfoListForm);
