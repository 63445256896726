import React , { useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import lockerIcon from '../../resources/images/svg/lock-24px.svg';
import Logo from './Logo';
import {
  FiSettings,
  FiUser,
  FiBriefcase,
  FiCalendar,
  FiDollarSign,
  FiBarChart,
  FiShoppingCart,
  FiMonitor,
  FiEdit,
  FiKey,
} from 'react-icons/fi';
import {FaMobileAlt} from 'react-icons/fa';
import {RiCoupon2Line} from 'react-icons/ri';
import {
  ImEnter
} from 'react-icons/im';
import { MdLocalParking } from 'react-icons/md';

const navigation = [
  /* {
    name: 'attendance',
    text: '출석관리',
    url: '/attendance',
  },*/
  {
    name: 'memeber',
    text: '회원',
    url: '/member',
    icon: function () {
      return <FiUser />;
    },
  },
  {
    name: 'attend',
    text: '출입 기록',
    url: '/attend',
    icon: function () {
      return <ImEnter />;
    },
  },
  {
    name: 'locker',
    text: '락커',
    url: '/locker',
    icon: function () {
      return <LockerIcon src={lockerIcon} />
    },
  },
  {
    name: 'lesson',
    text: '레슨',
    url: '/lesson',
    icon: function () {
      return <FiCalendar />
    },
  },
  {
    name: 'mobileProduct',
    text: '모바일 판매',
    url: '/mobileProduct',
    icon: function () {
      return <FiShoppingCart />;
    },
  },
  {
    name: 'mobile',
    text: '광고/홍보',
    url: '/mobile',
    icon: function () {
      return <FaMobileAlt />;
    },
  },
  {
    name: 'sales',
    text: '매출',
    url: '/sales',
    icon: function () {
      return <FiDollarSign />;
    },
  },
  {
    name: 'statistics',
    text: '통계',
    url: '/statistics',
    icon: function () {
      return <FiBarChart />;
    },
  },
  {
    name: 'point',
    text: '포인트',
    url: '/point',
    icon: function () {
      return <MdLocalParking />;
    },
  },
  {
    name: 'prescription',
    text: '기록 & 처방',
    url: '/prescription',
    icon: function () {
      return <FiEdit />;
    },
  },
  {
    name: 'coupon',
    text: '트랩 쿠폰',
    url: '/coupon',
    icon: function () {
      return <RiCoupon2Line />;
    },
  },
  {
    name: 'configuration',
    text: '설정',
    url: '/configuration/center',
    icon: function () {
      return <FiSettings />;
    },
  },
];

const NavigationBlock = styled.div`
  display: flex;
  width: 240px;
  height: 100%;
  position: fixed;
  min-width: 150px;
  flex-direction: column;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  background: white;
  z-index: 10;
  @media screen and (max-width: 1600px) {
    width: 200px;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const LockerIcon = styled.img`
  width: 16.8px;
  height: 16.8px;
`;
const StyledMenu = css`
  color: ${palette.webBlack[9]};
  font-size: 1rem;
  display: flex;
  align-items: center;
  width: 130px;
  @media screen and (max-width: 1600px) {
    width: 110px;
    font-size: 0.8rem;
  }
`;
const StyledLink = styled(NavLink)`
  ${StyledMenu}

  span {
    margin-right: 10px;
    font-size: 1.05rem;
    @media screen and (max-width: 1600px) {
      font-size: 0.9rem;
    }
  }
`;
const StyledUl = styled.ul`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledLi = styled.li`
  margin-top: 1.25rem;

  & .active {
    filter: invert(33%) sepia(95%) saturate(1756%) hue-rotate(201deg) brightness(94%) contrast(95%);
  }
`;
const svgStyle = {
  marginRight: '15px',
};

const activeStyle = {
  color: palette.webCyan[5],
  fontWeight: 'bold',
};

const Navigation = () => {
    const {
        currentClub,
        user,
      } = useSelector(({ user, club, socket }) => ({
        currentClub: club.defaultClub,
        user: user.user,
    }));

    let testClubID = localStorage.getItem("testClubID")


  return (
    <NavigationBlock>
      <Logo />
      <StyledUl>
          {navigation.map((menu) => {
            if (!currentClub) return false
            if (currentClub && currentClub.pointActive == 0 && menu.name == 'point') return false
            if (currentClub && currentClub.attendActive == 0 && menu.name == 'attend') return false
            if (currentClub && currentClub.couponFlag == 0 && menu.name == 'coupon') return false

            return(
              <StyledLi key={menu.name} style={{position: 'relative'}}>
                <StyledLink to={menu.url} activeStyle={activeStyle}>
                  <span className={menu.text && menu.text=="locker" ? "active" : ""}>{menu.icon()}</span>
                  <span>{menu.text}</span>
                </StyledLink>
              </StyledLi>
            )
          })}
      </StyledUl>
      {
        (user && (user.memberType === 1 || user.memberType === 2))?
        <div style={{marginTop: '2rem', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <StyledLi key={'admin'} style={{position: 'relative', marginTop:'20px'}}>
                <StyledLink to={'/admin'} activeStyle={activeStyle}>
                    <span className={'admin'}>{<FiKey />}</span>
                    <span>{'Admin'}</span>
                </StyledLink>
            </StyledLi>
        </div>
        :null
      }
    </NavigationBlock>
  );
};

export default Navigation;
