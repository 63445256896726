import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import CustomDatePicker from '../common/CustomDatePicker';
import Button from '../common/Button';
import { FileDrop } from 'react-file-drop'
import { AutoSizer, List } from 'react-virtualized';
import { FaTrash } from 'react-icons/fa';
import Select from 'react-select';
import { ImFilePicture } from 'react-icons/im';
import {
  timeValueToDate,
  inputNumberAutoComma,
  sort,
  sortReverse,
} from '../../lib/commonFunc/commonFunc';
import { FaArrowLeft } from 'react-icons/fa';
import palette from '../../lib/styles/palette';
import TableScrollbar from 'react-table-scrollbar';
import { Ring } from 'react-awesome-spinners';
import { ImPlus } from 'react-icons/im';
import profile from '../../resources/images/common/default_profile.jpg';
import MessageAddMember from './modal/MessageAddMember'
import TempStorage from './modal/TempStorage'
import SentRecord from './modal/SentRecord'
import TempModal from '../common/ConfirmModal'
import ConfirmModal from '../common/ConfirmModal'
import InitModal from '../common/ConfirmModal'
import landingPage from '../../modules/app/landingPage';

const MsgContainer = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
`;

const LeftBox = styled.div`
    width: 100%;
`;
const RightBox = styled.div`

`;
const TopBtnBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
const TopBtn = styled.button`
    width: 130px;
    padding: 6px 0;
    border: 0;
    border-radius: .2rem;
    background-color: ${palette.webCyan[5]};
    color: #fff;
    cursor: pointer;

    &:nth-child(1) {
        margin-right: 20px;
    }
    
    &:focus {
        outline: none;
    }
`;

const PreviewBox = styled.div`
    width: 330px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid ${palette.webGray[5]};
    height: 580px;
    margin: 35px 130px 0 130px;
    border-radius: .3rem;
    background-color: #fff;
    box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.05);
`;

const PreviewTop = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
`;

const Top1 = styled.div`
    width: 10px;
    height: 10px;
    background-color: ${palette.webGray[5]};
    margin-right: 20px;
    border-radius: 100%;
`;

const Top2 = styled.div`
    width: 70px;
    height: 10px;
    border-radius: .3rem;
    background-color: ${palette.webGray[5]};
`;

const Preaview = styled.div`
    display: flex;
    flex-direction: column;
    width: 85%;
    height: 80%;
    border: 1px solid ${palette.webGray[15]};
    border-radius: .2rem;
    background-color: ${palette.webGray[15]};
`;

const PreviewHeader = styled.div`
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;

    & svg {
        font-size: .9rem;
        margin-right: 20px;
    }

    & {
        font-size: .9rem;
        padding: 10px 10px;
    }
`;

const TitleBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
`;

const LinkBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

const SubBox = styled.div`
    display: flex;
    width: 100px;

    & .block {
        padding: 0;
        height: 20px;
        width: 8px;
        border-radius: .1rem;
        background-color: ${palette.webCyan[5]};
        margin-right: 10px;
    }
`;

const Sub = styled.div`
    font-size: 1rem;
    font-weight: bold;
`;

const TitleInputBox = styled.input`
    width: 100%;
    font-size: 1rem;
    padding: 5px 10px;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[16]};

    &:focus {
        outline: none;
    }
`;

const InputBox = styled.textarea`
    width: 100%;
    font-size: 1rem;
    height: 300px;
    padding: 5px 10px;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[16]};
    margin-top: 10px;
    resize: none;

    &:focus {
        outline: none;
    }
`;

const PhotoImgBox = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: auto;
    display: flex;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &::-webkit-scrollbar {
      height: 10px;
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }
`;

const PhotoBox = styled.div`
    width: 100%;
    font-size: 1rem;
    height: 300px;
    padding: 5px 0;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[16]};
    background-color: #F0F0F0;
    margin-top: 10px;
    resize: none;

    & div.svgBox {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

    }
    & svg {
        font-size: 6rem;
        color: ${palette.webGray[5]};
    }

    &:hover {
        opacity: .7;
    }

    &:focus {
        outline: none;
    }

    &.drag {
        opacity: .7;
    }
`;

const PhotoImg = styled.div`
    position: relative;
    min-width: 200px; 
    width: 100%;
    margin: 0 1%;
    height: 100%;
    border-radius: .3rem;
    overflow: hidden;

    & div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        opacity: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        & svg {
            font-size: 3rem;
            color: #000;
            cursor: pointer;
        }

        &:hover {
            opacity: .7;
        }
    }

    & img {
        width: 100%;
        height: 100%;
        background-color: ${palette.webGray[15]};
        object-fit: contain;

        &:hover {
            opacity: .7;
        }
    }
`;

const ContentBox = styled.div`
    width: 100%;
    margin-bottom: 30px;
`;

const PreaveiwContent = styled.div`
    flex: 1;
    overflow-y: auto;
    background-color: ${palette.webGray[15]};
    display: flex;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }

    &:hover {
        &::-webkit-scrollbar {
        width: 10px;
        display: block;
        }
    }
`;

const ClubPhotoBox = styled.div`
    width: 60px;
    height: 100%;
`;

const MsgBox = styled.div`
    width: 100%;
    height: fit-content;
    margin-bottom: 20px;
`;

const ClubPhoto = styled.img`
    border-radius: 100%;
    width: 100%;
    height: auto;
    padding: 6px;
    object-fit: contain;
    margin-top: 10px;
`;

const MsgBoxTop = styled.div`
    width: 100%;
    font-size: .85rem;
    color: ${palette.webGray[7]};
    margin-top: 25px;
`;

const Msg = styled.div`
    width: 95%;
    padding: 10px;
    background-color: #fff;
    margin-top: 10px;
    border-radius: .3rem;
`;

const MsgNullBox = styled.div`
    width: 95%;
    height: 250px;
    font-size: .95rem;
    color: ${palette.webGray[11]};
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #fff;
    margin-top: 10px;
    border-radius: .3rem;
`;

const MsgTitle = styled.div`
    width: 100%;
    font-weight: bold;
    font-size: .92rem;
    margin-bottom: 10px;
    word-break: break-all;
    white-space: pre-line;
`;

const MsgContents = styled.div`
    width: 100%;
    font-size: .85rem;
    line-height: 1.4rem;
    word-break: break-all;
    white-space: pre-line;
    margin-bottom: 10px;
`;

const MsgPhoto = styled.div`
    width: 100%;
    
    & img {
        width: 100%;
        height: auto;
        margin-bottom: 10px;
        border-radius: .3rem;
    }
`;

const MsgLink = styled.button`
    width: 100%;
    padding: 6px 0;
    border: 0;
    border-radius: .2rem;
    background-color: ${palette.webCyan[5]};
    color: #fff;

    &:focus {
        outline: none;
    }
`;

const BtnBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 55px;
`;

const Btn = styled.div`
    width: 120px;
    text-align: center;
    padding: 6px 0;
    border: 0;
    border-radius: .2rem;
    margin: 0 10px;
    background-color: ${palette.webGray[5]};
    color: #fff;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

const BtnSend = styled.div`
    width: 120px;
    text-align: center;
    padding: 6px 0;
    border: 0;
    margin: 0 10px;
    border-radius: .2rem;
    background-color: ${palette.webCyan[5]};
    color: #fff;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

const BtnAddMember = styled.div`
    width: 100px;
    text-align: center;
    padding: 4px 0;
    border: 1px solid ${palette.webGray[5]};
    border-radius: .2rem;
    background-color: #fff;
    font-size: .95rem;
    color: #000;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

const MemberBox = styled.div`
    width: 100%;
    height: 150px;
    border: 1px solid ${palette.webGray[5]};
    border-bottom-left-radius: .2rem;
    border-bottom-right-radius: .2rem;
    border-top: 0;
    overflow: hidden;
    background-color: #fff;
    
    & :focus {
        outline: none;
    }

    & ::-webkit-scrollbar {
      width: 10px;
    }
    & ::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    & ::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }
`;

const MemberBoxHeader = styled.div`
    display: flex;
    align-items:center;
    width: 100%;
    height: 40px;
    background-color: ${palette.webGray[15]};
    font-size: .95rem;
    font-weight: bold;
    border: 1px solid ${palette.webGray[5]};
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem;
    margin-top: 10px;

    & .name {
        width: 20%;
        text-align: center;
    }
    & .account {
        width: 60%;
        text-align: center;
    }
    & .delete {
        width: 20%;
        text-align: center;
    }
`;

const MemberListItem = styled.ul`
    width: 100%;
    display: flex;
    align-items: center;

    border-bottom: 1px solid ${palette.webGray[15]};

    & li {
        text-align: center;
        font-size: .95rem;

        &.name {
            width: 20%;
        }
        &.account {
            width: 60%;
        }
        &.delete {
            width: 20%;
            
            & button {
                border: 0;
                border-radius: .2rem;
                background-color: ${palette.warning};
                color: #fff;
                cursor: pointer;
                padding: 4px 10px;

                &:focus {
                    outline: none;
                }
            }
        }
    }
`;

const MemberNullBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: .95rem;
    color: ${palette.webGray[6]};
`;

const SelectBox = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`;

const SortSelect = styled.div`
    width: 150px;
    margin-right: 20px;

    @media screen and (max-width: 1708px) {
        margin-bottom: 10px;
    }
`;

const ItemSelect = styled.div`
    flex: 1;
    min-width: 300px;
`;
const MyImageModalWrap = styled.div`
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const MyImageBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;
const MyImageBtnBox = styled.div`
    width: 100%;
  display: flex;
  padding: 10px 25px;
  margin-bottom: 15px;
  justify-content: center;
  font-size: 1rem;
  text-align: right;

  & button {
    cursor: pointer;
    padding: 4px 20px;
    text-align: center;
    border: none;
    font-size: .95rem;
    color: #fff;
    border-radius: 3px;
    background-color: ${palette.webGray[5]};

    &:focus{
      outline: none;
    }     
    
    &.confirm {
      background-color: ${palette.webCyan[5]};
      margin-right: 10px;
    }
  }
`;
const MyImageTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const MyImageContentBox = styled.div`
  width: 100%;
  height: auto;
  padding: 20px 30px;
`;
const SortBox = styled.ul`
width: 100%;
display: flex;
align-items: center;
margin-top: 20px;
margin-bottom: 15px;


& li {
    padding: 0 15px;
    font-size: 1.05rem;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    & span {
        position: absolute;
        top: -20px;
        font-size: .85rem;
        color: ${palette.webGray[6]};

    }
        &.active {
            font-weight: bold;
        }
    }
`;

const MyImageList = styled.ul`
    width: 800px;
    height: 500px;
    display: flex;
    padding: 20px 0;
    flex-wrap: wrap;
    display: flex;
    overflow-y: auto;
    border: 1px solid ${palette.webGray[15]};
    border-radius: .2rem;
    background-color: #fff;
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
    }
`;  

const MyImageItem = styled.li`
    width: 29%;
    margin: 0 2% 20px 2%;
`;

const ImageBox = styled.div`
    width: 100%;
    height: 270px;
    background-color: ${palette.webGray[17]};
    border-radius: .3rem;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
            width: 100%;
            height: auto;
            cursor: pointer;

            &:hover {
                opacity: .7;
            }
    }
`;

const ImageBtnBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & button {
        border: 0;
        border-radius: .2rem;
        width: 60%;
        padding: 7px 0;
        background-color: ${palette.webCyan[5]};
        color: #fff;
        cursor: pointer;

        &:focus{
            outline: none;
        }

        &.delete {
            background-color: ${palette.webGray[5]};
            width: 30%;
        }
    }
`;

const ImageTitle = styled.div`
    width: 100%;
    text-align: center;
    font-size: .95rem;
    font-weight: bold;
    padding: 10px 4px;
    overflow:hidden; 
    text-overflow:ellipsis; 
    white-space:nowrap;
    background-color: #fff;
`;

const ImageRegistTime = styled.div`
    display: flex; 
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    font-size: .85rem;
    color: ${palette.webGray[6]};

    & svg {
        position: absolute;
        right: 4px;
        font-size: 1.1rem;
        cursor: pointer;
    }
`;

const MyImageNullBox = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content:center;
    align-items:center;
    font-size: .85rem;
    color: ${palette.webGray[5]};
`;

const NotificationMessage = ({
    onChangeField, 
    image, 
    link, 
    club, 
    isDrag,
    onDrop,
    onTargetClick,
    fileInputRef,
    onFileInputChange,
    setIsDrag,
    addMemberModal,
    setAddMemberModal,
    members,
    onSelectMember,
    selectedMembers,
    onSelectTempMember,
    selectedTempMembers,
    onSelectMemberDelete,
    openModal,
    onSelectTempMemberDelete,
    closeModal,
    onSelectedTempMemberControl,
    sortType,
    onMemberTypeChange,
    expiredMembers,
    readyMembers,
    availableMembers,
    deleteImage,
    sentRecordModal,
    setSentRecordModal,
    onSaveMessage,
    sentMessageList,
    title,
    content,
    setTitle,
    setContent,
    initPage,
    onDeleteMessage,
    onloadData,
    pageSort,
    pageLink,
    onChagePageLink,
    onChangePageSort,
    landingPageList,
    uploadMyImage,
    templateList,
    mobileProductList,
    onMsgDelete,
    deleteMsgList,
    setDeleteMsgList,
    setDeleteMode,
    deleteMode,
    setIsAllDelete,
    isAllDelete,
    loading,
    onCloseRecordModal,
    onLoadMsg,
    selectedMessage,
    onLoadDetailMessage,
    onLoadMember,

    targetSex,
    targetAgeMin,
    targetAgeMax,
    onMemberSexChange,
    onMemberAgeMinChange,
    onMemberAgeMaxChange,
}) => {

    const [confirmModal, setConfirmModal] = useState(false);
    const [tempModal, setTempModal] = useState(false);
    const [initModal, setInitModal] = useState(false);
    const [myImageModal, setMyImageModal] = useState(false)
    const [templateSize, setTemplateSize] = useState(1)
    let myImage = [];

    const options = [
        { label: '없음', value: 1 },
        { label: '링크 페이지', value: 3 },
        { label: '모바일 상품', value: 2 },
        { label: '스토어', value: 4 },
    ];

    if(templateList && templateList.length > 0) {
        if(templateSize == 1) {
            myImage = templateList
        } else if(templateSize == 2) {
            myImage = templateList.filter(item => {return item.size == "large"})
        } else if(templateSize == 3) {
            myImage = templateList.filter(item => {return item.size == "small"})
        }
    }

    let activeMobileList = mobileProductList.filter((item) => {return item.isActive == 1})


    const selectedMemberRenderer = useCallback(
      ({ index, key, style }) => {
        const member = selectedMembers[index];
        return (
          <MemberListItem
            style={style}
            key={index}
          >
              <li className="name">{member.name}</li>
              <li className="account">{member.account}</li>
              <li className="delete"><button  onClick={() => onSelectMemberDelete(member)}>삭제</button></li>
          </MemberListItem>
        );
      },
      [selectedMembers],
    );

    return(
        <>
            <MsgContainer>
                <LeftBox>
                    <TitleBox>
                        <SubBox>
                            <div className="block" />
                            <Sub>제목</Sub>
                        </SubBox>
                        <TitleInputBox name="title" onChange={(e) => {setTitle(e.target.value)}} value={title} placeholder="알림 제목을 입력해주세요." maxLength={50} style={{marginTop: 0}}/>
                    </TitleBox>
                    <div style={{display: 'flex'}}>
                    <ContentBox>
                        <SubBox style={{paddingBottom: '7px'}}>
                            <div className="block" />
                            <Sub>내용</Sub>
                        </SubBox>
                        <InputBox name="content" onChange={(e) => {setContent(e.target.value)}} value={content} placeholder="알림 내용을 입력해주세요." maxLength={600}/>
                    </ContentBox>
                    <ContentBox style={{width: '300px', marginLeft: '30px'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                            <SubBox style={{width: '300px'}}>
                                <div className="block" />
                                {/* <Sub>첨부 사진<span> ({image.length} / 3)</span></Sub> */}
                                <Sub>첨부 사진</Sub>
                            </SubBox>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <BtnAddMember 
                                    style={{width: '120px'}}
                                    onClick={() => setMyImageModal(true)
                                }>이미지 보관함</BtnAddMember>
                            </div>
                        </div>
                        <FileDrop
                            onDrop={onDrop}
                            onDragOver={() => setIsDrag(true)}
                            onDragLeave={() => setIsDrag(false)}
                        >
                            <PhotoBox className={isDrag ? "drag" : ""}>
                                <input
                                    onChange={onFileInputChange}
                                    ref={fileInputRef}
                                    type="file"
                                    style={{display: 'none'}}
                                />
                                {image.length > 0 ?
                                <>
                                    <PhotoImgBox>
                                        {image.map((item, idx) => {return (
                                            <PhotoImg key={idx} onClick={() => deleteImage(idx)}>
                                                <div><FaTrash /></div>
                                                {
                                                    item && !item.type?
                                                    <img src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${item}`}/>
                                                    :
                                                    <img src={URL.createObjectURL(item)}/>
                                                }
                                            </PhotoImg>)
                                            })
                                        }
                                    </PhotoImgBox>
                                </>
                                :
                                <div className="svgBox" onClick={() => onTargetClick()}>
                                    <ImFilePicture />
                                </div>
                                }
                            </PhotoBox>
                        </FileDrop>
                    </ContentBox>
                    </div>
                    <ContentBox style={{borderTop: `1px solid ${palette.webGray[5]}`, paddingTop: '30px', marginTop: '10px'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                            <SubBox style={{width: '300px'}}>
                                <div className="block" />
                                <Sub>수신 회원<span> (총 {selectedMembers.length}명)</span></Sub>
                            </SubBox>
                            <BtnAddMember onClick={() => {
                                onLoadMember()
                                openModal()
                            }}>회원 추가</BtnAddMember>
                        </div>
                        <MemberBoxHeader>
                            <div className="name">이름</div>
                            <div className="account">계정</div>
                            <div className="delete"></div>
                        </MemberBoxHeader>
                        <MemberBox>
                            {selectedMembers && selectedMembers.length > 0 ?
                            <AutoSizer>
                                {({ height, width }) => {
                                    return (
                                        <List
                                        rowRenderer={selectedMemberRenderer}
                                        rowCount={selectedMembers.length}
                                        width={width}
                                        height={height}
                                        rowHeight={50}
                                        list={selectedMembers}
                                        overscanRowCount={5}
                                        className="search_list"
                                        />
                                    );
                                }}
                            </AutoSizer>
                            :
                            <MemberNullBox>수신 회원이 없습니다.</MemberNullBox>
                            }
                        </MemberBox>
                    </ContentBox>
                    <LinkBox>
                        <SubBox style={{width: '150px'}}>
                            <div className="block" />
                            <Sub>연결 페이지</Sub>
                        </SubBox>
                        <SelectBox>
                            <SortSelect>
                                <Select
                                    options={options}
                                    isSearchable={false}
                                    defaultValue={{label:"없음", value: 1}}
                                    aria-labelledby="select"
                                    onChange={onChangePageSort}
                                    maxMenuHeight={'500px'}
                                    value={options.find(item => {return item.value === pageSort})}
                                />
                            </SortSelect>
                            {pageSort !== 1 && pageSort !== 4 ?
                                    pageSort == 2 ?
                                        <ItemSelect>
                                            <Select
                                                options={
                                                    Array.isArray(activeMobileList)
                                                        ? sortReverse(activeMobileList, "title").map((item, idx) => {return item = {...item, label: item.title, value: idx}})
                                                        : []}
                                                isSearchable={false}
                                                onChange={onChagePageLink}
                                                placeholder={pageLink ? mobileProductList && mobileProductList.length > 0 && mobileProductList.find(item => {return item.clubOnlineSalesItemID == pageLink}).title : "링크 페이지를 선택해주세요."}
                                                aria-labelledby="select"
                                                maxMenuHeight={'500px'}
                                            />
                                        </ItemSelect>
                                    :
                                        pageSort == 3 ?
                                        <ItemSelect>
                                            <Select
                                                options={
                                                    Array.isArray(landingPageList)
                                                        ? sortReverse(landingPageList, "title").map((item, idx) => {return item = {...item, label: item.title, value: idx}})
                                                        : []}
                                                isSearchable={false}
                                                onChange={onChagePageLink}
                                                placeholder={pageLink ? landingPageList && landingPageList.length > 0 && landingPageList.find(item => {return item.clubLandingPageID == pageLink}).title : "링크 페이지를 선택해주세요."}
                                                aria-labelledby="select"
                                                maxMenuHeight={'500px'}
                                            />
                                        </ItemSelect>
                                    :
                                        ""
                                :
                                    ""
                            }
                        </SelectBox>
                    </LinkBox>
                </LeftBox>
                <RightBox>
                    <TopBtnBox>
                        <TopBtn onClick={() => {
                            onLoadMsg(1)
                            setSentRecordModal(true)
                        }}>보낸 알림 기록</TopBtn>
                        <TopBtn onClick={() => {
                            onLoadMsg(2)
                            setSentRecordModal(true)
                        }}>임시 저장함</TopBtn>
                    </TopBtnBox>
                    <PreviewBox draggable={false}>
                        <PreviewTop>
                            <Top1 />
                            <Top2 />
                        </PreviewTop>
                        <Preaview>
                            <PreviewHeader>
                                <FaArrowLeft /><span>알림</span>
                            </PreviewHeader>
                            <PreaveiwContent>
                                <ClubPhotoBox>
                                    <ClubPhoto src={profile}/>
                                </ClubPhotoBox>
                                <MsgBox>
                                    <MsgBoxTop>{club && club.name}</MsgBoxTop>
                                    {title == "" && content == "" && image.length == 0 && !pageLink ?
                                    <MsgNullBox>
                                        내용을 입력해주세요.
                                     </MsgNullBox>
                                    :
                                    <Msg>
                                        {title &&
                                            <MsgTitle>
                                                {title}
                                            </MsgTitle>
                                        }
                                        <MsgContents>
                                            {content}
                                        </MsgContents>
                                        <MsgPhoto>
                                            {image.map((item, idx) => {
                                            if(item && !item.type) {
                                                return(<img key={idx} src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${item}`}/>)
                                            } else {
                                                return(<img key={idx} src={URL.createObjectURL(item)}/>)
                                            }
                                            })}
                                        </MsgPhoto>
                                        {pageLink && <MsgLink>바로 GO</MsgLink>}
                                    </Msg>
                                    }
                                </MsgBox>
                            </PreaveiwContent>
                        </Preaview>
                    </PreviewBox>
                    <BtnBox>
                        <BtnSend onClick={() => setConfirmModal(true)}>알림 전송</BtnSend>
                        <Btn onClick={() => setTempModal(true)}>임시 저장</Btn>
                        <Btn onClick={() => setInitModal(true)}>취소</Btn>
                    </BtnBox>
                </RightBox>
            </MsgContainer>
            {myImageModal &&
                <MyImageModalWrap>
                    <Background />
                    <MyImageBox>
                        <MyImageTitle>이미지 보관함</MyImageTitle>
                        <MyImageContentBox>
                            <SortBox>
                                <li className={templateSize == 1 ? "active" : ""} onClick={() => {if(templateSize != 1) setTemplateSize(1)}}>전체</li>
                                <li className={templateSize == 2 ? "active" : ""} onClick={() => {if(templateSize != 2) setTemplateSize(2)}}><span>720 * 720</span>큰 사이즈</li>
                                <li className={templateSize == 3 ? "active" : ""} onClick={() => {if(templateSize != 3) setTemplateSize(3)}}><span>720 * 360</span>작은 사이즈(배너)</li>
                            </SortBox>
                            <MyImageList>
                                {myImage && myImage.length > 0 ?
                                    myImage.map((image, idx) => {
                                        return(
                                            <MyImageItem key={idx}>
                                                <ImageBox>
                                                    <img src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/template/${image.templateURL}`} 
                                                    onClick={() => {
                                                        uploadMyImage(`template/${image.templateURL}`)
                                                        setTemplateSize(1)
                                                        setMyImageModal(false)
                                                    }}/>
                                                </ImageBox>
                                                <ImageTitle>{image.title}</ImageTitle>
                                                <ImageRegistTime>{timeValueToDate(image.registTime)}</ImageRegistTime>
                                            </MyImageItem>
                                            )
                                    })
                                :
                                <MyImageNullBox>제작한 이미지가 없습니다.</MyImageNullBox>
                                }
                            </MyImageList>
                        </MyImageContentBox>
                        <MyImageBtnBox>
                            <button onClick={() => {
                                setTemplateSize(1)
                                setMyImageModal(false)
                            }}>닫기</button>
                        </MyImageBtnBox>
                    </MyImageBox>
                </MyImageModalWrap>
            }
            <SentRecord
                visible={sentRecordModal}
                setVisible={setSentRecordModal}
                sentMessageList={sentMessageList}
                onDeleteMessage={onDeleteMessage}
                onloadData={onloadData}
                loading={loading}
                landingPageList={landingPageList}
                onMsgDelete={onMsgDelete}
                deleteMsgList={deleteMsgList}
                setDeleteMsgList={setDeleteMsgList}
                setDeleteMode={setDeleteMode}
                deleteMode={deleteMode}
                isAllDelete={isAllDelete}
                setIsAllDelete={setIsAllDelete}
                loading={loading}
                onCloseRecordModal={onCloseRecordModal}
                selectedMessage={selectedMessage}
                onLoadDetailMessage={onLoadDetailMessage}
            />
            {/* <TempStorage
                visible={tempStorageModal}
                setVisible={setTempStorageModal}
                tempMessageList={tempMessageList}
                onDeleteMessage={onDeleteMessage}
                onloadData={onloadData}
                landingPageList={landingPageList}
                onMsgDelete={onMsgDelete}
                deleteMsgList={deleteMsgList}
                setDeleteMsgList={setDeleteMsgList}
            /> */}
            <MessageAddMember
                visible={addMemberModal}
                setVisible={setAddMemberModal}
                members={members}
                onSelectMember={onSelectMember}
                selectedMembers={selectedMembers}
                onSelectTempMember={onSelectTempMember}
                selectedTempMembers={selectedTempMembers}
                onSelectTempMemberDelete={onSelectTempMemberDelete}
                closeModal={closeModal}
                onSelectedTempMemberControl={onSelectedTempMemberControl}
                sortType={sortType}
                onMemberTypeChange={onMemberTypeChange}
                expiredMembers={expiredMembers}
                readyMembers={readyMembers}
                availableMembers={availableMembers}
                
                targetSex={targetSex}
                targetAgeMin={targetAgeMin}
                targetAgeMax={targetAgeMax}
                onMemberSexChange={onMemberSexChange}
                onMemberAgeMinChange={onMemberAgeMinChange}
                onMemberAgeMaxChange={onMemberAgeMaxChange}
            />
            <ConfirmModal
                text={`알림 메세지를 전송하시겠습니까?`}
                onClick={() => {
                    onSaveMessage(title, content, 1);
                    setConfirmModal(false);
                }}
                setModal={() => {setConfirmModal(false)}}
                confirmText = "전송"
                onModal={confirmModal}
            />
            <TempModal
                text={`내용을 임시저장 하시겠습니까?`}
                onClick={() => {
                    onSaveMessage(title, content, 2);
                    setTempModal(false);
                }}
                setModal={() => {setTempModal(false)}}
                confirmText = "저장"
                onModal={tempModal}
            />
            <InitModal
                text={`작성 내용을 초기화 하시겠습니까?`}
                onClick={() => {
                    initPage();
                    setInitModal(false);
                }}
                setModal={() => {setInitModal(false)}}
                confirmText = "확인"
                onModal={initModal}
            />
        </>
    )
};

export default React.memo(NotificationMessage);

