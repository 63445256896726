import React from 'react';
import Button from '../common/Button';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import { IoIosClose } from 'react-icons/io';
import { inputNumberAutoComma } from '../../lib/commonFunc/commonFunc';
import lockerIcon from '../../resources/images/svg/lock-24px.svg'
import CustomDatePicker from '../common/CustomDatePicker';
import {
  FiBriefcase,
} from 'react-icons/fi';
import CommonNumberInput from '../common/CommonNumberInput';
import CommonTextInput from '../common/CommonTextInput';
import Term from '../configuration/item/Term';
import OverlapModal from './OverlapModal'

const FullScreen = styled.div`
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
const AskModalBlock = styled.div`
  width: 70%;
  background: #fff;
  padding: 1.5rem;
  border-radius: 4px;
  max-height: 95%;
  overflow: auto;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.125);
  h2 {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 3rem;
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
  }

  @media screen and (max-width: 720px) {
    position: absolute;
    top: 50px;
  }

`;
const FormArea = styled.div`
  flex: 1;
  min-width: 400px;
  min-height: 300px;
  display: flex;
  padding-top: 10px;
  padding-bottom: 15px;
  flex-direction: column;
`;

const LockerInfoLabel = styled.span`
  width: 110px;
`;
const LockerInfoBlock = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  .date_picker {
    padding: 8px 16px;
    border: 1px solid #cdd9ed;
    line-height: 25px;
    font-size: 14px;
    width: 100%;
  }
`;

const Bottom = styled.div`
  display: flex;
  height: 100%;
`;
const Line = styled.hr`
  color: #c4c4c4;
  width: 100%;
`;

const LockerTopBlock = styled.div`
  display: flex;
`;
const LockerInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  margin-left: 30px;
  padding-bottom: 15px;
  align-items: center;
`;
const LockerInfoMainLabel = styled.span`
  margin-right: 30px;
  margin-top: 10px;
`;
const LockerInfoNameLabel = styled.span`
  font-size: 1.5rem;
  margin-right: 30px;
  margin-top: 10px;
`;

const Seperator = styled.div`
  & + & {
    margin-top: 2rem;
  }
  display: flex;
  flex-direction: column;
  .date_picker {
    width: 100%;
    padding: 8px 16px;
    border: 1px solid #cdd9ed;
    line-height: 25px;
    font-size: 14px;
    margin-top: 10px;
  }
`;

const Space = styled.div`
  margin-bottom: 20px;
`;

const StyledLabel = styled.label`
  width: 110px;
  font-weight: 600;
`;
const RowBlock = styled.div`
  display: flex;
  align-items: center;

  ${(props) =>
    props.margin &&
    css`
      margin: 10px 0;
    `}
`;

const SubBlock = styled.div`
  font-weight: bold;
  font-family: 'Noto Sans kr;sans-serif;';
  font-weight: 600;
  padding: 0 0 10px 0;
  margin-bottom: 20px;
  font-size: 16pt;
  border-bottom: 1px solid ${palette.webGray[18]};
`;

const CardInfoBlock = styled.div``;
const SmallInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  div {
    margin-right: 10px;
  }
`;
const PriceBlock = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
  ${(props) =>
    props.margin &&
    css`
      margin: 10px 0;
    `}
`;
const TotalPrice = styled.div`
  color: ${palette.webCyan[5]};
  font-size: 1.6rem;
  font-weight: bold;
  width: 200px;
  overflow-x: hidden;
  word-break: break-all;

  display: flex;
  align-items: center;
  div {
    display: inline-block;
    align-items: center;
    font-size: 1.4rem;
    color: black;
    font-weight: normal;
    vertical-align: text-top;
  }
`;
const TotalPriceLabel = styled.span`
  font-size: 1.1rem;
  margin-right: 30px;
  display: inline-block;
`;
const CloseBlock = styled.div`
  float: right;

  svg {
    cursor: pointer;
    font-size: 3rem;
  }
`;

const EditUserLockerModal = ({
  visible,
  title,
  onConfirm,
  onCancel,
  form,
  lockerName,
  lockerSN,
  onChangeField,
  onChange,
  selectLockerTerm,
  onChangeTerm,
  point,
  setLockerEditConfirm,
  lockerEditConfirm,
  editAsk
}) => {
    
  if (!visible) return null;
  return (
    <FullScreen>
      <AskModalBlock>
        <h2>{title}</h2>
        <FormArea>
          <LockerTopBlock>
            <LockerInfoItem style={{marginRight: '0'}}>
              <img src={lockerIcon} style={{width: '4rem', height: '4rem'}} />
            </LockerInfoItem>
            <LockerInfoItem>
                <LockerInfoMainLabel>락커 종류</LockerInfoMainLabel>
                <LockerInfoMainLabel>락커 번호</LockerInfoMainLabel>
            </LockerInfoItem>
            <LockerInfoItem>
                <LockerInfoMainLabel>{lockerName}</LockerInfoMainLabel>
                <LockerInfoMainLabel>{lockerSN}</LockerInfoMainLabel>
            </LockerInfoItem>
            <div style={{flex:1}}/>
            <CloseBlock>
                <IoIosClose onClick={()=>{onCancel('modify')}} />
            </CloseBlock>
          </LockerTopBlock>
          <Line/>
          <LockerInfoBlock full>
            <Space/>
            <LockerInfoLabel>이름</LockerInfoLabel>
            <LockerInfoNameLabel>{form.playerName}</LockerInfoNameLabel>
          </LockerInfoBlock>
          <Space/>
          <LockerInfoBlock full>
            <LockerInfoLabel>판매일</LockerInfoLabel>
            <CustomDatePicker
              onChange={(date) => onChange(date, 'registTime')}
              selected={new Date(form.registTime * 1000)}
              placeholderText="판매일을 선택해주세요."
              className="date_picker"
            />
          </LockerInfoBlock>
          <Space/>
          <LockerInfoBlock full>
            <LockerInfoLabel>시작일</LockerInfoLabel>
            <CustomDatePicker
              onChange={(date) => onChange(date, 'startTime')}
              //selected={Date.parse(form.startTime)}
              selected={new Date(form.startTime * 1000)}
              placeholderText="시작일을 선택해주세요."
              className="date_picker"
            />
          </LockerInfoBlock>
          <LockerInfoBlock full>
            <LockerInfoLabel>종료일</LockerInfoLabel>
            <CustomDatePicker
              onChange={(date) => onChange(date, 'endTime')}
              //selected={Date.parse(form.endTime)}
              selected={new Date(form.endTime * 1000)}
              placeholderText="종료일을 선택해주세요."
              className="date_picker"
            />
            <div style={{width:50}}/>
            <Term term={selectLockerTerm} onChangeSelect={onChangeTerm} isLocker/>
          </LockerInfoBlock>
          <LockerInfoBlock full>
            <LockerInfoLabel>비밀번호</LockerInfoLabel>
                <CommonNumberInput
                    id="password"
                    label="비밀번호"
                    variant="outlined"
                    name="password"
                    onChange={onChangeField}
                    value={form.password}
                    type="text"
                />
          </LockerInfoBlock>
          {/* ////// */}
          <Seperator>
            <Space/>
            <Line/>
            <RowBlock>
              <PriceBlock>
                <StyledLabel>카드</StyledLabel>
                <CardInfoBlock>
                  <CommonNumberInput
                    id="card1"
                    label="카드 1 금액"
                    variant="outlined"
                    name="card"
                    onChange={onChangeField}
                    value={inputNumberAutoComma(form.card)}
                    type="text"
                  />

                  <SmallInfo>
                    <CommonTextInput
                      id=""
                      label="할부방식"
                      variant="outlined"
                      name="InstallmentMonth"
                      onChange={onChangeField}
                      value={form.InstallmentMonth}
                      type="text"
                      size="small"
                    />
                    <CommonTextInput
                      id="company"
                      label="카드사"
                      variant="outlined"
                      name="company"
                      onChange={onChangeField}
                      value={form.company}
                      type="text"
                      size="small"
                    />
                    <CommonTextInput
                      id="authNumber"
                      label="승인번호"
                      variant="outlined"
                      name="authNumber"
                      onChange={onChangeField}
                      value={form.authNumber}
                      size="small"
                      type="text"
                      longWidth
                    />
                  </SmallInfo>
                </CardInfoBlock>
              </PriceBlock>
            </RowBlock>
            <RowBlock margin>
              <PriceBlock>
                <StyledLabel>현금</StyledLabel>
                <CommonNumberInput
                  id="cash"
                  label="금액"
                  name="cash"
                  onChange={onChangeField}
                  value={inputNumberAutoComma(form.cash)}
                />
              </PriceBlock>
              <PriceBlock>
                <StyledLabel>포인트</StyledLabel>
                <CommonNumberInput
                  id="point"
                  label={`금액${(point == null || (point.increaseTotal - point.decreaseTotal == 0))?' (사용불가)':` (${inputNumberAutoComma(point.increaseTotal - point.decreaseTotal)} P 사용가능)`}`}
                  name="point"
                  onChange={onChangeField}
                  value={inputNumberAutoComma(form.point)}
                  disabled={point == null?true:false}
                />
              </PriceBlock>
              <PriceBlock>
                <StyledLabel>페이</StyledLabel>
                <CommonNumberInput
                  id="pay"
                  label="금액"
                  name="pay"
                  onChange={onChangeField}
                  value={inputNumberAutoComma(form.pay)}
                />
              </PriceBlock>
            </RowBlock>
            <RowBlock margin>
              <PriceBlock>
                <StyledLabel>미수금</StyledLabel>
                <CommonNumberInput
                  id="unpayment"
                  label="금액"
                  variant="outlined"
                  name="unpayment"
                  onChange={onChangeField}
                  value={inputNumberAutoComma(form.unpayment)}
                  type="text"
                />
              </PriceBlock>
            </RowBlock>
          </Seperator>
          {/* ////// */}
          <Line/>

          <PriceBlock>
            <TotalPriceLabel>총 판매금액</TotalPriceLabel>
            <TotalPrice>
            {inputNumberAutoComma(Number(form.card) + Number(form.cash) + Number(form.point) + Number(form.pay))}
            <div style={{ marginLeft: '5px' }}>원</div>
            </TotalPrice>
        </PriceBlock>
        <Button onClick={editAsk} cyan fullWidth>락커 수정</Button>
        </FormArea>
      </AskModalBlock>
      <OverlapModal
        onClick={onConfirm}
        setModal={setLockerEditConfirm}
        modal={lockerEditConfirm}
        isNew={false}
        isEdit={true}
        mb={10}
      />
    </FullScreen>
  );
};

export default React.memo(EditUserLockerModal);
