import React, { useState } from 'react';

import styled, { css } from 'styled-components';
//import MemberTable from '../common/MemberTable';
import { Ring } from 'react-awesome-spinners';
import CommenButton from '../common/Button';
import { selectMainMembershipState, selectMainMembership, timeValueToDate, sort, sortReverse, inputNumberAutoComma, dateToString } from '../../lib/commonFunc/commonFunc'
import Select from 'react-select';
import palette from '../../lib/styles/palette';
import Pagination from '@material-ui/lab/Pagination';
import { GoSearch } from 'react-icons/go';
import { MdRefresh, MdSmartphone } from 'react-icons/md';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField';

const Table = styled.table`
  font-family: 'Noto Sans KR', sans-serif;
  td {
    padding: 10px;
    border-top: 1px solid ${palette.webGray[15]};
  }

  thead {
    tr {
      border-top: 2px solid ${palette.webGray[7]};
      border-bottom: 2px solid ${palette.webGray[7]};
    }
  }
`;

const TextSpace = styled.div`
  padding: 0 10px;
  font-size: 20pt;
`;

const DateBlock = styled.td`
  padding: 10px;
  font-weight: 600;
`;
const Line = styled.div`
    width: 100%;
    padding: 20px 10px;
    margin-bottom: 60px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding-bottom: 60px;
`;
const TabBlock = styled.div`
  display: flex;
  margin-bottom: 2rem;
`;
const TabText = styled.div`
  font-size: 16pt;
  cursor: pointer;
  & + & {
    margin-left: 50px;
  }
  ${(props) =>
        props.active &&
        css`
      font-weight: 700;
      color: ${palette.webCyan[5]};
    `}
`;


const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      width: state.selectProps.width,
      borderBottom: '1px dotted pink',
      color: state.selectProps.menuColor,
      padding: 20,
      fontSize: '0.95rem',
      'z-index': 500,
    }),
    indicatorSeparator: (provided, state) => ({
      backgroundColor: 'none',
    }),
    indicatorContainer: (provided, state) => ({
      color: '#646464',
    }),
    input: () => ({
      curosr: 'pointer',
    }),
    control: (_, { selectProps: { width } }) => ({
      width: width,
      display: 'flex',
      color: '#fff',
      'z-index': 500,
      curosr: 'pointer',
      border: '1px solid #ccc',
      fontSize: '0.95rem',
      paddingLeft: '10px',
      borderRadius: '5px'
    }),
    placeholder: () => ({
      cursor: 'pointer',
      color: '#646464',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
  
      return { ...provided, opacity, transition };
    },
  };


const downloadExcel = (date, tableName) => {
    const table = document.getElementsByClassName(tableName)[0];
  
    let tab_text = '<html xmlns:x="urn:schemas-microsoft-com:office:excel">';
    tab_text +=
      '<head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">';
    tab_text += '<xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>';
    tab_text += '<x:Name>Test Sheet</x:Name>';
    tab_text +=
      '<x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions></x:ExcelWorksheet>';
    tab_text += '</x:ExcelWorksheets></x:ExcelWorkbook></xml></head><body>';
    tab_text += "<table border='1px'>";
  
    let exportTable = table.cloneNode(true);
  
    tab_text += exportTable.outerHTML;
    tab_text += '</table></body></html>';
    let data_type = 'data:application/vnd.ms-excel';
    let ua = window.navigator.userAgent;
    let msie = ua.indexOf('MSIE ');
    let fileName = date + '_Table' + '.xls';
  
    // browser 처리
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      //ie 용
      if (window.navigator.msSaveBlob) {
        let blob = new Blob([tab_text], {
          type: 'application/csv;charset=utf-8;',
        });
        navigator.msSaveBlob(blob, fileName);
      }
    } else {
      //그외
      let blob2 = new Blob([tab_text], {
        type: 'application/csv;charset=utf-8;',
      });
      let filename = fileName;
      let elem = window.document.createElement('a');
      elem.href = window.URL.createObjectURL(blob2);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
};

const AdminClubSms = ({
    smsPaymentList,
    aligoPoint,
    selectClub,
    setSelectClub,
    clubOptions,
    smsNumber,
    setSmsNumber,
    onChangeSmsNumber,
}) => {
    const [clubfilter, setclubfilter] = useState(0)
    const [datefilter, setdatefilter] = useState({startTime:0, endTime:0})
    const [pageType, setPageType] = useState('paymentResult')

    let clubList = [{label:'전체', value:0}];

    for (let i = 0; i < smsPaymentList.length; i++) {
        let skip = false;
        for (let j = 0; j < clubList.length; j++) {
            if(clubList[j].value == smsPaymentList[i].clubID)
            {
                skip = true;
                break;
            }
        }

        if(!skip)
        {
            clubList.push({label:smsPaymentList[i].clubName, value:smsPaymentList[i].clubID});
        }
    }

    let dateList = [
        {label:'전체', value:{startTime:0, endTime:0}}
    ]
    let today = new Date(Date.now())
    today.setHours(0,0,0,0)
    today.setDate(1)
    let startTime = Number(today.getTime()/1000)
    today.setMonth(today.getMonth()+1)
    today.setDate(today.getDate()-1)
    let endTime = Number(today.getTime()/1000)
    dateList.push({label:`${today.getMonth()+1}월`, value:{endTime, startTime}})

    today.setDate(1)
    for (let i = 0; i < 11; i++) {
        endTime = Number(today.getTime()/1000)
        today.setMonth(today.getMonth() - 1)
        startTime = Number(today.getTime()/1000)
        dateList.push({label:`${today.getMonth()+1}월`, value:{endTime, startTime}})
    }

    const onClickPeriod = (period)=>{
        let tempdate = new Date()

        let startTime = 0;
        let endTime = parseInt(tempdate.getTime()/1000);
        if(period == 'month')
        {
            tempdate.setDate(1)
            tempdate.setHours(0, 0, 0, 0)
            startTime = parseInt(tempdate.getTime()/1000);
        }
        else if(period == '3months')
        {
            tempdate.setDate(1)
            tempdate.setHours(0, 0, 0, 0)
            tempdate.setMonth(tempdate.getMonth() - 2)
            startTime = parseInt(tempdate.getTime()/1000);
        }
        else if(period == '6months')
        {
            tempdate.setDate(1)
            tempdate.setHours(0, 0, 0, 0)
            tempdate.setMonth(tempdate.getMonth() - 5)
            startTime = parseInt(tempdate.getTime()/1000);
        }
        
        setdatefilter({startTime, endTime})
    }

    if(datefilter.startTime == 0 && datefilter.endTime == 0)
    {
        onClickPeriod('6months')
    }

    const changeDateFormat = (timestamp) => {
        const date = new Date(timestamp * 1000);
        const year1 = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        const dateStr = `${year1}-${month}-${day}`;

        return dateStr
    }
    
  return (
    <>
        <TabBlock >
            <div style={{ display: 'flex' }}>
                <TabText
                    onClick={() => setPageType('paymentResult')}
                    active={pageType === 'paymentResult'}
                >
                    충전 내역
                </TabText>
                <TabText
                    onClick={() => setPageType('numberRegist')}
                    active={pageType === 'numberRegist'}
                >
                    발신번호 등록 & 변경
                </TabText>
            </div>
        </TabBlock>
        {
            pageType === 'paymentResult'?
            <div>
                <div style={{background:'#F0F0F0', padding:'30px', margin:'30px'}}>
                    <div style={{fontWeight:600, paddingBottom:'10px'}}>현재 알리고 문자 남은 포인트</div>
                    <div style={{paddingBottom:'10px'}}>{inputNumberAutoComma(Math.floor(aligoPoint.smsCount * 8.4))}P</div>
                    <div>단문 : {inputNumberAutoComma(aligoPoint.smsCount)}건</div>
                    <div>장문 : {inputNumberAutoComma(aligoPoint.lmsCount)}건</div>
                    <div>그림 : {inputNumberAutoComma(aligoPoint.mmsCount)}건</div>
                </div>
                <div style={{padding:'12px 12px 30px 30px', display:'flex'}}>
                    <TextField
                        id="startTime"
                        label="시작일"
                        name="startTime"
                        type="date"
                        value={changeDateFormat(datefilter.startTime)}
                        onChange={(e) =>{
                            let date = new Date(e.target.value)

                            date.setHours(0, 0, 0, 0)

                            setdatefilter({startTime:parseInt(date.getTime()/1000), endTime:datefilter.endTime})
                        }}
                        InputLabelProps={{
                            shrink: true,
                    }}
                    />
                    <TextSpace>~</TextSpace>
                    <TextField
                        id="endTime"
                        label="종료일"
                        name="endTime"
                        type="date"
                        value={changeDateFormat(datefilter.endTime)}
                        onChange={(e) =>{
                            let date = new Date(e.target.value)

                            date.setHours(23, 59, 59)

                            setdatefilter({startTime:datefilter.startTime, endTime:parseInt(date.getTime()/1000)})
                        }}
                        InputLabelProps={{
                            shrink: true,
                    }}
                    />
                    <div style={{width:'20px'}}/>
                    <ButtonGroup
                        color="primary"
                        aria-label="outlined primary button group"
                    >
                        <Button onClick={() => onClickPeriod('month')}>1달</Button>
                        <Button onClick={() => onClickPeriod('3months')}>3달</Button>
                        <Button onClick={() => onClickPeriod('6months')}>6달</Button>
                        <Button onClick={() => onClickPeriod('')}>전체</Button>
                    </ButtonGroup>
                </div>
                <div style={{padding: '0 20px', marginBottom: '20px', marginTop: '20px'}}>
                    <div style={{width: '100%', display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
                        <div style={{width: '50px'}}>클럽 : </div>
                        <Select
                            options={clubList}
                            onChange={(e)=>{
                                setclubfilter(e.value)
                            }}
                            defaultValue={0}
                            isSearchable={false}
                            //style={{width:'250px'}}
                            styles={{ 
                                container: provided => ({...provided, width: '400px', padding: 0, border: 0})
                            }}
                            height="450px"
                            aria-labelledby="select"
                            placeholder="클럽 선택"
                            maxMenuHeight={'450px'}
                        />
                        {/* <div style={{width: '50px', marginLeft:'50px'}}>기간 : </div>
                        <Select
                            options={dateList}
                            onChange={(e)=>{
                                setdatefilter(e.value)
                            }}
                            defaultValue={{startTime:0, endTime:0}}
                            isSearchable={false}
                            //style={{width:'250px'}}
                            styles={{ 
                                container: provided => ({...provided, width: '400px', padding: 0, border: 0})
                            }}
                            height="450px"
                            aria-labelledby="select"
                            placeholder="기간 선택"
                            maxMenuHeight={'450px'}
                        /> */}
                    </div>
                </div>
                <div style={{padding: '0 20px', marginBottom: '20px', marginTop: '100px'}}>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px'}}>
                        <div style={{width: '300px'}}>문자 결제 내역</div>
                        <CommenButton
                            onClick={() => downloadExcel('문자 결제 내역', 'smsPaymentResult')}
                        >{`Excel 다운로드`}</CommenButton>
                    </div>
                </div>
                <Table className="smsPaymentResult">
                    <thead>
                        <tr>
                        <td>클럽 이름</td>
                        <td>구매일</td>
                        <td>결제 금액</td>
                        <td>충전 포인트</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            smsPaymentList.map((dateData, idx1)=>{
                                if(clubfilter != 0 && dateData.clubID != clubfilter) return null;

                                if(datefilter.startTime != 0 && 
                                    (dateData.registTime < datefilter.startTime || dateData.registTime > datefilter.endTime) ) return null;
                                return (
                                <tr
                                    key={idx1}
                                    style={{ borderTop: `1px solid ${palette.webGray[17]}`}}
                                >
                                    <td>{dateData.clubName}</td>
                                    <td>{dateToString(dateData.registTime)}</td>
                                    <td>{inputNumberAutoComma(dateData.price)}원</td>
                                    <td>{inputNumberAutoComma(dateData.base+dateData.bonus)}P</td>
                                </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
            :
            pageType == 'numberRegist'?
            <div style={{padding: '0 20px', marginBottom: '20px'}}>
                <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px'}}>
                    <div style={{width: '300px'}}>클럽 선택</div>
                    <div style={{flex: 1}}>
                        <Select
                            options={clubOptions}
                            onChange={setSelectClub}
                            placeholder="클럽 선택"
                            styles={customStyles}
                            isSearchable={false}
                            aria-labelledby="select"
                            value={selectClub}
                            width="250px"
                        />
                    </div>
                </div>
                <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px'}}>
                    <div style={{width: '300px'}}>{'발신번호'}</div>
                    <div style={{flex: 1}}><input value={smsNumber} onChange={(e) => setSmsNumber(e.target.value)} style={{width: '400px', border: '1px solid #ccc', borderRadius: '.2rem', padding: '12px 16px', fontSize: '.95rem'}} type="text"/></div>
                </div>
                <div style={{width: '100%', display: 'flex', justifyContent: 'end', marginBottom: '15px'}}>
                    <button onClick={() => onChangeSmsNumber()} style={{backgroundColor: palette.webCyan[5], color:'#fff', border: '1px solid rgba(0, 0, 0, .2)', borderRadius: '.2rem', padding: '10px 20px', cursor: 'pointer', fontSize: '1.2rem'}}>
                        발신번호 등록 & 변경
                    </button>
                </div>
            </div>
            :null
        }
        <Line/>
    </>
  );
};

export default React.memo(AdminClubSms);
