import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { AutoSizer, List } from 'react-virtualized';
import Slider from '@material-ui/core/Slider';
import ColorPicker, { useColor } from "react-color-palette";
import { GrFormClose } from 'react-icons/gr';
import { GoSearch } from 'react-icons/go';
import { VscTriangleLeft, VscTriangleRight } from 'react-icons/vsc';
import palette from '../../../lib/styles/palette';
import { inputNumberAutoComma } from '../../../lib/commonFunc/commonFunc';

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const AddMemberModalWrap = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1200px) {
    width: 100%;
    height: 100%;
  }
`;

const AddMemberBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;
  

  @media screen and (max-width: 1200px) {
      position: absolute;
      top: 180px;
  }
`;

const AddMemberBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 10px 30px 20px 30px;
`;

const BtnAddMemberConfirm = styled.button`
  width: 330px;
  padding: 10px 10px 10px 10px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-right: 20px;

  &:focus {
    outline: none;
  }
`;

const BtnAddMemberClose = styled.button`
width: 120px;
  padding: 10px 10px 10px 10px;
background-color: ${palette.webGray[5]};
color: #fff;
font-size: 1.2rem;
border: 0;
border-radius: .2rem;
cursor: pointer;

&:focus {
  outline: none;
}
`;

const AddMemberTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const AddMemberContentBox = styled.div`
  width: 100%;
  height: auto;
  padding: 25px 30px 20px 30px;
`;

const TableBox = styled.div`
  width: 100%;
  height: 350px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[11]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;
const Table = styled.table`
  font-family: 'Noto Sans KR', sans-serif;

  td {
    padding: 5px 5px 30px 30px;
  }

`;

const SmsNumberInfoModal = ({
    visible, 
    closeModal
}) => {
    if(!visible) return false;

    return(
      <AddMemberModalWrap>
          <Background />
          <AddMemberBox>
              <AddMemberTitle>발신 번호 등록</AddMemberTitle>
              <AddMemberContentBox>
                <div style={{flex:1, justifyContent:'center', alignItems:'flex-start', padding:'20px'}}>
                    <div style={{fontSize:'1.8rem'}}><span style={{fontWeight:600}}>발신번호 등록 및 변경은 서류인증을 통해서만 가능합니다.</span></div>
                    <div style={{fontSize:'1.2rem', paddingTop:'20px', paddingBottom:'50px'}}>- 서류를 먼저 준비한 후 트랩 고객센터로 문의 바랍니다.</div>
                    <div style={{display:'flex', width:'100%'}}>
                        <div>
                            <div style={{fontSize:'1.8rem', paddingTop:'30px', fontWeight:600}}>1. 통신서비스 이용증명원</div>
                            <div style={{fontSize:'1.4rem', paddingTop:'10px'}}> - 아래 발급처를 참고하시어 등록하려는 발신번호가 가입된 통신사를 통해 서류를 발급받아 제출 바랍니다.</div>
                            <div style={{fontSize:'1.4rem', paddingTop:'10px'}}> - KT : 국번 없이 100 또는 080-000-1618</div>
                            <div style={{fontSize:'1.4rem', paddingTop:'10px'}}> - SK : 080-816-2000 또는 1600-2000</div>
                            <div style={{fontSize:'1.4rem', paddingTop:'10px'}}> - LG : 국번 없이 101 또는 1544-0001</div>
                            <div style={{fontSize:'1.8rem', paddingTop:'30px', fontWeight:600}}>2. 신분증</div>
                            <div style={{fontSize:'1.4rem', paddingTop:'10px'}}> - <span style={{fontWeight:600}}>대표님의 신분증 사본</span>이 필요합니다. <span style={{fontWeight:600}}>주민번호 뒷자리는 가려서 </span>보내주세요. </div>
                            <div style={{fontSize:'1.8rem', paddingTop:'30px', fontWeight:600}}>3. 발신번호 사전등록 신청서</div>
                            <div style={{fontSize:'1.4rem', paddingTop:'10px'}}> - 발신번호 사전등록 신청서  <a style={{color:palette.webCyan[5]}} href={'https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/%EB%B0%9C%EC%8B%A0%EB%B2%88%ED%98%B8_%EC%82%AC%EC%A0%84%EB%93%B1%EB%A1%9D_%EB%8C%80%EB%A6%AC_%EC%8B%A0%EC%B2%AD%EC%84%9C.docx'} download target="_blank" rel="noopener noreferrer">다운로드</a> 받으신 후 작성하여 jpg, jpge, pdf, png, zip, gif, bmp, tif, tiff 파일형식으로 준비해 주세요.</div>
                            <div style={{fontSize:'1.8rem', paddingTop:'30px', fontWeight:600}}>4. 사업자 등록증</div>
                            <div style={{fontSize:'1.4rem', paddingTop:'10px'}}> - 잘린부분이 없도록 준비해 주세요.</div>
                            <div style={{fontSize:'1rem', paddingTop:'50px', color:'#646464'}}>{'전기통신사업법 제84조의2 (전화번호의 거짓표시 금지 및 이용자 보호) 및 거짓으로 표시된 전화번호로 인한 이용자의 피해 예방 등에 관한 고시 '}</div>
                            <div style={{fontSize:'1rem', paddingTop:'5px', color:'#646464'}}>{'제9조(인터넷발송 문자메시지 발신번호 사전등록) 의거하여 이용자 피해 예방을 위해 사전에 등록한 발신번호 및 본인인증절차를 거진 후 서비스를 제공합니다.'}</div>
                        </div>
                    </div>
                </div>
              </AddMemberContentBox>
              <AddMemberBtnBox>
                  <BtnAddMemberClose onClick={() => {
                    closeModal()
                  }}>닫기</BtnAddMemberClose>
              </AddMemberBtnBox>
          </AddMemberBox>
      </AddMemberModalWrap>
    )
};

export default SmsNumberInfoModal;
