import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import {
  timeValueToDate,
  inputNumberAutoComma,
  sort,
  sortReverse,
} from '../../lib/commonFunc/commonFunc';
import Pagination from '@material-ui/lab/Pagination';
import palette from '../../lib/styles/palette';
import DeleteModal from '../common/DeleteModal'
import ItemTypes from '../prescription/ItemTypes';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import { RiSettings3Line } from 'react-icons/ri';

const MobileProductContainer = styled.div`
    width: 100%;
    height: fit-content;
`;

const MobileProductBox = styled.div`
    width: 100%;
    height: fit-content;

    &:nth-child(1) {
        margin-bottom: 50px;
    }
`;

const TitleBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

const Title = styled.div`
    font-weight: bold;
    font-size: 1.1rem;
`;

const BtnAddMobileProduct = styled.button`
    border: 0;
    background-color: ${palette.webCyan[5]};
    border-radius: .2rem;
    color: #fff;
    font-weight: bold;
    font-size: 1rem;
    width: 110px;
    height: 35px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
        outline: none;
    }

    svg {
        font-size: 1.5rem;
        color: #4d4d4d;
    }
`;

const MobileProductListTop = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    background-color: #f8f8f8;
    border: 1px solid ${palette.webGray[16]};
    border-top-right-radius: .5rem;
    border-top-left-radius: .5rem;
    border-bottom: 0;
    padding: 16px 0;
    font-weight: bold;
    & .MobileProductImage {
        width: 15%;
    }
    & .MobileProductInfo {
        width: 50%;
    }
    & .btnBox {
        width: 20%;
    }
    & .sortNum {
        width: 15%;
    }
`;

const MobileProductList = styled.ul`
    border: 1px solid ${palette.webGray[16]};
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
`;

const MobileProductNullBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    color: ${palette.webGray[8]};
    font-size: .95rem;
`

const MobileProductItem = styled.li`
    display: flex;
    align-items: center;
    position: relative;
    text-align: center;
    padding: 20px 0;
    border-top: 1px solid ${palette.webGray[16]};
    cursor: pointer;

    &:hover {
        background-color: ${palette.webGray[17]};
    }
    

    & .background {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: -1;
    }
    
    & {
        z-index: 3;
    }

    &:nth-child(1) {
        border-top: 0;
    }

    & .sortNum {
        width: 15%;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        & .num {
            margin-right: 10px;
            font-size: 1.2rem;
            font-weight: bold;
        }

        & .btnSort {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            & svg {
                font-size: 1.4rem;
                color: ${palette.webCyan[5]};
                cursor: pointer;
            }
        }
    }
    & .MobileProductImage {
        width: 15%;
        height: 100%;
        text-align: center;
        padding: 0 10px;

        & img {
            width: 50%;
            padding-bottom: 0%;
            object-fit: contain;
        }
    }
    & .MobileProductInfo {
        width: 50%;
        height: 100%;
        padding: 0 10px 0 50px;

        & .sort {
            display: flex;
            align-items: center;
            height: 100%;

            & div {
                padding: 3px 0;
            }

            & .block {
                padding: 0;
                height: 20px;
                width: 8px;
                border-radius: .1rem;
                background-color: ${palette.webCyan[5]};
                margin-right: 10px;
            }

            & .sub {
                width: 100px;
                text-align: left;
                font-size: .95rem;
                font-weight: bold;
            }
            
            & .content {
                font-size: .95rem;
            }
        }
    }
    & .btnBox {
        width: 20%;
        padding: 0 10px;

        & .using {
            padding: 5px 0;
            border-radius: .2rem;
            border: 1px solid ${palette.webGray[5]};
            width: 70px;
            background-color: #fff;
            margin-right: 20px;
            cursor: pointer;
            color: #000;

            &:focus {
                outline: none;
            }
        }

        & .unusing {
            padding: 5px 0;
            border-radius: .2rem;
            border: 1px solid ${palette.webGray[5]};
            width: 100px;
            background-color: #fff;
            margin-right: 20px;
            cursor: pointer;
            color: #000;

            &:focus {
                outline: none;
            }
        }

        & .delete {
            padding: 5px 0;
            border-radius: .2rem;
            border: 0;
            width: 70px;
            background-color: ${palette.webGray[5]};
            cursor: pointer;
            color: #fff;

            &:focus {
                outline: none;
            }
        }
    }
`;

const SettingModalControl = (ref, viewOpenMore, modal) => {
    function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
            viewOpenMore(!modal);
        }
    }

    useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
    };
    });
};

const MobileProductManagement = ({onClickRegist, mobileProductState, onChangeMobileProductState, onChangeProductOrder, mobileProductList, onDeleteMobileProduct, deleteProduct, setDeleteProduct, onChangeIsActive, onClickMobileProduct}) => {
    const [deleteModal, setDeleteModal] = useState(false)
    const [settingModal, setSettingModal] = useState(false)
    const [activeItemPage, setActiveItemPage] = useState(1)
    const [itemPage, setItemPage] = useState(1)
    const settingRef = useRef(null);
    
    const activeAlert = () => alert("판매중인 상품은 수정이 불가능합니다.")

    const decodeOption = (optionJson) => {
        if(optionJson) {
            let temp = JSON.parse(optionJson)

            if(temp && temp.length > 0){
                let option = temp.map(item => {return item.name}).join(", ")
                return option
            } else {
                return "-"
            }
        } else {
            return "-"
        }
    }

    SettingModalControl(settingRef, setSettingModal, settingModal);

    return(
        <>
        <MobileProductContainer>
            <MobileProductBox>
                <TitleBox style={{alignItems: 'flex-end'}}>
                    <Title>판매중인 상품</Title>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {mobileProductState === 0 && <div style={{marginRight: '20px', fontWeight: 'normal', width: '300px', padding: '7px 10px', border: '1px solid #c4c4c4', borderRadius: '.2rem', fontSize: '.75rem', color: palette.webCyan[5], backgroundColor: '#fff'}}>모바일 상품 미사용중입니다.<br/>사용하려면 설정에서 사용 상태로 변경해주세요.</div>}
                        <BtnAddMobileProduct onClick={() => onClickRegist()}>상품 등록</BtnAddMobileProduct>
                        <div style={{position: 'relative'}}>
                            <BtnAddMobileProduct onMouseDown={() => {setSettingModal(!settingModal)}} style={{position: 'relative', width: '35px', marginLeft: '20px', border: '1px solid #cccccc', backgroundColor: '#fff'}}>
                                <RiSettings3Line />
                            </BtnAddMobileProduct>
                            {settingModal &&
                                <div ref={settingRef} className="setting-box" style={{zIndex: 4, backgroundColor: '#fff', boxShadow: '0px 5px 5px 0px rgba(0, 0, 0, 0.1)', position:'absolute', width: '1160px', top: '110%', right: 0,backgroundColor: '#fff', borderRadius: '.2rem', border: '1px solid #cccccc', padding: '14px 20px'}}>
                                    <div style={{paddingLeft: '10px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '20px'}}>
                                        <div style={{t: '20px', fontWeight: 'bold', marginRight: '30px', fontSize: '.95rem'}}>모바일 상품 판매</div>
                                            {mobileProductState === 1 ?
                                                <div style={{color: palette.webCyan[5], marginRight: '30px', fontWeight: 'bold'}}>사용중</div>
                                                :
                                                <div style={{color: "#A6A6A6", marginRight: '30px', fontWeight: 'bold'}}>미사용중</div>
                                            }
                                            <button onClick={() => onChangeMobileProductState()} style={{borderRadius: '.2rem', border: '1px solid #cccccc', backgroundColor: '#fff', cursor: 'pointer',fontSize: '.9rem', color: '#4d4d4d', padding: '4px 10px'}}>{mobileProductState ? "사용 중단" : "사용"}</button>
                                        </div>
                                        <div style={{width: '100%'}}>
                                            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px', fontSize: '.93rem'}}>
                                                <div style={{flex: 1, border: `2px solid ${palette.webCyan[5]}`, overflow:'hidden', backgroundColor: '#fff', padding: '10px 16px', borderRadius: '.2rem'}}>
                                                    <div style={{marginBottom:'14px'}}>* <b>결제 수수료</b> : <span style={{fontWeight: 'bold'}}>4.5%</span> <span style={{fontSize: '.9rem'}}>(결제 수수료는 금융사 및 인터넷 결제 PG사 등의 수수료이며, 모바일 상품 판매 금액에서 결제 수수료를 제외한 금액을 위 정산 계좌로 입금합니다.)</span></div>

                                                    <div style={{lineHeight: '150%', marginBottom:'14px'}}>
                                                        <div style={{lineHeight: '150%', marginBottom:'5px'}}>* <b>정산 규정</b> : 매월 3회 모바일 매출 정산이 이뤄집니다. 정산일은 1일, 11일, 21일이며 정산일이 영업일이 아닌 경우 익일 정산됩니다.</div>
                                                        <div style={{paddingLeft: '80px'}}>ㆍ<b>1일~10일에 발생한 매출</b> : 같은달 21일에 정산</div>
                                                        <div style={{paddingLeft: '80px'}}>ㆍ<b>11일~20일에 발생한 매출</b> : 다음달 1일에 정산</div>
                                                        <div style={{paddingLeft: '80px'}}>ㆍ<b>21일~말일에 발생한 매출</b> : 다음달 11일에 정산</div>
                                                    </div>

                                                    <div style={{lineHeight: '150%'}}>
                                                        <div style={{marginBottom: '7px', color: palette.webCyan[5]}}><b>[ 환불 규정 ]</b></div>
                                                        <div><b>① 앱 환불</b> : 회원이 앱에서 모바일 회원권 결제 7일 이내 이면서 운동 시작일 전일 경우에만 앱에서 환불 처리합니다.</div>
                                                        <div><b>② 현장 환불</b> : <span style={{color: palette.webCyan[5]}}>결제일로부터 8일 이후 또는 운동 시작일부터는 현장에서 클럽 환불 정책에 맞게 직접 환불 처리해야만 합니다.</span></div>
                                                        {/* <div><b>③ 환불 관련 예시</b></div>
                                                        <div style={{paddingLeft: '15px'}}>ㆍ<b>ex) 대기 상태의 모바일 회원권 구매 후 7일 이내 환불 요청</b> : 트랩에서 전액 환불 <span style={{fontSize: '.9rem'}}>(구매 취소된 회원권은 고지 및 정산하지 않음)</span></div>
                                                        <div style={{paddingLeft: '15px'}}>ㆍ<b>ex) 대기 상태의 모바일 회원권 구매 후 8일 이후 환불 요청</b> : 클럽 환불 규정에 따라 현장에서 환불 <span style={{fontSize: '.9rem'}}>(회원권 판매금액은 정산일에 입금)</span></div>
                                                        <div style={{paddingLeft: '15px'}}>ㆍ<b>ex) 회원권이 유효 상태일 때 : 클럽 환불 규정에 따라 현장에서 환불</b> <span style={{fontSize: '.9rem'}}>(회원권 판매금액은 정산일에 입금)</span></div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            }
                        </div>
                    </div>
                </TitleBox>
                <MobileProductListTop>
                    <li className="sortNum">순서</li>
                    <li className="MobileProductImage">대표 사진</li>
                    <li className="MobileProductInfo">상품 정보</li>
                    <li className="btnBox"></li>
                </MobileProductListTop>
                <MobileProductList>
                    {mobileProductList.filter(item => {return item.isActive == 1}).length > 0 ?
                    mobileProductList.filter(item => {return item.isActive == 1}).map((item, idx) => {
                    if(activeItemPage * 10 >= idx + 1 && idx >= (activeItemPage - 1) * 10 ) {
                    return(
                        <MobileProductItem key={idx}>
                            <div onClick={() => onClickMobileProduct(item)} className="background"></div>
                            <div className="sortNum">
                                <div className="num">{item.order}</div>
                                <div className="btnSort">
                                    {mobileProductList.filter(item => {return item.isActive})[0].clubOnlineSalesItemID !== item.clubOnlineSalesItemID && <TiArrowSortedUp onClick={() => onChangeProductOrder(item.clubOnlineSalesItemID, -1)}/>}
                                    {mobileProductList.filter(item => {return item.isActive})[mobileProductList.filter(item => {return item.isActive}).length - 1].clubOnlineSalesItemID !== item.clubOnlineSalesItemID && <TiArrowSortedDown onClick={() => onChangeProductOrder(item.clubOnlineSalesItemID, 1)}/>}
                                </div>
                            </div>
                                <div  onClick={() => {onClickMobileProduct(item)}} className="MobileProductImage"><img src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${item.mainImage}`}/></div>
                            <div  onClick={() => {onClickMobileProduct(item)}} className="MobileProductInfo">
                                <div className="sort">
                                    <div className="sub">제목</div>
                                    <div className="content" style={{color: palette.webCyan[5], fontWeight: 'bold'}}>{item.title}</div>
                                </div>
                                <div className="sort">
                                    <div className="sub">회원권</div>
                                    <div className="content">{item.subTitle}</div>
                                </div>
                                <div className="sort">
                                    <div className="sub">판매 가격</div>
                                    <div className="content">{inputNumberAutoComma(item.salePrice)}원 {item.salePrice !== item.originPrice && <span>(할인 전 가격 : {item.originPrice}원 / 할인률 : {100 - Math.round((item.salePrice / item.originPrice * 100))}%)</span>}</div>
                                </div>
                                <div className="sort">
                                    <div className="sub">판매 옵션</div>
                                    <div className="content">{decodeOption(item.option)}</div>
                                </div>
                            </div>
                            <div className="btnBox">
                                <button style={{margin: 0}} onClick={() => onChangeIsActive(item.clubOnlineSalesItemID, 0)} className="unusing">비활성화</button>
                            </div>
                        </MobileProductItem>
                    )}}) : <MobileProductNullBox>판매중인 상품이 없습니다.</MobileProductNullBox>}
                </MobileProductList>
            </MobileProductBox>
            <div style={{display: 'flex', width: '100%', marginTop: '40px', marginBottom: '80px',justifyContent: 'center'}}>
                <Pagination 
                    count={Math.ceil(mobileProductList.filter(item => {return item.isActive == 1}).length / 10)} 
                    page={activeItemPage}
                    size="large"
                    showFirstButton 
                    showLastButton
                    onChange={(event, value) => {if(value !== activeItemPage) {
                        setActiveItemPage(value)
                    }}}
                />
            </div>
            <MobileProductBox>
                <TitleBox>
                    <Title>미판매중인 상품</Title>
                </TitleBox>
                <MobileProductListTop>
                    <li className="sortNum">순서</li>
                    <li className="MobileProductImage">대표 사진</li>
                    <li className="MobileProductInfo">상품 정보</li>
                    <li className="btnBox"></li>
                </MobileProductListTop>
                <MobileProductList>
                    {mobileProductList.filter(item => {return item.isActive == 0}).length > 0 ?
                    sortReverse(mobileProductList.filter(item => {return item.isActive == 0}), "title").map((item, idx) => {
                    if(itemPage * 10 >= idx + 1 && idx >= (itemPage - 1) * 10 ) {
                    return(
                        <MobileProductItem key={idx}>
                            <div onClick={() => {onClickMobileProduct(item)}} className="background"></div>
                            <div className="sortNum">
                                <div className="num">-</div>
                            </div>
                                <div  onClick={() => {onClickMobileProduct(item)}} className="MobileProductImage"><img src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${item.mainImage}`}/></div>
                            <div  onClick={() => {onClickMobileProduct(item)}} className="MobileProductInfo">
                                <div className="sort">
                                    <div className="sub">제목</div>
                                    <div className="content" style={{fontWeight: 'bold', color: palette.webCyan[5]}}>{item.title}</div>
                                </div>
                                <div className="sort">
                                    <div className="sub">회원권</div>
                                    <div className="content">{item.subTitle}</div>
                                </div>
                                <div className="sort">
                                    <div className="sub">판매 가격</div>
                                    <div className="content">{inputNumberAutoComma(item.salePrice)}원 {item.salePrice !== item.originPrice && <span>(할인 전 가격 : {item.originPrice}원 / 할인률 : {100 - Math.round((item.salePrice / item.originPrice * 100))}%)</span>}</div>
                                </div>
                                <div className="sort">
                                    <div className="sub">판매 옵션</div>
                                    <div className="content">{decodeOption(item.option)}</div>
                                </div>
                            </div>
                            <div className="btnBox">
                                <button onClick={() => onChangeIsActive(item.clubOnlineSalesItemID, 1)} className="using">활성화</button>
                                <button onClick={() => {
                                    setDeleteProduct(item)
                                    setDeleteModal(true)
                                }} className="delete">삭제</button>
                            </div>
                        </MobileProductItem>
                    )}}) : <MobileProductNullBox>미판매중인 상품이 없습니다.</MobileProductNullBox>}
                </MobileProductList>
            </MobileProductBox>
            <div style={{display: 'flex', width: '100%', marginTop: '40px', justifyContent: 'center'}}>
                <Pagination 
                    count={Math.ceil(mobileProductList.filter(item => {return item.isActive == 0}).length / 10)} 
                    page={itemPage}
                    size="large"
                    showFirstButton 
                    showLastButton
                    onChange={(event, value) => {if(value !== itemPage) {
                        setItemPage(value)
                    }}}
                />
            </div>
        </MobileProductContainer>
        <DeleteModal
        text={`${deleteProduct.salesType == 100?'구독 상품을 삭제하면 기존 구독회원의 구독이 정지됩니다.\n\n':''}${deleteProduct.title} 상품을 삭제하시겠습니까?`}
        modal={deleteModal}
        onClick={() => {
            onDeleteMobileProduct();
            setDeleteModal(false);
        }}
        mb={deleteProduct.salesType == 100?50:10}
        setModal={() => {
            setDeleteModal(false)
            setDeleteProduct({})
        }}
        />
        </>
    )
};

export default MobileProductManagement;

