import autosize from 'autosize';
import React from 'react';
import styled from 'styled-components';
import palette from '../../../lib/styles/palette';
import { AiOutlineRight } from 'react-icons/ai';
import linkPageGuideImage1 from '../../../resources/images/appGuide/링크 페이지 목록.jpg';
import linkPageGuideImage2 from '../../../resources/images/appGuide/링크 페이지 제작.jpg';

const Wrap = styled.div`
    width: 100%;
`;

const Part = styled.div`
    width: 100%;
    margin-bottom: 50px;
    padding-top: 50px;
    border-top: 1px solid rgba(0,0,0,.35);

    &:nth-child(1) {
        border-top: 0;
        padding-top: 0px;
    }
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 30px;
`;

const TextContent = styled.div`
    font-size: 16px;
    line-height: 150%;    
    word-break: keep-all;
    width: 100%;
`;

const HowToUse = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    margin-bottom: 50px;
`;

const InfoBox = styled.div`

`;

const ImageBox = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
`;

const IntroBox = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 50px;
`;

const GuideBox = styled.div`
    width: 100%;
`;

const GuideImageBox = styled.div`
    width: 1200px;
    height: 600px;
    background-color: #fff;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.05);
`;

const GuideImage = styled.img`
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
`;

const IntroDescBox = styled.div`
    flex: 1;
`;

const IntroDescTop = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

`;

const IntroDescTitle = styled.div`
    font-weight: bold;
    font-size: 16px;
`;

const BtnIntroMore = styled.button`
    display: flex;
    align-items: center;
    border: 0;
    background-color: #fff;
    cursor: pointer;
    color: rgba(0,0,0, .45);
    font-weight: bold;
    
    & svg {
        padding-top: 1px;
    }
`;

const AppGuideLinkPage = () => {
    return(
        <Wrap>
            <Part>
                <Title>링크 페이지 제작 기능이란?</Title>
                <TextContent>
                트랩 앱에서 사용자가 배너를 클릭하거나 알림 메세지의 바로가기 버튼을 통해 접속할 수 있는 이벤트 페이지, 공지사항 페이지, 홍보페이지와 같은 특정 페이지를 제작하는 기능입니다.
                <br />링크 페이지 제작 방식은 글을 입력하면 양식에 맞추어 화면이 구성되는 고정형 제작 방식과 일반 블로그처럼 자유롭게 글을 쓰고 글씨 크기, 색상 등을 수정하는 자유형 방식이 있습니다.
                </TextContent>
            </Part>
            <Part>
                <Title>링크 페이지 목록</Title>
                <GuideBox>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '100px'}}>
                        <GuideImageBox>
                            <GuideImage src={linkPageGuideImage1}/>
                        </GuideImageBox>
                        <TextContent style={{width: '1200px', marginTop: '30px'}}>
                        1. <b>링크페이지 제작 목록</b> : 제작한 링크페이지를 보여주는 목록입니다. 배너와 같은 다른 기능에서 링크 페이지를 연결하고 있으면 사용 중이라는 문구가 표시됩니다.
                        <br/>2. <b>미리 보기 화면</b> : 앱에서 사용자가 보게 될 화면을 예상하여 미리 보여주는 기능입니다.
                        </TextContent>
                    </div>
                </GuideBox>
                <Title>링크 페이지 제작</Title>
                <GuideBox>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <GuideImageBox>
                            <GuideImage src={linkPageGuideImage2}/>
                        </GuideImageBox>
                        <TextContent style={{width: '1200px', marginTop: '30px'}}>
                        1. <b>고정형 방식 & 자유형 방식</b> : 고정형 방식은 제목, 내용, 사진만 추가하면 정해진 양식에 맞추어 링크 페이지를 제작하는 기능이며, 자유형 방식은 블로그처럼 자유롭게 링크 페이지를 제작할 수 있는 기능입니다.
                        <br/>2. <b>사진 첨부 & 이미지 보관함</b> : 사진 첨부 기능을 이용하여 PC에 저장되어 있는 사진을 첨부 할 수 있고 이미지 보관함 기능을 이용하여 이미지 제작 기능을 이용해 제작한 이미지를 불러올 수 있습니다.
                        <br/>3. <b>미리 보기 화면</b> : 앱에서 사용자가 보게 될 화면을 예상하여 미리 보여주는 기능입니다.
                        <br/>4. <b>상품 추가</b> : 작성한 링크 페이지의 하단에 상품을 연결할 수 있는 기능입니다. 상품 홍보 페이지나 이벤트 페이지에서 사용하기 적합하며 회원이 상품을 누르면 해당 상품을 구매할 수 있는 상세보기 페이지로 이동합니다.
                        </TextContent>
                    </div>
                </GuideBox>
            </Part>
        </Wrap>
    )
};

export default AppGuideLinkPage;
