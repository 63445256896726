import React, { useEffect, useState, useRef } from 'react';
import SMS from '../../components/app/SMS';
import { useDispatch, useSelector } from 'react-redux';
import { 
    changeFormField,
    init,
    addImage,
    loadMember,
    loadMembership,
    selectMember,
    selectTempMember,
    selectMemberDelete,
    selectTempMemberDelete,
    initSelectedTempMembers,
    selectedTempMemberControl,
    memberTypeChange,
    changeImage,
    uploadImage,
    deleteMessage,
    loadData,
    membersChange,
    resetMembershipResult,
} from '../../modules/app/notificationMessage'
import qs from 'qs';
import { 
    loadTemplate,
} from '../../modules/app/template'
import client, { url } from '../../lib/api/client';
import { inputNumberAutoComma, selectMainMembership, selectMainMembershipState, sort, sortReverse, uploadImageToServer } from '../../lib/commonFunc/commonFunc';
import imageCompression from 'browser-image-compression'
import dotenv from 'dotenv'
dotenv.config()

const SMSContainer = ({ history }) => {
    const {
      club,
      socketKey,
      image,
      link,
      members,
      selectedMembers,
      selectedTempMembers,
      sortType,
      expiredMembers,
      readyMembers,
      availableMembers,
      saveRes,
      landingPageList,
      mobileProductList,
      templateList,
      loading,
      user,
      membershipResult
    } = useSelector(({ mobileProduct, template, landingPage, notificationMessage, club, user, socket, loading }) => ({
      club: club.defaultClub,
      socketKey: socket.socketKey,
      image: notificationMessage.image,
      link: notificationMessage.link,
      members: notificationMessage.members,
      selectedMembers: notificationMessage.selectedMembers,
      selectedTempMembers: notificationMessage.selectedTempMembers,
      expiredMembers: notificationMessage.expiredMembers,
      readyMembers: notificationMessage.readyMembers,
      mobileProductList: mobileProduct.mobileProductList,
      availableMembers: notificationMessage.availableMembers,
      sortType: notificationMessage.sortType,
      saveRes: notificationMessage.saveRes,
      loading: loading[`notificationMessage/LOAD_MESSAGE`],
      landingPageList: landingPage.landingPageList,
      templateList: template.templateList,
      user: user.user,
      membershipResult: notificationMessage.membershipResult,
    }));
    
    const dispatch = useDispatch()

    const fileInputRef = useRef(null);

    const [isDrag, setIsDrag] = useState(false)
    const [isADS, setIsADS] = useState(true)
    const [messageType, setMessageType] = useState("SMS")
    const [addMemberModal, setAddMemberModal] = useState(false)
    const [sendRecordModal, setSendRecordModal] = useState(false)
    const [confirmType, setConfirmType] = useState(1)
    const [pageSort, setPageSort] = useState(1);
    const [pageLink, setPageLink] = useState(null);
    const [deleteMsgList, setDeleteMsgList] = useState([]);
    const [deleteMode, setDeleteMode] = useState(false);
    const [isAllDelete, setIsAllDelete] = useState(false);
    const [paymentModal, setPaymentModal] = useState(false)
    const [smsInfo, setSmsInfo] = useState(null)
    const [priceList, setPriceList] = useState([])
    const [recordList, setRecordList] = useState([])
    const [selectedMessage, setSelectedMessage] = useState(null)
    
    const [paymentResultModal, setPaymentResultModal] = useState(false)
    const [paymentResultList, setPaymentResultList] = useState([])

    const [targetSex, setTargetSex] = useState(0)
    const [targetAgeMin, setTargetMin] = useState(null)
    const [targetAgeMax, setTargetMax] = useState(null)

    const [adsInfoModal, setAdsInfoModal] = useState(false)
    const [smsNumberModal, setSmsNumberModal] = useState(false)
    
    
    

    const [title, setTitle] = useState("")
    const [content, setContent] = useState("")

    const onCloseRecordModal = () => {
    }

    const onChangeField = (e) => {
        const {name, value} = e.target;
        dispatch(changeFormField({key: name, value}))
    }

    const onChangePageSort = (e) => {
        setPageSort(e.value);
        setPageLink(null)
    }

    const onLoadDetailMessage = (newselectedMessage) => {
        //
        if(selectedMessage && (selectedMessage.clubSmsID == newselectedMessage.clubSmsID)) {
            return;
        }
        
        setSelectedMessage(newselectedMessage)
    }

    const deleteImage = (index) => {
        let temp = []

        for (let i = 0; i < image.length; i++) {
            if(i != index) temp.push(image[i])
        }

        dispatch(changeImage({image: temp}))
    }

    const initInput = () => {
        setTitle("")
        setContent("")
    }
    
    const deleteAWSImage = (imageName) => {
    }

    const onDeleteMessage = (message) => {
        for (let i = 0; i < message.photoURL.length; i++) {
            if(message.photoURL[i] && message.photoURL[i].indexOf && message.photoURL[i].indexOf('template') == -1)
            {
                deleteAWSImage(message.photoURL[i])
            }
        }

        dispatch(deleteMessage({
            clubID: club.clubID,
            clubMessageID: message.clubMessageID,
            socketKey,
        }))
    }
    
    const uploadFile = async (file) => {
        if(!file) return false;
        if(!file[0]) return false;

        let imgNum = image.length;
        
        for (let i = 0; i < file.length; i++) {
            if((file[i].type).includes("image/")) {
                if(imgNum < 1) {
                    await resizeImg(file[i])

                    imgNum += 1;
                }
            }   else {
                alert("이미지 파일을 업로드 해주세요.")
                return false;
            }
        }
    }

    const uploadImageToAWS = async (imageFile, num) => {
        let _imageFile = club.clubID + "_notification" + "_" + Math.floor(Date.now() / 1000).toString() + "_" + num + '.jpg';

        let result = await uploadImageToServer(imageFile, _imageFile, 'clubdata/notification', imageFile.type);
        
        return _imageFile;
    }

    const onFileInputChange = (event) => {
        const { files } = event.target;
        uploadFile(files)
    }

    const onMemberTypeChange = (type) => {

        if(type != 1 && !membershipResult)
        {
            dispatch(
                loadMembership({
                  clubID: club.clubID,
                  socketKey,
                }),
              );
        }

        dispatch(memberTypeChange({type}))
    }

    const onTargetClick = () => {
        fileInputRef.current.click()
    }

    const onDrop = (files, event) => {
        setIsDrag(false)
        uploadFile(files)
    }
    
    const onLoadMember = () => {
        dispatch(memberTypeChange(0))
        dispatch(resetMembershipResult())
        dispatch(
            loadMember({
              clubID: club.clubID,
              page: 0,
              searchText: '',
              type: 'all',
              socketKey,
            }),
          );
    }

    const onSelectMemberDelete = (member) => {
        dispatch(selectMemberDelete({member}))
    }

    const onSelectTempMemberDelete = (member) => {
        dispatch(selectTempMemberDelete({member}))
    }

    const onSelectedTempMemberControl = (add, sohwMembers) => {
        let temp = []
        if(add) {
            for (let i = 0; i < selectedTempMembers.length; i++) {
                temp.push(selectedTempMembers[i])
            }
            for (let i = 0; i < sohwMembers.length; i++) {
                temp.push(sohwMembers[i])
            }
        }

        dispatch(selectedTempMemberControl({member: temp}))
    }

    const onSelectMember = () => {
        let temp = []
        for (let i = 0; i < selectedTempMembers.length; i++) {
            temp.push(selectedTempMembers[i])
        }
        dispatch(selectMember({member: temp}))
        setAddMemberModal(false);
    }

    const openModal = () => {
        let temp = []
        for (let i = 0; i < selectedMembers.length; i++) {
            temp.push(selectedMembers[i])
        }

        dispatch(selectTempMember({member: temp}))
        setAddMemberModal(true);
    }
    
    const closeModal = () => {
        dispatch(initSelectedTempMembers())
        setAddMemberModal(false);
    }

    const onSelectTempMember = (member) => {

        console.log('onSelectTempMember : ', member)
        dispatch(selectTempMember({member}))
    }

    const onloadData = (image, member, link, linkType, title, content) => {
        initPage()

        let imageArr = []
        for (let i = 0; i < image.length; i++) {
            if(image[i] && image[i] !== "") {
                imageArr.push(image[i]);
            }
        }

        dispatch(loadData({
            imageArr,
            member,
        }))
        setContent(content)
        setTitle(title)
        setPageSort(1)
        setPageLink(null)
    }

    const onSaveMessage = async (title, contents) => {
        let photoURL = ""

        // for (let i = 0; i < image.length; i++) {
        //     if(image[i] && !image[i].type) {                
        //         photoURL = photoURL.concat(image[i])
        //         if(i + 1 != image.length) {
        //             photoURL = photoURL.concat("%&")
        //         }
        //     }else {
        //         let imageName = await uploadImageToAWS(image[i], i)
        //         photoURL = photoURL.concat(`notification/${imageName}`)
        //         if(i + 1 != image.length) {
        //             photoURL = photoURL.concat("%&")
        //         }
        //     }
        // }

        if(image && image.length > 0) {
            if(image[0] && !image[0].type) { 
                photoURL = `https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/` + image[0]
            }
            else {
                let imageName = await uploadImageToAWS(image[0], 1)
                photoURL = `https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/notification/${imageName}`
            }
        }

        if(selectedMembers.length == 0) {
            alert("수신 회원을 선택해주세요.")
            return false;
        }

        let playerIDList = []

        for (let i = 0; i < selectedMembers.length; i++) {
            playerIDList.push(selectedMembers[i].playerID)
        }

        let clubSmsData = {
            title,
            text:isADS?(`(광고) ${club && club.name}\n`+contents+`\n${smsInfo?smsInfo.freeNagative:""}`):contents,
            photoURL,
            clubSmsID: "",
            messageType:messageType,
            registTime: Math.ceil(new Date().getTime() / 1000),
            clubID: club.clubID,
            memberList: playerIDList,
            price:0,
            count:0,
        }

        const queryString = qs.stringify({
            socketKey,
        });
        let res = await client.post(`${url}/api/sms/${club.clubID}?${queryString}`, {clubSmsData:clubSmsData});
        console.log('send sms : ', res.data)

        if(res.data && res.data.result == 1) {
            alert("문자 메세지를 전송했습니다.")
            dispatch(init())
            initInput()
        }
    }    

    const uploadMyImage = async (myImage) => {
        if(image.length < 1) {
          dispatch(uploadImage({image: myImage}));
        } else if(image.length === 1) {
          dispatch(uploadImage({image: myImage}));
        }
    }

    const resizeImg = async (img) => {
        let file = img;	// 입력받은 file객체
        
        // 이미지 resize 옵션 설정 (최대 width을 100px로 지정)
        const options = { 
            maxSizeMB: 0.256,
            maxWidthOrHeight: 640,
        }
        
        try {
            const compressedFile = await imageCompression(file, options);
            dispatch(addImage({image: compressedFile}));
          
        } catch (error) {
            alert("이미지 등록에 오류가 발생했습니다. 다시 시도해주세요.")
            console.log(error);
        }
    }

    const initPage = () => {
        dispatch(init())
        initInput()
        setPageLink(null)
        setPageSort(1)
        dispatch(
          loadMember({
            clubID: club.clubID,
            page: 0,
            searchText: '',
            type: 'all',
            socketKey,
          }),
        );
    }

    const getByteSize = (str)=>{
        let byteSize = 0;
  
        for (let i = 0; i < str.length; i++) {
          const charCode = str.charCodeAt(i);
          
          if (charCode <= 0x007F) {
            // ASCII 문자 (영문, 숫자, 공백 등): 1바이트
            byteSize += 1;
          } else {
            // 한글 및 기타 비-ASCII 문자: 2바이트
            byteSize += 2;
          }
        }
        
        return byteSize;
    }

    useEffect(() => {
        dispatch(init())
        loadSmsInfo()

        dispatch(loadTemplate({
            clubID: club.clubID,
            socketKey,
        }))
    }, []);

    useEffect(() => {
        
        const textSize = getByteSize(isADS?(`(광고) ${club && club.name}\n`+content+`\n${smsInfo?smsInfo.freeNagative:""}`):content);
        if(textSize > 90 || (image && image.length > 0)) {
            setMessageType((image && image.length > 0)?"MMS":"LMS")
        }
        else {
            setMessageType("SMS")
        }
        console.log("asdasd : ", content, messageType)
    }, [content, image, isADS]);


    useEffect(() => {
      if (club) {
        let temp = {availableMembers: [], expiredMembers: [], readyMembers:[]}

        for (let i = 0; i < members.length; i++) {
            if(members[i].membershipList && members[i].membershipList.length > 0) {
                let mainMembership
                let mainMembershipState

                mainMembership = selectMainMembership(members[i].membershipList, mainMembershipState);

                if(mainMembership) {
                    mainMembershipState = selectMainMembershipState(mainMembership)
                }

                if(mainMembershipState === "유효") {
                    temp.availableMembers.push(members[i])
                } else if(mainMembershipState === "만료") {
                    temp.expiredMembers.push(members[i])  
                } else if(mainMembershipState === "대기") {
                    temp.readyMembers.push(members[i])  
                }
            }
        }

        dispatch(membersChange({members: temp}))
      }
    }, [members]);

    const loadSmsInfo = async ()=>{
        const queryString = qs.stringify({
            socketKey,
        });
        let res = await client.get(`${url}/api/sms/${club.clubID}?${queryString}`);
        console.log('loadSmsInfo : ', res.data)
        setSmsInfo(res.data)
        let resPayment = await client.get(`${url}/api/sms/payment/${club.clubID}?${queryString}`);
        console.log('loadSmsInfo payment : ', resPayment.data)
        if(resPayment.data.result && resPayment.data.result == 1) {
            setPriceList(resPayment.data.infos)
            console.log('loadSmsInfo payment : ', resPayment.data.infos)
        }
    }
    const paymentResult = async (res) => {
        if (res.PCD_PAY_RST === 'success') {
            const queryString = qs.stringify({
                socketKey,
            });
            const dateString = res.PCD_PAY_TIME;
            const year = parseInt(dateString.substring(0, 4), 10);
            const month = parseInt(dateString.substring(4, 6), 10) - 1; // 월은 0부터 시작
            const day = parseInt(dateString.substring(6, 8), 10);
            const hour = parseInt(dateString.substring(8, 10), 10);
            const minute = parseInt(dateString.substring(10, 12), 10);
            const second = parseInt(dateString.substring(12, 14), 10);

            const date = new Date(year, month, day, hour, minute, second);

            // 초 단위 타임스탬프 (유닉스 타임스탬프)
            const registTime = Math.floor(date.getTime() / 1000);
            
            let resdata = await client.post(`${url}/api/sms/check/${club.clubID}?${queryString}`, {payOID:res.PCD_PAY_OID, registTime:registTime, price:res.PCD_PAY_TOTAL});

            console.log("res : ", res)
            console.log("resdata : ", resdata.data)
            if(resdata && resdata.data && resdata.data.result == 1) {
                setSmsInfo(resdata.data)
                alert(`충전 되었습니다.`)
            }
        } else {
            // 결제 실패일 경우 알림 메시지
            window.alert(res.PCD_PAY_MSG);
        }
    }

    const onPaymentSMS = (price)=>{

        const obj = {};
        /*
         *  공통 설정
         */
        obj.PCD_PAY_TYPE = 'card';			                                        // (필수) 결제 방법 (transfer | card)
        obj.PCD_PAY_WORK = 'PAY';			                                        // (필수) 결제요청 업무구분 (AUTH : 본인인증+계좌등록, CERT: 본인인증+계좌등록+결제요청등록(최종 결제승인요청 필요), PAY: 본인인증+계좌등록+결제완료)
        obj.PCD_CARD_VER = '02';		    // DEFAULT: 01 (01: 정기결제 플렛폼, 02: 일반결제 플렛폼), 카드결제 시 필수
        obj.PCD_PAYER_AUTHTYPE = 'sms';
        obj.PCD_PAY_OID = `qnoss_sms_${club.clubID}_${Math.floor(Date.now()/1000)}`
        
        obj.PCD_RST_URL = '/react/order_result'
        obj.callbackFunction = paymentResult;

        obj.PCD_PAYER_NO = club.clubID + "000" + club.ownerID;				  // (선택) 가맹점 회원 고유번호 (결과전송 시 입력값 그대로 RETURN)
        obj.PCD_PAYER_NAME = club.name;			  // (선택) 결제자 이름
        obj.PCD_PAYER_HP = club.phone;				  // (선택) 결제자 휴대폰 번호
        obj.PCD_PAY_GOODS = `문자 ${inputNumberAutoComma(price)}`;				  // (필수) 결제 상품
        obj.PCD_PAY_TOTAL = price;				  // (필수) 결제 금액

        // 파트너 인증 - 클라이언트 키(clientKey)
        obj.clientKey = "8EAD24F996568A9F460A3E73B43526DA";

        window.PaypleCpayAuthCheck(obj);
    }

    const resetSendRecord = async ()=>{
        const queryString = qs.stringify({
            socketKey,
        });
        let resdata = await client.get(`${url}/api/sms/record/${club.clubID}?${queryString}`);
        console.log("resetSendRecord resdata : ", resdata.data)
        if(resdata.data && resdata.data.result && resdata.data.result == 1) {
            setRecordList(resdata.data.infos)
            setSendRecordModal(true)
        }
    }

    const resetPaymentResult = async ()=>{
        const queryString = qs.stringify({
            socketKey,
        });
        let resdata = await client.get(`${url}/api/sms/paymentresult/${club.clubID}?${queryString}`);
        console.log("resetPaymentResult resdata : ", resdata.data)
        if(resdata.data && resdata.data.result && resdata.data.result == 1) {
            setPaymentResultList(resdata.data.infos)
            setPaymentResultModal(true)
        }
    }
    
    return (
        <SMS
            messageType={messageType}
            isADS={isADS}
            setIsADS={setIsADS}
            onLoadMember={onLoadMember}
            selectedMessage={selectedMessage}
            onChangeField={onChangeField}
            image={image}
            link={link}
            club={club}
            isDrag={isDrag}
            setIsDrag={setIsDrag}
            onDrop={onDrop}
            onTargetClick={onTargetClick}
            onFileInputChange={onFileInputChange}
            fileInputRef={fileInputRef}
            addMemberModal={addMemberModal}
            setAddMemberModal={setAddMemberModal}
            members={members && sort(members, 'registTime')}
            onSelectMember={onSelectMember}
            selectedMembers={selectedMembers && sort(selectedMembers, 'registTime')}
            selectedTempMembers={selectedTempMembers && sort(selectedTempMembers, 'registTime')}
            onSelectTempMember={onSelectTempMember}
            onSelectMemberDelete={onSelectMemberDelete}
            openModal={openModal}
            onSelectTempMemberDelete={onSelectTempMemberDelete}
            closeModal={closeModal}
            onSelectedTempMemberControl={onSelectedTempMemberControl}
            sortType={sortType}
            onMemberTypeChange={onMemberTypeChange}
            expiredMembers={expiredMembers}
            availableMembers={availableMembers}
            deleteImage={deleteImage}
            sendRecordModal={sendRecordModal}
            setSendRecordModal={setSendRecordModal}
            onSaveMessage={onSaveMessage}
            title={title}
            content={content}
            setTitle={setTitle}
            setContent={setContent}
            initPage={initPage}
            onDeleteMessage={onDeleteMessage}
            onloadData={onloadData}
            pageSort={pageSort}
            onChangePageSort={onChangePageSort}
            landingPageList={landingPageList}
            pageLink={pageLink}
            templateList={templateList}
            uploadMyImage={uploadMyImage}
            mobileProductList={mobileProductList}
            deleteMsgList={deleteMsgList}
            setDeleteMsgList={setDeleteMsgList}
            setDeleteMode={setDeleteMode}
            deleteMode={deleteMode}
            isAllDelete={isAllDelete}
            setIsAllDelete={setIsAllDelete}
            loading={loading}
            onCloseRecordModal={onCloseRecordModal}
            onLoadDetailMessage={onLoadDetailMessage}

            paymentModal={paymentModal}
            setPaymentModal={setPaymentModal}
            myPoint={smsInfo?smsInfo.point:0}
            smsPrice={smsInfo?smsInfo.sms:0}
            lmsPrice={smsInfo?smsInfo.lms:0}
            mmsPrice={smsInfo?smsInfo.mms:0}
            smsNumber={smsInfo?smsInfo.smsNumber:""}
            priceList={priceList}
            onPaymentSMS={onPaymentSMS}
            freeNagative={smsInfo?smsInfo.freeNagative:""}
            resetSendRecord={resetSendRecord}
            recordList={sort(recordList, 'registTime')}
            paymentResultModal={paymentResultModal}
            setPaymentResultModal={setPaymentResultModal}
            paymentResultList={sort(paymentResultList, 'registTime')}
            resetPaymentResult={resetPaymentResult}
            readyMembers={readyMembers}
            targetSex={targetSex}
            targetAgeMin={targetAgeMin}
            targetAgeMax={targetAgeMax}
            onMemberSexChange={(_sex)=>{
                if(sortType == 0)
                {
                    onMemberTypeChange(1)
                }
                setTargetSex(_sex)
            }}
            onMemberAgeMinChange={(_min)=>{
                if(sortType == 0)
                {
                    onMemberTypeChange(1)
                }
                setTargetMin(_min)
            }}
            onMemberAgeMaxChange={(_max)=>{
                if(sortType == 0)
                {
                    onMemberTypeChange(1)
                }
                setTargetMax(_max)
            }}
            adsInfoModal={adsInfoModal}
            setAdsInfoModal={setAdsInfoModal}
            smsNumberModal={smsNumberModal}
            setSmsNumberModal={setSmsNumberModal}

        />
    );
};

export default SMSContainer;
