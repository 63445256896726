import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { AutoSizer, List } from 'react-virtualized';
import Slider from '@material-ui/core/Slider';
import ColorPicker, { useColor } from "react-color-palette";
import { GrFormClose } from 'react-icons/gr';
import { GoSearch } from 'react-icons/go';
import { VscTriangleLeft, VscTriangleRight } from 'react-icons/vsc';
import palette from '../../../lib/styles/palette';
import { dateToString, inputNumberAutoComma } from '../../../lib/commonFunc/commonFunc';

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const AddMemberModalWrap = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1200px) {
    width: 100%;
    height: 100%;
  }
`;

const AddMemberBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;
  

  @media screen and (max-width: 1200px) {
      position: absolute;
      top: 180px;
  }
`;

const AddMemberBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 10px 30px 20px 30px;
`;

const BtnAddMemberConfirm = styled.button`
  width: 330px;
  padding: 10px 10px 10px 10px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-right: 20px;

  &:focus {
    outline: none;
  }
`;

const BtnAddMemberClose = styled.button`
width: 120px;
  padding: 10px 10px 10px 10px;
background-color: ${palette.webGray[5]};
color: #fff;
font-size: 1.2rem;
border: 0;
border-radius: .2rem;
cursor: pointer;

&:focus {
  outline: none;
}
`;

const AddMemberTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const AddMemberContentBox = styled.div`
  width: 100%;
  height: auto;
  padding: 25px 30px 20px 30px;
`;

const TableBox = styled.div`
  width: 100%;
  height: 350px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[11]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;
const Table = styled.table`
  font-family: 'Noto Sans KR', sans-serif;

  td {
    padding: 5px 5px 30px 30px;
  }

`;

const SmsPaymentResultModal = ({
    visible, 
    closeModal,
    paymentResultList
}) => {
    if(!visible) return false;

    return(
      <AddMemberModalWrap>
          <Background />
          <AddMemberBox>
              <AddMemberTitle>충전 내역</AddMemberTitle>
              <AddMemberContentBox>
                <TableBox>
                    <Table className="sms_payment">
                    <thead>
                        <tr>
                        <td>날짜</td>
                        <td>충전 금액</td>
                        <td>충전 포인트</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            paymentResultList.map((item, index)=>{
                                return (
                                    <tr key={`priceList_${index}`}>
                                        <td style={{fontWeight:600}}>{dateToString(item.registTime)}</td>
                                        <td>{inputNumberAutoComma(item.price)}원</td>
                                        <td>{item.bonus + item.base}P</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                    </Table>
                </TableBox>
              </AddMemberContentBox>
              <AddMemberBtnBox>
                  <BtnAddMemberClose onClick={() => {
                    closeModal()
                  }}>닫기</BtnAddMemberClose>
              </AddMemberBtnBox>
          </AddMemberBox>
      </AddMemberModalWrap>
    )
};

export default SmsPaymentResultModal;
